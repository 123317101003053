import React from 'react'

import { Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Input } from '../../lib/types'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          width: '100%',
        },
      },
    }),
  { name: 'AllInputs' }
)

type Props = {
  availInputs: Input[]
  selectedInputs: Input[]
  updateSelectedInputs: (selected: Input[]) => void
}

const AllInputs: React.FC<Props> = (props) => {
  const { selectedInputs, availInputs, updateSelectedInputs } = props
  const classes = useStyles()

  // Skip the Timestamp pseudo-tag
  const allInputs = availInputs.filter((i) => i.type !== 'datetime')

  // Which tags are selected
  const selected_ids = selectedInputs.map((i) => i.input_id)

  /** Toggle the selection state of an input */
  const toggleInput = (input: Input, selected: boolean) => {
    if (selected) {
      // Remove the input
      updateSelectedInputs(
        selectedInputs.filter((i) => i.input_id !== input.input_id)
      )
    } else {
      // Add the input
      updateSelectedInputs([...selectedInputs, input])
    }
  }

  const makeButton = (input: Input) => {
    const is_selected = selected_ids.includes(input.input_id)
    return (
      <Button
        key={input.input_id}
        variant="contained"
        color={is_selected ? 'primary' : 'default'}
        onClick={() => toggleInput(input, is_selected)}
      >
        {input.name} ({input.units})
      </Button>
    )
  }

  return (
    <div className={classes.buttons}>{allInputs.map((i) => makeButton(i))}</div>
  )
}

export default AllInputs
