import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import AppNav from './AppNav'

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'absolute',
        height: '100%',
        width: '100%',
      },
      header: {
        flex: '1 1 auto',
        zIndex: 100,
        position: 'relative',
      },
      content: {
        flex: '1 1 100%',
        overflow: 'auto',
        // padding: theme.spacing(2),
        zIndex: 90,
        position: 'relative',
      },
    }),
  { name: 'AppWrapper' }
)

type Props = {
  children?: React.ReactNode
}

const AppWrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <AppNav />
      </div>
      <div className={classes.content}>{props.children}</div>
    </div>
  )
}

export default AppWrapper
