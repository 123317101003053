import { Button, Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Doc, System } from '../lib/types'
import { useApp, useSystem } from '../providers'
import { ContentContainer } from './common'

const DOCUMENT_NAMES = [
  'P&ID',
  'Equipment Specification',
  'Operating Manual',
  'Outline',
]

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttons: {
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          gridTemplateColumns: '1fr',
        },
      },
    }),
  { name: 'Documents' }
)

const Documents: React.FC = () => {
  const { system } = useSystem()
  const { api } = useApp()

  const classes = useStyles()

  const openDoc = async (doc: Doc | null) => {
    if (!doc) {
      return
    }
    const docUrl = await api.getDocUrl(doc.tenant_id, doc.doc_id)
    if (docUrl) {
      window.open(docUrl.url, '_blank')
    }
  }

  return (
    <ContentContainer title="Documents">
      <Grid container direction="column" alignItems="center">
        <Grid item>
          {system.imageSrc ? (
            <img src={system.imageSrc} alt="System" width="250px" />
          ) : null}
        </Grid>
        <Grid item className={classes.buttons}>
          {DOCUMENT_NAMES.map((docName) => (
            <DocButton
              key={docName}
              system={system}
              docName={docName}
              openDoc={openDoc}
            />
          ))}
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

type DocButtonProps = {
  system: System
  docName: string
  openDoc: (doc: Doc) => any
}
const DocButton = (props: DocButtonProps) => {
  const { system, docName, openDoc } = props

  const doc = system.docs?.find((d) => d.name === docName) ?? null

  const onClick = () => {
    if (doc) {
      openDoc(doc)
    }
  }

  return (
    <Button
      variant="contained"
      disabled={!doc}
      fullWidth
      onClick={onClick}
      color={doc ? 'primary' : 'default'}
    >
      {docName}
    </Button>
  )
}

export default Documents
