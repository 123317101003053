import { useEffect, useState } from 'react'

import { Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Formik } from 'formik'
import { useSnackbar } from 'notistack'

import { Part } from '../../lib/types'
import { useApp, useSystem } from '../../providers'
import { ContentContainer, LoadPage } from '../common'

import PartsTable from './PartsTable'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      orderForm: {
        // Space out the parts tables a bit
        '& > *': {
          margin: theme.spacing(1, 0),
        },
      },
      submitButton: {
        fontWeight: 'bold',
      },
    }),
  { name: 'OrderParts' }
)

const OrderParts: React.FC = () => {
  const { api, asBusy } = useApp()
  const { system } = useSystem()

  const [parts, setParts] = useState<Part[] | null>(null)

  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  // Fetch the parts
  useEffect(() => {
    api
      .listSystemParts(system.tenant_id, system.system_id)
      .then((parts) => setParts(parts))
  }, [api, system])

  // Part quantities managed by Formik
  const qtys: Record<string, number> = {}

  if (!parts) {
    return <LoadPage />
  }

  if (parts.length === 0) {
    return (
      <ContentContainer title="Order Parts">
        No parts configured for this system.
      </ContentContainer>
    )
  }

  /** Submit the order the API */
  const submitOrder = async (
    qtys: Record<string, number>
  ): Promise<boolean> => {
    // Associate the quantities with the parts
    const orderedParts = parts
      .map((part) => ({
        part_number: part.partNumber,
        quantity: qtys[part.partNumber],
        description: part.name,
      }))
      .filter((op) => op.quantity)

    // Send the order the API and show the spinner
    const resp = await asBusy(() =>
      api.orderParts(system.tenant_id, system.system_id, orderedParts)
    )

    if (resp) {
      // Notify the user
      enqueueSnackbar('Submitted order!', { variant: 'success' })

      return true
    }

    return false
  }

  return (
    <ContentContainer title="Order Parts">
      <Formik
        initialValues={{ qtys }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (await submitOrder(values.qtys)) {
            resetForm()
          }
          setSubmitting(false)
        }}
      >
        {(f) => (
          <form
            id="order"
            noValidate
            onSubmit={f.handleSubmit}
            className={classes.orderForm}
          >
            <PartsTable
              title="Replacement Membranes"
              type="membrane"
              parts={parts}
            />
            <PartsTable
              title="Ancillary Parts"
              type="ancillary"
              parts={parts}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={f.isSubmitting}
              classes={{ root: classes.submitButton }}
            >
              Submit Quote Request
            </Button>
          </form>
        )}
      </Formik>
    </ContentContainer>
  )
}

export default OrderParts
