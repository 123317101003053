import React, { useState } from 'react'

import { Location } from 'history'
import { useLocation, useHistory } from 'react-router-dom'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { AppBar, Menu, MenuItem, Tabs, Tab } from '@material-ui/core'
import DoubleArrow from '@material-ui/icons/DoubleArrow'

import { useAwsUserRole } from '../../lib/hooks'
import { useAppSelector } from '../../lib/redux'
import { U } from '../../lib/utils'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      appBar: {
        backgroundColor: 'black',
        color: 'white',
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(1, 4),
        // justifyContent: 'flex-end',
        alignItems: 'center',
      },
      title: {
        fontSize: '24px',
        whiteSpace: 'nowrap',
      },
      tenantSelector: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
        marginRight: 'auto',
      },
      appBarActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
      },
      appBarLink: {
        whiteSpace: 'nowrap',
      },
      contextNavBar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        '& button': {
          minWidth: '130px',
        },
      },
      contextTabScrollButton: {
        // color: theme.palette.primary.light,
      },
      navFlex: {
        justifyContent: 'space-between',
      },
      navIndicator: {
        backgroundColor: '#00AF9D',
        height: theme.spacing(0.5),
      },
      regArrow: {
        transition: `transform ${theme.transitions.easing.easeInOut} ${
          theme.transitions.duration.standard / 1000
        }s`,
      },
      rotatedArrow: {
        transition: `all ${theme.transitions.easing.easeInOut} ${
          theme.transitions.duration.standard / 1000
        }s`,
        transform: 'rotate(90deg)',
      },
      rowTab: {
        flexDirection: 'row',
      },
    }),
  { name: 'StandardNav' }
)

type MenuTabItem = {
  path: string
  label: string
  hide?: boolean
}

type MenuTabProps = {
  value: string
  label: string
  emptyText?: string
  openMenu: string | null
  setOpenMenu: (openMenu: string | null) => void
  // children: React.ReactNode
  items: MenuTabItem[]
}

const MenuTab = (props: MenuTabProps) => {
  const { value, label, emptyText, openMenu, setOpenMenu, items } = props
  const classes = useStyles()
  const history = useHistory()
  const isOpen = openMenu === value
  const anchorId = `${value}_tab`

  const anchor = isOpen ? document.getElementById(anchorId) : null

  const navigator = (path: string) => (event: React.UIEvent) => {
    // Don't follow the link, etc.
    event.preventDefault()

    // Close all the open menus
    setOpenMenu(null)

    // Go to the new page
    history.push(path)
  }

  const shownItems = items.filter((item) => !item.hide)

  return (
    <>
      <Tab
        id={anchorId}
        label={
          <>
            {label}
            <DoubleArrow
              classes={{
                root: isOpen ? classes.rotatedArrow : classes.regArrow,
              }}
            />
          </>
        }
        value={value}
        classes={{ wrapper: classes.rowTab }}
        onClick={() => setOpenMenu(value)}
      />
      <Menu
        open={isOpen}
        onClose={() => setOpenMenu(null)}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {shownItems.map((item) => (
          <MenuItem key={item.path} onClick={navigator(item.path)}>
            {item.label}
          </MenuItem>
        ))}
        {!shownItems.length && (
          <MenuItem onClick={() => setOpenMenu(null)}>
            <span style={{ fontStyle: 'italic' }}>{emptyText}</span>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const StandardNav: React.FC = () => {
  const [openMenu, setOpenMenu] = useState<string | null>(null)

  const tenant = useAppSelector((state) => state.tenant)
  const system = useAppSelector((state) => state.system)
  const awsUserRole = useAwsUserRole()

  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  // dont show if no system selected
  if (!tenant || !system) {
    return null
  }

  const navigator = (path: string) => (event: React.UIEvent) => {
    // Don't follow the link, etc.
    event.preventDefault()

    // Close all the open menus
    setOpenMenu(null)

    // Go to the new page
    history.push(path)
  }

  const isPremium = awsUserRole !== 'customer'

  // Which tab should be highlighted
  const tabValue = getTabValue(location, isPremium)

  return (
    <AppBar
      id="contextNavBar"
      position="static"
      classes={{ root: classes.contextNavBar }}
      elevation={1}
    >
      <Tabs
        value={tabValue}
        variant="scrollable"
        scrollButtons="on"
        classes={{
          flexContainer: classes.navFlex,
          indicator: classes.navIndicator,
        }}
      >
        <Tab label="OVERVIEW" value="overview" onClick={navigator('/')} />
        {isPremium && (
          <Tab
            label="LIVE DATA"
            value="livedata"
            onClick={navigator('/livedata')}
          />
        )}
        <Tab label="DOCUMENTS" value="docs" onClick={navigator('/documents')} />
        {isPremium && (
          <MenuTab
            value="hmi"
            label="HMI VIEW"
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            items={[
              { path: '/hmi/alarms', label: 'Alarm History' },
              {
                path: '/hmi/lots',
                label: 'Lot History',
                hide: !system.show_lots,
              },
              {
                path: '/hmi/tests',
                label: 'System Tests',
                hide: !system.show_tests,
              },
              ...(system.hmis?.map((hmi) => ({
                path: `/hmi/view/${U(hmi.name)}`,
                label: `HMI: ${hmi.name}`,
              })) ?? []),
            ]}
          />
        )}
        {isPremium && (
          <MenuTab
            value="influent"
            label="INFLUENT"
            emptyText="No Influent Tags"
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            items={[
              { path: '/influent/chart', label: 'Chart', hide: !system.inputs },
              { path: '/influent/logs', label: 'Logs', hide: !system.inputs },
              {
                path: '/influent/enter',
                label: 'Enter Data',
                hide: !system.inputs,
              },
            ]}
          />
        )}
        <Tab label="KSS ASSIST" value="assist" onClick={navigator('/assist')} />
        {!isPremium && (
          <Tab
            label="INSTALLED MEMBRANES"
            value="parts"
            onClick={navigator('/parts/membrane')}
          />
        )}
        {isPremium && (
          <MenuTab
            value="parts"
            label="MEMBRANES AND PARTS"
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            items={[
              { path: '/parts/membrane', label: 'Installed Membranes' },
              { path: '/parts/order', label: 'Order Parts' },
            ]}
          />
        )}
        {!isPremium && (
          <Tab
            label="ORDER PARTS"
            value="order"
            onClick={navigator('/parts/order')}
          />
        )}
        <Tab
          label={isPremium ? 'ORDERS' : 'ORDER HISTORY'}
          value="orderHist"
          onClick={navigator('/order_history')}
        />
      </Tabs>
    </AppBar>
  )
}

/**
 * Figure out which tab best matches the current page
 */
const getTabValue = (location: Location, isPremium: boolean): string => {
  const path = location.pathname

  if (path.endsWith('/livedata')) return 'livedata'
  if (path.endsWith('/documents')) return 'docs'
  if (path.includes('/hmi/')) return 'hmi'
  if (path.includes('/influent/')) return 'influent'
  if (path.endsWith('/assist')) return 'assist'
  if (path.endsWith('/parts/order')) return isPremium ? 'parts' : 'order'
  if (path.includes('/parts/')) return 'parts'
  if (path.endsWith('/order_history')) return 'orderHist'

  // Everything else is Overview
  return 'overview'
}

export default StandardNav
