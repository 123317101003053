/**
 * Show errors in popup dialogs
 */
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import ClipboardIcon from '@material-ui/icons/Assignment'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { dismissError, useAppSelector, useAppDispatch } from '../../lib/redux'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dialog: {
        '& .MuiDialogContentText-root': {
          whiteSpace: 'pre-wrap',
        },
        '& .MuiDialogTitle-root': {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark,
        },
      },
      dismiss: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.dark,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
      },
      errorTextStyle: {
        color: theme.palette.text.primary,
      },
    }),
  { name: 'Errors' }
)

/**
 * Copy the error text to the user's clipboard.
 */
function copyToClipboard(error: any) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(error.text)
  }
}

const Errors: React.FC = () => {
  const classes = useStyles()

  const dispatch = useAppDispatch()
  const errors = useAppSelector((state) => state.errors)

  if (!errors || errors.length === 0) {
    return null
  }

  // Copying to the clipboard may be disabled
  const can_copy = navigator.clipboard

  // Take the first error
  const error = errors[0]

  const dismiss = () => dispatch(dismissError())

  return (
    <Dialog
      open
      onClose={dismiss}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      classes={{ root: classes.dialog }}
    >
      <DialogTitle>{error.title ? error.title : 'Error'}</DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: classes.errorTextStyle }}>
          {error.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton
          onClick={() => copyToClipboard(error)}
          disabled={!can_copy}
          title="Copy to clipboard"
        >
          <ClipboardIcon />
        </IconButton>
        <Button onClick={dismiss} className={classes.dismiss}>
          Dismiss Error
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Errors
