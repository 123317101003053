/**
 * Access to the current Sytems
 *
 * For example, with a ClassComponent:
 *   import { withSystem, WithSystemProps } from '../providers';
 *   type Props = {
 *     // Local properties
 *    } & WithSystemProps;
 *
 *   class Example extends react.Component<Props, State> {
 *      async render() {
 *        const system = this.props.system;
 *
 *        return (
 *           ...
 *        );
 *      }
 *   }
 *
 *   export default withSystem(Example);
 *
 * or a FunctionComponent:
 *
 *   import { useSystem } from '../providers';
 *   export default async Example(props) {
 *      const { system } = useSystem();
 *
 *      return (
 *        ...
 *      );
 *   }
 */

import React, { useContext, useMemo } from 'react'

import hoistNonReactStatics from 'hoist-non-react-statics'

import getDisplayName from './getDisplayName'
import { System, Tenant } from '../lib/types'

/*
 * Properties added by withSystem()
 */
export interface WithSystemProps {
  /** Current Selected Tenant */
  tenant: Tenant
  /** Current Selected System */
  system: System
}

/** Context for System */
export const SystemContext = React.createContext<WithSystemProps>(
  {} as WithSystemProps
)

/**
 * Provides System for functional components
 *
 * @returns System
 */
export const useSystem = (): WithSystemProps => useContext(SystemContext)

/**
 * Wraps a component to provide the WithSystemProps
 *
 * @param WrappedComponent the component to wrap
 * @returns the wrapper component
 */
export function withSystem<
  P extends WithSystemProps,
  R = Omit<P, keyof WithSystemProps>
>(WrappedComponent: React.ComponentType<P>): React.ComponentType<R> {
  // Wrap the component with the consumer
  const Wrapper = (props: R) => (
    <SystemContext.Consumer>
      {(context) => (
        <WrappedComponent
          {...(props as any)}
          tenant={context.tenant}
          system={context.system}
        />
      )}
    </SystemContext.Consumer>
  )

  // Copy static methods from WrappedComponent to Wrapper
  hoistNonReactStatics(Wrapper, WrappedComponent)

  // Make the component name prettier for debugging
  if (process.env.NODE_ENV !== 'production') {
    Wrapper.displayName = `WithSystem(${getDisplayName(WrappedComponent)})`
  }

  return Wrapper
}

type Props = {
  /** Current Tenant */
  tenant: Tenant
  /** Current System */
  system: System
  /** Child components to render in the body */
  children?: React.ReactNode
}

/**
 * Component to provide the current selected System
 */
const SystemProvider: React.FC<Props> = (props: Props) => {
  const { tenant, system, children } = props

  // Assemble and cache the provided values
  const value = useMemo<WithSystemProps>(
    () => ({
      tenant,
      system,
    }),
    [tenant, system]
  )

  return (
    <SystemContext.Provider value={value}>{children}</SystemContext.Provider>
  )
}

export default SystemProvider
