import React from 'react'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
  isFunction,
} from 'formik'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      form: {
        '& > *': {
          // Space the form elements out a bit
          margin: theme.spacing(1, 0),
        },
      },
    }),
  { name: 'EditDialog' }
)

type Props<T extends FormikValues> = {
  /** Is the dialog open? */
  open: boolean
  /** The title for the dialog */
  title: React.ReactNode
  /** Callback triggered when the dialog closes for any reason */
  onClose: () => any
  /**
   * Callback triggered when the user clicks on the Save button
   *
   * @param values Updated values
   */
  onSave: (values: T) => Promise<boolean>
} & Omit<FormikConfig<T>, 'onSubmit'>

/**
 * A Formik form in a dialog.
 *
 * All the children and extra properties are passed to Formik.
 *
 * @example
 * <EditDialog
 *   open={editing}
 *   title="Edit Stuff"
 *   onClose={() => setEditing(false)}
 *   onSave={(stuff) => saveStuff(stuff)}
 *   initialValues={stuff}
 * >
 *   <Field name="example" label="Example" />
 * </EditDialog>
 *
 * The children can also be function that accepts the Formik form, which you may
 * need for more complicated forms:
 * @example
 *   <EditDialog ... >
 *     { f => (
 *        <CustomInput name="example" label="Example" value={f.values.example} onChange={f.handleChange} />
 *     )}
 *   </EditDialog>
 */
export default function EditDialog<T extends FormikValues>(
  props: Props<T>
): React.ReactElement | null {
  const { open, title, onClose, onSave, children, ...formikProps } = props

  const classes = useStyles()

  if (!open) {
    return null
  }

  return (
    <Formik<T>
      {...formikProps}
      onSubmit={async (values, helpers) => {
        if (await onSave(values)) {
          onClose()
        }
        helpers.setSubmitting(false)
      }}
    >
      {(f) => (
        <Dialog open={open} scroll="paper" fullWidth onClose={() => onClose()}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <form noValidate onSubmit={f.handleSubmit} className={classes.form}>
              {children
                ? isFunction(children)
                  ? (children as (bag: FormikProps<T>) => React.ReactNode)(f)
                  : children
                : null}
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()}>Cancel</Button>
            <Button
              onClick={() => f.submitForm()}
              disabled={!f.dirty || !f.isValid || f.isSubmitting}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
