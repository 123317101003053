/**
 * Access to the Admin Breadcrumb
 *
 * For example, with a ClassComponent:
 *   import { withBreadcrumb, WithBreadcrumbtProps } from '../providers';
 *   type Props = {
 *     // Local properties
 *    } & WithBreadcrumbProps;
 *
 *   class Example extends react.Component<Props, State> {
 *      fetchData() {
 *         const system = this.fetchSystem(...);
 *         this.props.setBreadcrumbTarget('system', system);
 *      }
 *   }
 *
 *   export default withBreadcrumb(Example);
 *
 * or a FunctionComponent:
 *
 *   import { useBreadcrumb } from '../providers';
 *   export default async Example(props) {
 *      const { setBreadcrumbTarget } = useBreadcrumb();
 *
 *      return (
 *        ...
 *      );
 *   }
 */

import React, { useContext } from 'react'

import hoistNonReactStatics from 'hoist-non-react-statics'

import { U } from '../lib/utils'
import getDisplayName from './getDisplayName'

/** Placeholder for an unset for an  */
export const BREADCRUMB_UNSET = -1

/** A single step in the breadcrumb trail */
export type Breadcrumb = {
  /** The position of this breadcrumb in the trail */
  index: number
  /** The text that is displayed in the trail */
  label: string
  /** The path for the link in the trail */
  path: string
}

/** Type of entity at the end of the breadcrumb trail */
export type BreadcrumbTargetType =
  | 'home'
  | 'tenant'
  | 'system'
  | 'subsystem'
  | 'subsystem'
  | 'module'

/** Entity at the end of the breadcrumb trail */
export type BreadcrumbTarget = {
  name: string
  tenant_id?: string
  system_id?: string
  subsystem_id?: string
  module_id?: string
}

/** First step on the Admin breadcrub trail */
const HOME_BREADCRUMB: Breadcrumb = {
  index: 0,
  label: 'Admin Home',
  path: '/admin',
}

/* Properties added by withBreadcrumb() */
export interface WithBreadcrumbProps {
  /** The trail of breadcrumbs  */
  breadcrumbs: Breadcrumb[]
  /** Set the final target for the breadcrumb trail */
  setBreadcrumbTarget: (
    type: BreadcrumbTargetType,
    target: BreadcrumbTarget | null
  ) => void
  /** Prune the trail at and beyond the index */
  popBreadcrumb: (index: number) => void
}

/** Context for Breadcrumbs */
export const BreadcrumbContext = React.createContext<WithBreadcrumbProps>(
  {} as WithBreadcrumbProps
)

/** Provides Breadcrumbs for functional components */
export const useBreadcrumb = (): WithBreadcrumbProps =>
  useContext(BreadcrumbContext)

/**
 * Wraps a component to provide the WithBreadcrumbProps
 *
 * @param WrappedComponent the component to wrap
 * @returns the wrapper component
 */
export function withBreadcrumb<
  P extends WithBreadcrumbProps,
  R = Omit<P, keyof WithBreadcrumbProps>
>(WrappedComponent: React.ComponentType<P>): React.ComponentType<R> {
  // Wrap the component with the consumer
  const Wrapper = (props: R) => (
    <BreadcrumbContext.Consumer>
      {(context) => <WrappedComponent {...(props as any)} {...context} />}
    </BreadcrumbContext.Consumer>
  )

  // Copy static methods from WrappedComponent to Wrapper
  hoistNonReactStatics(Wrapper, WrappedComponent)

  // Make the component name prettier for debugging
  if (process.env.NODE_ENV !== 'production') {
    Wrapper.displayName = `WithBreadcrumbs(${getDisplayName(WrappedComponent)})`
  }

  return Wrapper
}

type Props = {
  /** Initial breadcrumb in the trail */
  initial?: {
    label: string
    path: string
  }
  /** Child components to render in the body */
  children?: React.ReactNode
}

type State = {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbTarget: (
    type: BreadcrumbTargetType,
    target: BreadcrumbTarget | null
  ) => void
  popBreadcrumb: (index: number) => void
}

/**
 * Component to provide the breadcrumbs
 */
class BreadcrumbProvider extends React.Component<Props, State> {
  // Cache of breadcrumb labels - used to remember labels from earlier in the
  // path
  cache: Record<string, string> = {}

  constructor(props: Props) {
    super(props)

    // Put all the properties into the state to avoid re-rendering
    this.state = {
      breadcrumbs: props.initial ? [{ index: 0, ...props.initial }] : [],
      setBreadcrumbTarget: this.setBreadcrumbTarget,
      popBreadcrumb: this.popBreadcrumb,
    }
  }

  render() {
    return (
      <BreadcrumbContext.Provider value={this.state}>
        {this.props.children}
      </BreadcrumbContext.Provider>
    )
  }

  /**
   * Set the final target for the breadcrumb trail
   */
  setBreadcrumbTarget = (
    type: BreadcrumbTargetType,
    target: BreadcrumbTarget | null
  ): void => {
    const breadcrumbs = [
      // Always include the Home link
      HOME_BREADCRUMB,
    ]

    if (!target?.tenant_id) {
      // Nothing else to do
      this.setState({ breadcrumbs })
      return
    }

    breadcrumbs.push({
      index: 1,
      path: `/admin/tenant/${U(target.tenant_id)}`,
      label: this.findLabel(type, 'tenant', target.tenant_id, target.name),
    })

    if (target.system_id) {
      breadcrumbs.push({
        index: 2,
        path: `/admin/tenant/${U(target.tenant_id)}/system/${U(
          target.system_id
        )}`,
        label: this.findLabel(type, 'system', target.system_id, target.name),
      })
    }
    if (target.subsystem_id) {
      breadcrumbs.push({
        index: 3,
        path: `/admin/tenant/${U(target.tenant_id)}/subsystem/${U(
          target.subsystem_id
        )}`,
        label: this.findLabel(
          type,
          'subsystem',
          target.subsystem_id,
          target.name
        ),
      })
    }
    if (target.module_id) {
      breadcrumbs.push({
        index: 4,
        path: `/admin/tenant/${U(target.tenant_id)}/module/${U(
          target.module_id
        )}`,
        label: this.findLabel(type, 'module', target.module_id, target.name),
      })
    }

    this.setState({ breadcrumbs })
  }

  /**
   * Find the label for the crumb
   *
   * @param targetType type of the target entiy
   * @param crumbType type of this crumb
   * @param id entity ID for this crumb
   * @param name name of the target entity
   * @returns bets matching label for this crumb
   */
  findLabel = (
    targetType: BreadcrumbTargetType,
    crumbType: BreadcrumbTargetType,
    id: string,
    name: string
  ): string => {
    // Key in the label cache
    const cache_key = `${crumbType}:${id}`

    if (targetType === crumbType) {
      // This is the target - cache and use the name
      this.cache[cache_key] = name
      return name
    }

    // TODO: Check Store for tenants
    // TODO: Fetch from network

    // Use the cached label, or a placeholder
    return this.cache[cache_key] ?? crumbType.toUpperCase()
  }

  /**
   * Prune the trail at this position and beyond
   */
  popBreadcrumb = (index: number): void => {
    const { breadcrumbs } = this.state

    if (index === BREADCRUMB_UNSET) {
      // Invalid index
      return
    }

    // Filter to allow only breadcrumbs before this index
    this.setState({
      breadcrumbs: breadcrumbs.filter((bc) => bc.index < index),
    })
  }
}

export default BreadcrumbProvider
