import RestAPI from '../../lib/api'
import { InfluentLog, Input, System } from '../../lib/types'

/**
 * Sort inputs first by units then name.
 */
export const sortInputs = (a: Input, b: Input): number => {
  return (
    (a.units || '').localeCompare(b.units || '') || a.name.localeCompare(b.name)
  )
}

/**
 * Fetch the influent logs for the inputs and time options
 *
 * @param api RestAPI
 * @param system current System
 * @param inputs selected inputs
 * @param timeOptions time range options, either:
 *   - { days: number } => recent days
 *   - { startTime: string, endTime: string } => explicit range of UTC date strings
 * @returns Promie to fetch influent logs
 */
export const fetchInfluentLogs = (
  api: RestAPI,
  system: System,
  inputs: Input[],
  timeOptions: { days: number } | { start_utc: string; end_utc: string }
): Promise<InfluentLog | null> => {
  const args = {
    inputs: inputs.map((input) => input.input_id),
    ...timeOptions,
  }

  return api
    .getInfluentLog(system.tenant_id, system.system_id, args)
    .then((fetchedLogs) => {
      if (fetchedLogs) {
        // Convert the first column into a Date
        fetchedLogs.rows.forEach((row) => {
          row[0] = new Date(row[0])
        })
      }

      return fetchedLogs
    })
}
