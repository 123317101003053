import { useMemo } from 'react'

import { ScaleLinear } from 'd3-scale'
import { LineSegment, VictoryTooltip, Flyout } from 'victory'

import { useIsSmallScreen } from '../../../lib/hooks'
import { VictoryLayoutProps, ChartableTag } from '../shared'
import { calculateLayout, labelMaker } from './shared'
import CustomLabels from './CustomLabels'

export type Props = {
  row: any[]
  /** All chart data */
  rows: any[][]
  /** Displayed tags */
  tags: ChartableTag[]
  /** Indexes of tags in the tag list, for picking colors */
  tagIndexes: number[]
  /** Scales to fit tag data onto shared chart */
  tagScales?: ScaleLinear<number, number>[]
} & VictoryLayoutProps

/**
 * Labels and line for an active chart tooltip
 */
const ActiveDisplay: React.FC<Props> = (props: Props) => {
  const { row, tags, tagIndexes, tagScales, ...victoryProps } = props
  const { scale } = victoryProps

  // Callback to generate the labels
  const makeLabels = useMemo(
    () => labelMaker(tags, tagIndexes),
    [tags, tagIndexes]
  )
  const isSmallScreen = useIsSmallScreen()

  const ts = row[0] as Date
  const labels = makeLabels(row)
  const layout = calculateLayout(props, row, labels, isSmallScreen)

  return (
    <g>
      <LineSegment
        {...victoryProps}
        x1={layout.xTooltip}
        x2={layout.xTooltip}
        y1={layout.yTop}
        y2={layout.yBottom}
        style={{
          stroke: 'black',
          opacity: 0.5,
        }}
      />
      {
        // Highlight the tag lines with a dot
        tagIndexes.map((tagIndex) => (
          <circle
            key={`dot-${tagIndex}`}
            cx={layout.xTooltip}
            cy={scale.y(
              tagScales ? tagScales[tagIndex](row[tagIndex]) : row[tagIndex]
            )}
            r={3}
            fill={tags[tagIndex].color}
          />
        ))
      }
      <VictoryTooltip
        {...victoryProps}
        active={true}
        x={layout.xTooltip}
        y={layout.yMid}
        constrainToVisibleArea={true}
        horizontal={true}
        labelComponent={
          <CustomLabels labels={labels} layout={layout} timestamp={ts} />
        }
        pointerWidth={0}
        pointerLength={0}
        flyoutComponent={
          <Flyout
            center={{ x: layout.cxFlyout, y: layout.cyFlyout }}
            width={layout.wFlyout}
            height={layout.hFlyout}
          />
        }
        flyoutStyle={{
          fill: 'white',
          stroke: 'black',
        }}
        style={{
          fontFamily: 'Roboto',
          fontWeight: 500,
          fontSize: 12,
          textAnchor: 'start',
        }}
      />
    </g>
  )
}

export default ActiveDisplay
