import { useEffect, useState } from 'react'

import { Card, CardHeader, CardContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { System, Subsystem, Module } from '../../../lib/types'

import ModuleCard from './ModuleCard'
import { useApp } from '../../../providers'
import { BY_SORT_VALUE } from '../../../lib/sortItems'
import { LoadPage } from '../../common'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      subsystemCard: {
        // Give some space between the subsystems
        marginBottom: theme.spacing(2),
      },
    }),
  { name: 'SubsystemSection' }
)

type Props = {
  system: System
  subsystem: Subsystem
}

/** All the modules of a subsytem */
const SubsystemSection: React.FC<Props> = (props: Props) => {
  const { system, subsystem } = props
  const { api } = useApp()

  const [modules, setModules] = useState<Module[] | null>(null)

  const classes = useStyles()

  useEffect(() => {
    api
      .listSubsystemModules(subsystem.tenant_id, subsystem.subsystem_id)
      .then((modules) => {
        if (modules) {
          modules.sort(BY_SORT_VALUE)
        }
        setModules(modules)
      })
  }, [api, subsystem])

  return (
    <Card className={classes.subsystemCard}>
      <CardHeader title={`Installed Membranes - ${subsystem.name}`} />
      <CardContent>
        {!modules && <LoadPage />}
        {modules?.length === 0 && <p>No modules</p>}
        {modules?.map((m) => (
          <ModuleCard key={m.module_id} system={system} module={m} />
        ))}
      </CardContent>
    </Card>
  )
}

export default SubsystemSection
