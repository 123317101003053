import { VictoryAxis } from 'victory'

import { X_AXIS_HEIGHT } from './shared'

/**
 * Standard X axis showing time
 */
export const XAxis: React.FC = (props: any) => (
  <VictoryAxis
    {...props}
    offsetY={X_AXIS_HEIGHT}
    style={{
      axis: {
        stroke: 'none',
      },
      grid: {
        stroke: '#EEEEEE',
      },
      ticks: {
        stroke: '#EEEEEE',
        size: 5,
      },
      tickLabels: {
        fill: '#666666',
        fontSize: 12,
        padding: 3,
      },
    }}
  />
)

export default XAxis
