import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { formatDate } from '../../lib/dates'
import { byDate } from '../../lib/sortItems'
import { MIN_CELL, NO_WRAP, renderLite } from '../../lib/tables'
import { InfluentLog, Input } from '../../lib/types'
import { useApp, useSystem } from '../../providers'

import { ContentContainer, LoadPage } from '../common'

import { sortInputs, fetchInfluentLogs } from './shared'

const NUM_DAYS = 365

const Logs: React.FC = () => {
  const { system } = useSystem()
  const { api } = useApp()
  const [fetchedLogs, setFetchedLogs] = useState<InfluentLog | null>(null)

  useEffect(() => {
    // Find all the inputs available for graphing
    const availInputs = system.inputs || []

    // Sort the inputs by units and name
    availInputs.sort(sortInputs)

    fetchInfluentLogs(api, system, availInputs, { days: NUM_DAYS }).then(
      (fetchedLogs) => {
        if (fetchedLogs) {
          // Put the most recent logs first
          fetchedLogs.rows.sort(byDate('0')).reverse()
        }
        // Use this data
        setFetchedLogs(fetchedLogs)
      }
    )
  }, [api, system])

  if (fetchedLogs === null) {
    return <LoadPage />
  }

  const options: MUIDataTableOptions = {
    enableNestedDataAccess: '.',
    selectableRows: 'none',
    viewColumns: false,
  }
  const data = fetchedLogs.rows
  const columns: MUIDataTableColumn[] = [
    {
      name: 'timestamp',
      label: 'Timestamp',
      options: {
        filter: false,
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
        customBodyRenderLite: renderLite(data, (entry) => formatDate(entry[0])),
      },
    },
    // Organize the inputs into columns
    ...prepareInputColumns(fetchedLogs.inputs),
  ]

  return (
    <ContentContainer>
      <MUIDataTable
        title={<Typography variant="h5">Influent Logs</Typography>}
        options={options}
        columns={columns}
        data={data}
      />
    </ContentContainer>
  )
}

/**
 * Prepare table columns for each unique input in the logs
 */
const prepareInputColumns = (inputs: Input[]): MUIDataTableColumn[] => {
  // Each unique tag will get its own column
  const columns: MUIDataTableColumn[] = []

  inputs.slice(1).forEach((input, index) => {
    // Add a column for this new tag
    columns.push({
      name: index.toString(),
      label: `${input.name} (${input.units})`,
    })
  })

  return columns
}

export default Logs
