import { Card, CardContent, CardHeader } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { System } from '../../lib/types'
import { FactTable } from '../common'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      content: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
      },
      image: {
        height: '200px',
        margin: theme.spacing(1),

        objectFit: 'cover',
        border: `solid 2px ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          height: 'auto',
          maxHeight: '250px',
        },
      },
    }),
  { name: 'SystemInfo' }
)

type Props = {
  system: System
}

/**
 * Basic info about system including image
 */
const SystemInfo: React.FC<Props> = (props: Props) => {
  const { system } = props

  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Overview" />
      <CardContent className={classes.content}>
        {system.imageSrc && (
          <img
            alt="Filtration System"
            className={classes.image}
            src={system.imageSrc}
          />
        )}
        <FactTable
          facts={[
            { label: 'System', value: system.name },
            { label: 'Location', value: system.location },
            { label: 'Nominal Flow Rate', value: system.nominal_flow_rate },
            { label: 'Membrane Product', value: system.membrane_product },
            {
              label: 'Number of Trains',
              value: system.number_filtration_trains,
            },
          ]}
        />
      </CardContent>
    </Card>
  )
}

export default SystemInfo
