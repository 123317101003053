import { format } from 'd3-format'

import { hasValue } from '../../../lib/utils'
import { ChartableTag, X_AXIS_HEIGHT } from '../shared'
import { estimateMaxWidth } from '../../../lib/glyphs'

// Basic layout values, in pixels
export const PADDING = 8
export const FONT_SIZE = 12
export const LINE_HEIGHT = 20

type TooltipValue = number | undefined | null

export type TooltipLabel = {
  label: string
  value: string
  color: string
}

export type Layout = {
  xTooltip: number
  yTooltip: number

  xFlyout: number
  yFlyout: number
  wFlyout: number
  hFlyout: number
  cxFlyout: number
  cyFlyout: number

  xLabels: number
  yLabels: number
  wLabels: number
  hLabels: number

  orientation: 'left' | 'right'

  xMin: Date
  xMax: Date
  xMid: number
  yMin: number
  yMax: number
  yTop: number
  yBottom: number
  yMid: number

  xDot: number
  xValue: number
  xLabel: number

  fontSize: number
  lineHeight: number
  padding: number
}

export type LayoutProps = {
  /** Victory chart domain */
  domain?: {
    x: [Date, Date]
    y: [number, number]
  }
  /** Victory chart scale */
  scale?: any
}

export const calculateLayout = (
  props: LayoutProps,
  row: any[],
  labels: TooltipLabel[],
  isSmallScreen = false
): Layout => {
  const { domain, scale } = props

  // Maximum estimated widths for value and label text
  const maxValueWidth = Math.max(
    40,
    estimateMaxWidth(
      labels.map((l) => l.value),
      FONT_SIZE
    )
  )
  const maxLabelWidth = Math.max(
    120,
    estimateMaxWidth(
      labels.map((l) => l.label),
      FONT_SIZE
    )
  )

  // Location of the tooltip
  const xTooltip = scale.x(row[0])

  // Chart bounds
  const [xMin, xMax] = domain!.x // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [yMin, yMax] = domain!.y // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const xMid = (scale.x(xMin) + scale.x(xMax)) / 2
  const yTop = scale.y(yMax)
  const yBottom = scale.y(yMin)
  const yMid = (yTop + yBottom) / 2

  // Label row offsets
  const xDot = PADDING
  const xValue = xDot + 2 * PADDING
  const xLabel = xValue + maxValueWidth + 2 * PADDING

  // Label region dimensions
  const hLabels = FONT_SIZE + LINE_HEIGHT * labels.length + 1 * PADDING
  const wLabels = xLabel + maxLabelWidth

  // Flyout dimensions
  const hFlyout = hLabels + 1 * PADDING
  const wFlyout = wLabels + 2 * PADDING

  // On which side of the tooltip line should the flyout appear
  const orientation = xTooltip > xMid ? 'left' : 'right'

  // Distance between the tooltip line and the flyout
  const xOffset = 32

  // Flyout position
  let xFlyout =
    orientation === 'left' ? xTooltip - xOffset - wFlyout : xTooltip + xOffset

  // Constrain the Flyout to the screen
  if (xFlyout < 0) {
    xFlyout = 0
  } else if (xFlyout + wFlyout > scale.x(xMax)) {
    xFlyout = scale.x(xMax) - wFlyout
  }

  // Position the tooltip mid graph for desktop and below the graph on mobile
  const yFlyout = isSmallScreen ? yBottom + X_AXIS_HEIGHT : yMid - hFlyout / 2

  // Find the center of the Flyout - the center is used to actually position the
  // Flyout
  const cxFlyout = xFlyout + wFlyout / 2
  const cyFlyout = yFlyout + hFlyout / 2

  // Labels position
  const xLabels = xFlyout + PADDING
  const yLabels = yFlyout + PADDING

  return {
    xTooltip,
    yTooltip: yMid,

    xFlyout,
    yFlyout,
    wFlyout,
    hFlyout,
    cxFlyout,
    cyFlyout,

    xLabels,
    yLabels,
    wLabels,
    hLabels,

    xDot,
    xValue,
    xLabel,

    orientation,

    xMin,
    xMax,
    xMid,
    yMin,
    yMax,
    yTop,
    yBottom,
    yMid,

    fontSize: FONT_SIZE,
    lineHeight: LINE_HEIGHT,
    padding: PADDING,
  }
}

// Wrapper around format that shows a dash for missing values
const orDash = (fmt: (value: number) => string) => (value: TooltipValue) =>
  hasValue(value) ? fmt(value) : '\u2014'

// Formatters for various scales
const F_0001 = format('.4f')
const F_0010 = format('.3f')
const F_0100 = format('.2f')
const F_1000 = format('.1f')
const F_LARGE = format('d')

// Formatter wrappers that show a dash for missing values
const DF_0001 = orDash(F_0001)
const DF_0010 = orDash(F_0010)
const DF_0100 = orDash(F_0100)
const DF_1000 = orDash(F_1000)
const DF_LARGE = orDash(F_LARGE)

/**
 * Format a value based on the value's scale.
 *
 * @param value value, or undefined, or null, who knows
 * @returns formatted value; or a dash for no value
 */
export const formatValue = (value: TooltipValue): string => {
  if (!hasValue(value)) {
    return '\u2014'
  }
  const abs = Math.abs(value)

  if (abs < 1) return F_0001(value)
  if (abs < 10) return F_0010(value)
  if (abs < 100) return F_0100(value)
  if (abs < 1000) return F_1000(value)
  return F_LARGE(value)
}

/**
 * Generate a callback to make labels for the selected row
 *
 * @param tags Tags to include in the label
 * @param tagIndexes tag indexes for coloring
 * @returns callback to make labels
 */
export const labelMaker = (
  tags: ChartableTag[],
  tagIndexes: number[]
): ((rows: any[]) => TooltipLabel[]) => {
  const formats = tags.map((tag, i) => {
    if (!tagIndexes.includes(i) || !hasValue(tag.max_value)) {
      // Nothing known about this tag, or this tag has no configured max value
      // Use the default formatter
      return formatValue
    }

    // Pick a formatter based on the tag's expected range
    const range = tag.max_value - (tag.min_value ?? 0)

    if (range < 1) return DF_0001
    if (range < 10) return DF_0010
    if (range < 100) return DF_0100
    if (range < 1000) return DF_1000
    return DF_LARGE
  })

  return (row: any[]) =>
    tagIndexes.map((tagIndex) => {
      const tag = tags[tagIndex]
      const value = row[tagIndex]

      return {
        label: `${tag.name} (${tag.units})`,
        value: formats[tagIndex](value),
        color: tag.color,
      }
    })
}
