import { useCallback, useEffect, useMemo, useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { NumberValue, ScaleLinear, scaleLinear } from 'd3-scale'
import { VictoryAxisProps, VictoryChart, VictoryGroup } from 'victory'

import { useDimensions } from '../../lib/hooks'
import { hasValue, loadLocal, saveLocal } from '../../lib/utils'
import { Lot } from '../../lib/types'
import { withSystem, WithSystemProps } from '../../providers'
import {
  ChartableTag,
  findMinMax,
  CHART_PADDING,
  NullComponent,
  Y_AXIS_LABELED_WIDTH,
} from './shared'
import TagLegend from './TagLegend'
import TagLine from './TagLine'
import Tooltip from './Tooltip'
import XAxis from './XAxis'
import YGrid from './YGrid'
import YLabels from './YLabels'
import LotPeriods, { LOTS_HEIGHT } from './LotPeriods'

const DEFAULT_MAX_AXES = 4

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2),
        height: '100%',
      },
      chart: {
        flex: '1 1 100%',
      },
      legend: {
        flex: '0 0 auto',
      },
    }),
  { name: 'AllTogetherChart' }
)

type TagData = {
  tag: ChartableTag
  tagIndex: number
  color: string
  yMin: number
  yMax: number
  scaledMin: number
  scaledMax: number
  scale: ScaleLinear<number, number>
  tickFormat: (y: NumberValue) => string
  y: (row: any) => number | null
}

type Props = {
  startTime: Date
  endTime: Date
  rows: any[][]
  tags: ChartableTag[]
  lots?: Lot[] | null

  showDots: boolean
  forceMinMax: boolean
  maxAxes?: number
  localPrefix: string
} & WithSystemProps

/**
 * A single chart that includes all the tags.
 */
const AllTogetherChart: React.FC<Props> = (props: Props) => {
  const {
    startTime,
    endTime,
    rows,
    tags,
    lots,
    showDots,
    forceMinMax,
    system,
    localPrefix,
    maxAxes = DEFAULT_MAX_AXES,
  } = props

  const classes = useStyles()

  // Measure the width of the wrapper to size the chart
  const [refDimensions, dimensions] = useDimensions()

  // Track which tag is focused in the legend
  const [focusedTag, setFocusedTag] = useState<string | null>(null)

  // Track which tags are selected in the legend
  const [selectedTags, setSelectedTags] = useState<string[]>(
    loadLocal(`${localPrefix}.selectedtags.${system.system_id}`, [])
  )

  // Save the selected tags in component state and local storage
  const selectAndSaveTags = useCallback(
    (tagNames: string[]) => {
      saveLocal(`${localPrefix}.selectedtags.${system.system_id}`, tagNames)
      setSelectedTags(tagNames)
    },
    [system]
  )

  // Make sure that the tags selected for an axis are actually shown on the graph
  useEffect(() => {
    const tagNames = filterForTags(tags, selectedTags)
    if (tagNames.length !== selectedTags.length || missingTag(tags, tagNames)) {
      selectAndSaveTags(tagNames)
    }
  }, [tags, selectedTags, selectAndSaveTags])

  // Prepare all the tags to fit on the same chart
  const { scaledData, scaledMin, scaledMax, tagScales } = useMemo(() => {
    const scaledData = tags
      .slice(1)
      .map((tag, i) => prepareTagData(rows, tag, i + 1, forceMinMax))
    const [scaledMin, scaledMax] = scaledData.reduce(
      ([min, max], d) => [
        Math.min(min, d.scaledMin),
        Math.max(max, d.scaledMax),
      ],
      [Infinity, -Infinity]
    )

    const tagScales = [scaleLinear(), ...scaledData.map((d) => d.scale)]

    return { scaledData, scaledMin, scaledMax, tagScales }
  }, [rows, tags, forceMinMax])
  const tagIndexes = tags.slice(1).map((_t, i) => i + 1)

  // Find the tags selected to show their axis
  const axisTags = scaledData.filter((d) => selectedTags.includes(d.tag.name))

  // Make sure the focused tag has an axis
  if (
    focusedTag &&
    !axisTags.some((tagData) => tagData.tag.name === focusedTag)
  ) {
    const focused = scaledData.find(
      (tagData) => tagData.tag.name === focusedTag
    )
    if (focused) {
      if (axisTags.length < maxAxes) {
        // Add the focsued axis to the end
        axisTags.push(focused)
      } else {
        // Replace the focused axis on the end
        axisTags[axisTags.length - 1] = focused
      }
    }
  }

  // Default to the first tag
  if (!axisTags.length && scaledData.length) {
    axisTags.push(scaledData[0])
  }

  return (
    <div className={classes.container}>
      <div ref={refDimensions} className={classes.chart}>
        {dimensions && (
          <>
            {lots && (
              <LotPeriods
                height={LOTS_HEIGHT}
                width={dimensions.width}
                domain={{
                  x: [startTime, endTime],
                  y: [0, 1],
                }}
                lots={lots ?? null}
              />
            )}
            <VictoryChart
              width={dimensions.width}
              height={dimensions.height - (lots ? LOTS_HEIGHT : 0)}
              domain={{
                x: [startTime, endTime],
                y: forceMinMax ? [0, 1] : [scaledMin, scaledMax],
              }}
              padding={{
                ...CHART_PADDING,
                left: maxAxes * Y_AXIS_LABELED_WIDTH,
              }}
              scale={{
                x: 'time',
                y: 'linear',
              }}
              prependDefaultAxes={true}
              defaultAxes={{
                dependent: <NullComponent />,
                independent: <XAxis />,
              }}
              style={{
                parent: {
                  height: 'auto',
                },
              }}
            >
              <YGrid />
              <VictoryGroup>
                {axisTags.map((tagData, index) => (
                  <TagAxis
                    key={tagData.tag.name}
                    axisTag={tagData}
                    offsetX={Y_AXIS_LABELED_WIDTH * (maxAxes - index)}
                    focusedTag={focusedTag}
                  />
                ))}
              </VictoryGroup>
              {scaledData.map((tagData) => (
                <TagLine
                  key={`tag-${tagData.tag.name}`}
                  rows={rows}
                  tags={tags}
                  tagIndex={tagData.tagIndex}
                  getY={tagData.y}
                  selectedTag={focusedTag}
                  showDots={showDots}
                />
              ))}
              <Tooltip
                rows={rows}
                tags={tags}
                tagIndexes={tagIndexes}
                tagScales={tagScales}
              />
            </VictoryChart>
          </>
        )}
      </div>
      <div className={classes.legend}>
        <TagLegend
          tags={tags}
          focusedTag={focusedTag}
          setFocusedTag={setFocusedTag}
          selectedTags={selectedTags}
          setSelectedTags={selectAndSaveTags}
          orientation="vertical"
          maxSelections={maxAxes}
        />
      </div>
    </div>
  )
}

/**
 * Y Axis for the selected tag
 */
type TagAxisProps = {
  axisTag: TagData
  focusedTag: string | null
} & VictoryAxisProps
const TagAxis = (props: TagAxisProps) => {
  const { axisTag, focusedTag, ...other } = props

  const focused = focusedTag ? focusedTag === axisTag.tag.name : null

  return (
    <YLabels
      {...other}
      color={axisTag.tag.color}
      label={`${axisTag.tag.name} (${axisTag.tag.units})`}
      tickFormat={axisTag.tickFormat}
      tickCount={5}
      focused={focused}
    />
  )
}

/**
 *
 * @param rows all the chart data
 * @param tag the tag definition
 * @param tagIndex column index of the tag in the rows
 * @returns
 */
const prepareTagData = (
  rows: any[][],
  tag: ChartableTag,
  tagIndex: number,
  forceMinMax: boolean
): TagData => {
  const minMax = findMinMax(rows, tag, tagIndex, forceMinMax)
  const [actualMin, actualMax] = [minMax.min, minMax.max]

  const yMin = hasValue(tag.min_value) ? tag.min_value : actualMin
  const yMax = hasValue(tag.max_value) ? tag.max_value : actualMax

  const scale = scaleLinear().domain([yMin, yMax]).range([0, 1])
  const format = scale.tickFormat(5)
  const tickFormat = (t: any) => format(scale.invert(t))

  const scaledMin = scale(Math.min(actualMin, yMin))
  const scaledMax = scale(Math.max(actualMax, yMax))

  return {
    tag,
    tagIndex,
    color: tag.color,
    yMin,
    yMax,
    scaledMin,
    scaledMax,
    scale,
    tickFormat,
    y: (row: any) => (hasValue(row[tagIndex]) ? scale(row[tagIndex]) : null),
  }
}

/**
 * Filter the selected tag name to those that are displayed in the chart.
 *
 * @param tags Tags shown in chart
 * @param tagNames tag names selected for Y-axes
 * @returns Selected Y-axes tag name that are in the chart
 */
const filterForTags = (tags: ChartableTag[], tagNames: string[]): string[] =>
  tagNames.filter((tagName) => tags.some((tag) => tag.name === tagName))

/**
 * Check if any of the tag names are not displayed in the chart.

 * @param tags Tags shown in chart
 * @param tagNames tag names selected for Y-axes
 * @returns true if any selected tag name does not have a corresponding displayed tag; false otherwise
 */
const missingTag = (tags: ChartableTag[], tagNames: string[]): boolean =>
  tagNames.some((tagName) => !tags.some((tag) => tag.name === tagName))

export default withSystem(AllTogetherChart)
