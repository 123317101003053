import { useCallback, useEffect, useState } from 'react'

import { IconButton } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { formatDate, parseDate } from '../../../lib/dates'
import { BY_SORT_VALUE } from '../../../lib/sortItems'
import { BARE_TABLE, renderLite } from '../../../lib/tables'
import { Module, Part, Position, System } from '../../../lib/types'
import { useApp } from '../../../providers'

import PositionDialog from './PositionDialog'
import { LoadPage } from '../../common'

type FormPosition = Position & {
  installedDate: Date | null
  projectedDate: Date | null
  part: Part | null
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      moduleCard: {
        // Give some space between the modules
        marginBottom: theme.spacing(2),
      },
    }),
  { name: 'ModuleCard' }
)

type Props = {
  system: System
  module: Module
}

/** All the positions of a module */
const ModuleCard: React.FC<Props> = (props: Props) => {
  const { system, module } = props
  const classes = useStyles()
  const { api } = useApp()

  const [positions, setPositions] = useState<FormPosition[] | null>(null)
  const [position, setPosition] = useState<FormPosition | null>(null)

  const fetchData = useCallback(() => {
    api
      .listModulePositions(module.tenant_id, module.module_id)
      .then((positions) => {
        positions?.sort(BY_SORT_VALUE)
        setPositions(positions?.map((p) => preparePosition(system, p)) ?? [])
      })
  }, [api, system, module])

  // Fetch the data on page load
  useEffect(() => fetchData(), [fetchData])

  if (!positions) {
    return <LoadPage />
  }

  const onCloseDialog = (refresh: boolean) => {
    setPosition(null)
    if (refresh) {
      fetchData()
    }
  }

  const data = positions

  const options: MUIDataTableOptions = {
    // Strip the header decoration
    ...BARE_TABLE,
    // Show all the positions
    pagination: false,
    // Use '.' for the object path delimiter
    enableNestedDataAccess: '.',
  }

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Position',
    },
    {
      name: 'part.name',
      label: 'Part',
    },
    {
      name: 'serial',
      label: 'Serial Number',
    },
    {
      name: 'installedDate',
      label: 'Date Installed',
      options: {
        customBodyRenderLite: renderLite(data, (row) =>
          formatDate(row.installedDate, false)
        ),
      },
    },
    {
      name: 'projectedDate',
      label: 'Projected Life',
      options: {
        customBodyRenderLite: renderLite(data, (row) =>
          formatDate(row.projectedDate, false)
        ),
      },
    },
    {
      name: 'notes',
      label: 'Notes',
    },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        customBodyRenderLite: renderLite(data, (row) => (
          <IconButton onClick={() => setPosition(row)}>
            <EditIcon />
          </IconButton>
        )),
      },
    },
  ]

  return (
    <div className={classes.moduleCard}>
      <MUIDataTable
        key={module.module_id}
        title={`Module: ${module.name}`}
        options={options}
        columns={columns}
        data={data}
      />
      <PositionDialog position={position} onClose={onCloseDialog} />
    </div>
  )
}

/** Prepare the position for the table */
const preparePosition = (system: System, position: Position): FormPosition => {
  const part =
    system.parts?.find((part) => part.partNumber === position.partNumber) ??
    null
  const installedDate = parseDate(position.installDate)
  const projectedDate = installedDate
    ? new Date(installedDate.getTime() + position.lifeExpectancy)
    : null

  return {
    ...position,
    part,
    installedDate,
    projectedDate,
  }
}

export default ModuleCard
