import {
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
} from 'mui-datatables'
import React from 'react'

import { formatDate } from './dates'
import { Option } from './types'

type LiteRenderer = (dataIndex: number, rowIndex: number) => React.ReactNode

type RenderCallback<T> = (row: T) => React.ReactNode

/** Table options to strip off all the table controls */
export const BARE_TABLE: MUIDataTableOptions = {
  selectableRows: 'none',
  search: false,
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  pagination: false,
  elevation: 0,
  setTableProps: () => ({ size: 'small' }),
}

/** Cell properties to make the column as narrow as possible without wrapping */
export const MIN_CELL = (): any => ({
  style: { width: 0, whiteSpace: 'nowrap' },
})
/** Cell properties to disabled text wrapping in the column */
export const NO_WRAP = (): any => ({ style: { whiteSpace: 'nowrap' } })

/**
 * Wrapper that finds the original data row and renders a field.
 *
 * @param rows all the rows in the table
 * @param callback function that renders the field
 * @returns wrapper for customBodyRenderLite
 */
export const renderLite = <T>(
  rows: T[] | null,
  callback: RenderCallback<T>
): LiteRenderer => {
  return (dataIndex: number, _rowIndex: number) => {
    const row = rows?.[dataIndex]
    if (!row) {
      return null
    }

    return callback.call(null, row)
  }
}

// Callback to convert a boolean to Yes/No
const booleanLabel = (value: any): string => (value ? 'Yes' : 'No')
//. Callback to find an option's label
const optionLabel = (options: Option[], value: any): string =>
  options.find((o) => o.value === value)?.label ?? value

/**
 * Wrapper that finds the original data row and renders a boolean field as Yes/No
 *
 * @param rows all the rows in the table
 * @param name name of boolean field
 * @returns wrapper for customBodyRenderLite
 */
export const renderLiteBoolean = <T>(
  rows: T[] | null,
  name: keyof T
): LiteRenderer => {
  return (dataIndex: number, _rowIndex: number) => {
    const row = rows?.[dataIndex]
    if (!row) {
      return null
    }

    return booleanLabel(row[name])
  }
}

/**
 * Wrapper that finds the original data row and renders a value from a list of options
 *
 * @param rows all the rows in the table
 * @param name name of field
 * @param options value options
 * @returns wrapper for customBodyRenderLite
 */
export const renderLiteOptions = <T>(
  rows: T[] | null,
  name: keyof T,
  options: Option[]
): LiteRenderer => {
  return (dataIndex: number, _rowIndex: number) => {
    const row = rows?.[dataIndex]
    if (!row) {
      return null
    }

    return optionLabel(options, row[name])
  }
}

/**
 * Standard column definition for a boolean field
 *
 * @param rows all the rows in the table
 * @param name name of field
 * @param label label for the column
 * @param extra additional column options
 * @returns column definition
 */
export const booleanColumn = <T>(
  rows: T[] | null,
  name: keyof T,
  label: string,
  extra: MUIDataTableColumnOptions = {}
): MUIDataTableColumn => {
  return {
    name: name as string,
    label,
    options: {
      customBodyRenderLite: renderLiteBoolean(rows, name),
      customFilterListOptions: {
        render: (v) => `${label}: ${booleanLabel(v)}`,
      },
      filterOptions: {
        renderValue: booleanLabel,
      },
      ...extra,
    },
  }
}

/**
 * Standard column definition for a field that uses Options
 *
 * @param rows all the rows in the table
 * @param name name of field
 * @param label label for the column
 * @param options field Options
 * @param extra additional column options
 * @returns column definition
 */
export const optionColumn = <T>(
  rows: T[] | null,
  name: keyof T,
  label: string,
  options: Option[],
  extra: MUIDataTableColumnOptions = {}
): MUIDataTableColumn => {
  return {
    name: name as string,
    label,
    options: {
      customBodyRenderLite: renderLiteOptions(rows, name, options),
      customFilterListOptions: {
        render: (v) => `${label}: ${optionLabel(options, v)}`,
      },
      filterOptions: {
        renderValue: (v) => optionLabel(options, v),
      },
      ...extra,
    },
  }
}

/**
 * Standard column definition for a date field
 *
 * @param rows all the rows in the table
 * @param name name of field
 * @param label label for the column
 * @param extra additional column options
 * @returns column definition
 */
export const dateColumn = <T>(
  rows: T[] | null,
  name: keyof T,
  label: string,
  extra: MUIDataTableColumnOptions = {}
): MUIDataTableColumn => {
  return {
    name: name as string,
    label,
    options: {
      customBodyRenderLite: renderLite(rows, (row) =>
        formatDate(row[name] as unknown as string, false)
      ),
      filter: false,
      ...extra,
    },
  }
}
