import React from 'react'

import { CircularProgress, Grid } from '@material-ui/core'

/**
 * Indicator that the page is still loading the initial data
 */
const LoadPage: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: 160 }}
    >
      <Grid item>
        <CircularProgress size={80} />
      </Grid>
    </Grid>
  )
}

export default LoadPage
