import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { MuiThemeProvider } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { Authenticator } from '@aws-amplify/ui-react'
import { SnackbarProvider } from 'notistack'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

// Load the Roboto Typeface
import 'typeface-roboto'
import { defaultTheme } from './styles/themes'

import configureAws from './lib/aws'
import { store } from './lib/redux'
import AppProvider from './providers/AppProvider'
import './styles/index.css'

import App from './App'
import LoadPage from './components/common/LoadPage'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// Standard dismiss button for all Snackbar messages
const snackbar = React.createRef<SnackbarProvider>()
const dismissSnack = (key: string | number | undefined) => (
  <IconButton
    onClick={() => snackbar.current?.closeSnackbar(key)}
    size="small"
    color="inherit"
  >
    <CloseIcon />
  </IconButton>
)

// Configure AWS Amplify
configureAws()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// TODO: Restore React.StrictMode

ReactDOM.render(
  <MuiThemeProvider theme={defaultTheme}>
    <React.Suspense fallback={<LoadPage />}>
      <Authenticator.Provider>
        <SnackbarProvider ref={snackbar} maxSnack={3} action={dismissSnack}>
          <ReduxProvider store={store}>
            <AppProvider store={store}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AppProvider>
          </ReduxProvider>
        </SnackbarProvider>
      </Authenticator.Provider>
    </React.Suspense>
  </MuiThemeProvider>,
  document.getElementById('root')
)
