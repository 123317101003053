import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, Link } from '@material-ui/core'

import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/EmailOutlined'

import { ContentContainer } from './common'
import { U } from '../lib/utils'

type ContactInfo = {
  type: 'phone' | 'email'
  label: string
  data: string
  subject?: string
  body?: string
}

const CONTACT_DATA: ContactInfo[] = [
  { label: 'USA Support', data: '+1-978-694-7050', type: 'phone' },
  { label: 'Europe Support', data: '+49-241-41326-32', type: 'phone' },
  {
    type: 'email',
    label: 'Email Info',
    data: 'getinfo@kochsep.com',
    subject: 'SepTrac Customer Request: EMAIL',
    body: 'Hello,\n\nI would like more infomation about Koch Separation Systems or the SepTrac application. Please send me more info about:\n\n{Place details here}',
  },
  {
    type: 'email',
    label: 'Submit SepTrac Feedback',
    data: 'getinfo@kochsep.com',
    subject: 'SepTrac Customer Request: Submit SepTrac Feedback',
    body: 'Hello,\n\nI would like to provide the following feedback about the SepTrac application:\n\n{Place feedback here}',
  },
]

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      cards: {
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          gridTemplateColumns: '1fr',
        },
      },
      themedCard: {
        width: '100%',
        boxShadow: theme.shadows[2],
        marginTop: theme.spacing(2),
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        '& .MuiCardHeader-root': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        '& .MuiCardContent-root': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(2),

          '& .MuiLink-root': {
            display: 'flex',
            alignItems: 'center',
            '& > *': {
              marginRight: theme.spacing(2),
            },
          },
          '& .MuiSvgIcon-root': {
            color: theme.palette.text.disabled,
          },
        },
      },
    }),
  { name: 'Contact' }
)

const Contact: React.FC = () => {
  const classes = useStyles()

  return (
    <ContentContainer title="Contact Us">
      <div className={classes.cards}>
        {CONTACT_DATA.map((contact) => (
          <ContactCard key={contact.label} contact={contact} />
        ))}
      </div>
    </ContentContainer>
  )
}

type ContactCardProps = {
  contact: ContactInfo
}

const ContactCard = (props: ContactCardProps) => {
  const { contact } = props

  const classes = useStyles()
  let content: React.ReactNode = null

  switch (contact.type) {
    case 'phone':
      content = (
        <Link href={`tel:${contact.data}`} color="inherit">
          <PhoneIcon />
          {contact.data}
        </Link>
      )
      break

    case 'email': {
      let mailtoAddress = `mailto:${contact.data}`
      if (contact.subject || contact.body) {
        mailtoAddress += '?'
        if (contact.subject) {
          mailtoAddress += `subject=${U(contact.subject)}`
        }
        if (contact.subject && contact.body) {
          mailtoAddress += '&'
        }
        if (contact.body) {
          mailtoAddress += `body=${U(contact.body)}`
        }
      }
      content = (
        <Link href={mailtoAddress} color="inherit">
          <EmailIcon />
          {contact.data}
        </Link>
      )
      break
    }
    default:
      break
  }

  return (
    <Card classes={{ root: classes.themedCard }}>
      <CardHeader
        title={contact.label}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>{content}</CardContent>
    </Card>
  )
}

export default Contact
