/**
 * Sorting utilities
 */

// eslint really does not like the (a: any, b: any)
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

type HasSort = { sort?: number }
type HasName = { name?: string }

// Comparator to sort items by the "sort" property
export const BY_SORT_VALUE = (a: HasSort, b: HasSort): number => {
  return (a.sort || 0) - (b.sort || 0)
}

// Comparator to sort items by the "name" property
export const BY_NAME = (a: HasName, b: HasName): number => {
  return (a.name || '').localeCompare(b.name || '')
}

// Create comparator to sort by string values
export const byString =
  (key: string) =>
  (a: any, b: any): number => {
    return (a[key] || '').localeCompare(b[key] || '')
  }

// Create comparator to sort by Date values
export const byDate =
  (key: string) =>
  (a: any, b: any): number => {
    return a[key].valueOf() - b[key].valueOf()
  }

// Create comparator to sort by number values
export const byNumber =
  (key: string) =>
  (a: any, b: any): number => {
    return (a[key] || 0) - (b[key] || 0)
  }

export const nextSort = (items: HasSort[]): number => {
  if (items.length === 0) {
    // Start at the beginning!
    return 0
  }

  // Sort after the last in the list
  const maxSort = items.reduce((m, i) => Math.max(m, i.sort ?? -1), -1)
  if (maxSort > -1) {
    return maxSort + 1
  }

  // Oh well, just go at the end
  return items.length + 1
}

export default function sortItems<T extends HasSort>(
  items: T[] | null | undefined
): T[] | null | undefined {
  if (items && items.length) {
    // Sort in place
    items.sort(BY_SORT_VALUE)
  }

  // Return the array as well for chaining calls
  return items
}
