/**
 * Interface to the REST API
 */
import API from '@aws-amplify/api'

import { addError, AppStore } from './redux'
import {
  Tenant,
  System,
  SystemPerformance,
  Subsystem,
  ProblemReport,
  User,
  Doc,
  OrderHistory,
  FileUpload,
  Module,
  Position,
  Part,
  OrderInformation,
  TrendingData,
  SystemStatus,
  Input,
  InfluentInput,
  InfluentLog,
  TripReport,
  TripReportDownload,
  DocDownload,
  AlarmLog,
  Lot,
  SystemTest,
  Tag,
  SubscriptionStatus,
} from '../lib/types'
import { P, U } from './utils'

// react-scripts eslint does not handle the type inferrence, while VSCode is
// fine.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 *  A response from the API.
 *
 * All data wil be wrapped in the 'data' field
 */
/*
type ApiResponse<T> = {
  data: T;
  error?: string;
}
*/

/** Amplify API request method */
type ApiMethod = (apiName: any, path: any, init: any) => Promise<any>

/**
 * Interface to the REST API
 */
export default class RestAPI {
  constructor(private store: AppStore) {}

  /**
   * Pick an Amplify API method
   *
   * @param method HTTP method name
   * @returns Amplify API callback
   */
  find_method = (method: string): ApiMethod => {
    switch (method) {
      case 'GET':
        return API.get
      case 'DELETE':
        return API.del
      case 'PATCH':
        return API.patch
      case 'POST':
        return API.post
      case 'PUT':
        return API.put
      default:
        throw new Error(`Unknown API method '${method}`)
    }
  }

  /**
   * Core API request, now with built-in error handling!
   *
   * HTTP and API errors are dispatched through redux.
   *
   * The response is null in the case of an error.
   *
   * Note: You'll need to call API.* directly if you want handle the errors yourself.
   *
   * @param method HTTP method (GET, DELETE, PATCH, POST, PUT)
   * @param apiName Name of Amplify API
   * @param path Endpoint path
   * @param extra Extra data to pass in request
   * @returns async API response, or null if there was an error
   */
  api_request = async <T>(
    method: string,
    apiName: string,
    path: string,
    extra: any = null
  ): Promise<T | null> => {
    try {
      // Figure out which API method to call
      const api_method = this.find_method(method)

      /* SKOVRAN
      // Make the call
      const resp = (await api_method.call(API, apiName, path, extra) as ApiResponse<T>);
      // The actual value is wrapped in the 'data' field
      if (resp.data) {
        return resp.data;
      }
      */

      // Make the call
      return await api_method.call(API, apiName, path, extra)
    } catch (err: any) {
      // Log the error
      console.log(`API ERROR:`, method, apiName, path, extra, err?.response)

      // Present the error to the user
      this.store.dispatch(addError(err))

      // Nothing to return
      return null
    }
  }

  // USERS ////////////////////////////////////////////////////////////

  listUsers = () => {
    return this.api_request<User[]>('GET', 'data', `/api/users`)
  }

  // postUser = (newUser) => {
  createUser = (user: Partial<User>) => {
    return this.api_request<User>('POST', 'data', `/api/users`, {
      body: user,
    })
  }

  updateUser = (username: string, user: Partial<User>) => {
    return this.api_request<User>(
      'PATCH',
      'data',
      `/api/users/${U(username)}`,
      {
        body: user,
      }
    )
  }

  deleteUser = (username: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/users/${U(username)}`
    )
  }

  // TENANTS //////////////////////////////////////////////////////////

  listTenants = () => {
    return this.api_request<Tenant[]>('GET', 'data', `/api/tenants`)
  }

  createTenant = (tenant: Partial<Tenant>) => {
    return this.api_request<Tenant>('POST', 'data', `/api/tenants`, {
      body: tenant,
    })
  }

  getTenant = (tenantId: string) => {
    return this.api_request<Tenant>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}`
    )
  }

  updateTenant = (tenantId: string, tenant: Partial<Tenant>) => {
    return this.api_request<Tenant>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}`,
      {
        body: tenant,
      }
    )
  }

  deleteTenant = (tenantId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}`
    )
  }

  /** Fetch the order history for a tenant */
  listOrders = (tenantId: string) => {
    return this.api_request<OrderHistory[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/orders`
    )
  }

  reportProblem = (tenantId: string, problemReport: Partial<ProblemReport>) => {
    return this.api_request<boolean>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/reportproblem`,
      {
        body: problemReport,
      }
    )
  }

  // DOCS /////////////////////////////////////////////////////////////

  createDoc = (tenantId: string, doc: Partial<Doc>) => {
    return this.api_request<Doc>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/docs`,
      {
        body: doc,
      }
    )
  }

  updateDoc = (tenantId: string, docId: string, doc: Partial<Doc>) => {
    return this.api_request<Doc>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/docs/${U(docId)}`,
      {
        body: doc,
      }
    )
  }

  getDocUrl = (tenantId: string, docId: string) => {
    return this.api_request<DocDownload>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/docs/${U(docId)}/url`
    )
  }

  getDocUploadUrl = (tenantId: string, path: string) => {
    return this.api_request<FileUpload>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/docs/upload_url/${P(path)}`
    )
  }

  // HMIS /////////////////////////////////////////////////////////////
  // INPUTS ///////////////////////////////////////////////////////////

  createInput = (tenantId: string, newInput: Partial<Input>) => {
    return this.api_request<Input>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/inputs`,
      {
        body: newInput,
      }
    )
  }

  getInput = (tenantId: string, inputId: string) => {
    return this.api_request<Tag>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/inputs/${inputId}`
    )
  }

  updateInput = (tenantId: string, inputId: string, input: Partial<Input>) => {
    return this.api_request<Input>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/inputs/${U(inputId)}`,
      {
        body: input,
      }
    )
  }

  deleteInput = (tenantId: string, inputId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/inputs/${U(inputId)}`
    )
  }

  // MODULES //////////////////////////////////////////////////////////

  createModule = (tenantId: string, module: Partial<Module>) => {
    return this.api_request<Module>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/modules`,
      {
        body: module,
      }
    )
  }

  getModule = (tenantId: string, moduleId: string) => {
    return this.api_request<Module>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/modules/${U(moduleId)}`
    )
  }

  updateModule = (
    tenantId: string,
    moduleId: string,
    module: Partial<Module>
  ) => {
    return this.api_request<Module>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/modules/${U(moduleId)}`,
      {
        body: module,
      }
    )
  }

  deleteModule = (tenantId: string, moduleId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/modules/${U(moduleId)}`
    )
  }

  listModulePositions = (tenantId: string, moduleId: string) => {
    return this.api_request<Position[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/modules/${U(moduleId)}/positions`
    )
  }

  // PARTS ////////////////////////////////////////////////////////////

  createPart = (tenantId: string, part: Partial<Part>) => {
    return this.api_request<Part>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/parts`,
      {
        body: part,
      }
    )
  }

  updatePart = (tenantId: string, partId: string, part: Partial<Part>) => {
    return this.api_request<Part>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/parts/${U(partId)}`,
      {
        body: part,
      }
    )
  }

  deletePart = (tenantId: string, partId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/parts/${U(partId)}`
    )
  }

  orderParts = (
    tenantId: string,
    systemId: string,
    order: OrderInformation[]
  ) => {
    // const exampleInputOrder = [
    //   {partNumber: '012345', orderQty: 2},
    // ];
    return this.api_request<boolean>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/parts/order`,
      {
        body: order,
      }
    )
  }

  // POSITIONS ////////////////////////////////////////////////////////

  createPosition = (tenantId: string, position: Partial<Position>) => {
    return this.api_request<Position>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/positions`,
      {
        body: position,
      }
    )
  }

  updatePosition = (
    tenantId: string,
    positionId: string,
    position: Partial<Position>
  ) => {
    return this.api_request<Position>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/positions/${U(positionId)}`,
      {
        body: position,
      }
    )
  }

  deletePosition = (tenantId: string, positionId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/positions/${U(positionId)}`
    )
  }

  // SUBSYSTEMS ///////////////////////////////////////////////////////

  createSubsystem = (tenantId: string, newSubsystem: Partial<Subsystem>) => {
    return this.api_request<Subsystem>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems`,
      {
        body: newSubsystem,
      }
    )
  }

  getSubsystem = (tenantId: string, subsystemId: string) => {
    return this.api_request<Subsystem>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems/${U(subsystemId)}`
    )
  }

  updateSubsystem = (
    tenantId: string,
    subsystemId: string,
    subsystem: Partial<Subsystem>
  ) => {
    return this.api_request<Subsystem>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems/${U(subsystemId)}`,
      {
        body: subsystem,
      }
    )
  }

  deleteSubsystem = (tenantId: string, subsystemId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems/${U(subsystemId)}`
    )
  }

  listSubsystemModules = (tenantId: string, subsystemId: string) => {
    return this.api_request<Module[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems/${U(subsystemId)}/modules`
    )
  }

  /**
   * Fetch the trending data for a subsystem
   *
   * @param tenantId Tenant ID
   * @param systemId System ID
   * @param args Object of
   *   - tags: array of tag IDs
   *   - start_utc: beginning of time range
   *   - end_utc: end of time range
   */
  fetchTrendingBySubsystem = (
    tenantId: string,
    subsystemId: string,
    args: any
  ) => {
    return this.api_request<TrendingData>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/subsystems/${U(subsystemId)}/trending`,
      {
        body: args,
      }
    )
  }

  // SYSTEMS ///////////////////////////////////////////////////////

  listSystems = (tenantId: string) => {
    return this.api_request<System[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems`
    )
  }

  createSystem = (tenantId: string, system: Partial<System>) => {
    return this.api_request<System>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems`,
      {
        body: system,
      }
    )
  }

  getSystem = (tenantId: string, systemId: string) => {
    return this.api_request<System>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}`
    )
  }

  updateSystem = (
    tenantId: string,
    systemId: string,
    system: Partial<System>
  ) => {
    return this.api_request<System>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}`,
      {
        body: system,
      }
    )
  }

  deleteSystem = (tenantId: string, systemId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}`
    )
  }

  getSystemStatus = (tenantId: string, systemId: string) => {
    return this.api_request<SystemStatus>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/status`
    )
  }

  getSystemPerformance = (tenantId: string, systemId: string) => {
    return this.api_request<SystemPerformance>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/performance`
    )
  }

  getSystemSnapshot = (tenantId: string, systemId: string) => {
    return this.api_request<any>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/snapshot`
    )
  }

  /**
   * Fetch the trending data for a system
   *
   * @param tenantId Tenant ID
   * @param systemId System ID
   * @param args Object of
   *   - tags: array of tag IDs
   *   - start_utc: beginning of time range
   *   - end_utc: end of time range
   */
  fetchSystemTrending = (tenantId: string, systemId: string, args: any) => {
    return this.api_request<TrendingData>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/trending`,
      {
        body: args,
      }
    )
  }

  listSystemDocs = (tenantId: string, systemId: string) => {
    return this.api_request<Doc[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/docs`
    )
  }

  listSystemInputs = (tenantId: string, systemId: string) => {
    return this.api_request<Input[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/inputs`
    )
  }

  listSystemParts = (tenantId: string, systemId: string) => {
    return this.api_request<Part[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/parts`
    )
  }

  getSystemAlarmLog = (tenantId: string, systemId: string, args: any) => {
    return this.api_request<AlarmLog>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/alarmlog`,
      {
        body: args,
      }
    )
  }

  // SYSTEMS - ALARM SUBSCRIPTION //////////////////////////////////

  getSubscriptionStatus = (tenantId: string, systemId: string) => {
    return this.api_request<SubscriptionStatus>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/alarms/subscription`
    )
  }

  postSubscribe = (tenantId: string, systemId: string) => {
    return this.api_request<SubscriptionStatus>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/alarms/subscribe`
    )
  }

  postUnsubscribe = (tenantId: string, systemId: string) => {
    return this.api_request<SubscriptionStatus>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/alarms/unsubscribe`
    )
  }

  // SYSTEMS - INFLUENT ////////////////////////////////////////////

  /**
   * Fetch recent Influent log entries
   *
   * @param tenantId Tenant ID
   * @param systemId System ID
   * @param args
   * - inputs: Array of input IDs to fetch
   * - start_utc: Beginning of time range
   * - end_utc: End of time range
   */
  getInfluentLog = (tenantId: string, systemId: string, args: any) => {
    return this.api_request<InfluentLog>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/influent/log`,
      {
        body: args,
      }
    )
  }

  /**
   * Create a new Influent log entry
   *
   * @param tenantId Tenant ID
   * @param systemId System ID
   * @param timestamp UTC datetime string
   * @param inputs Array of input values, like:
   *   [
   *   {
   *     input_id : 'pH',
   *     value: '7.0'
   *   }, ...
   *   ]
   */
  postInfluentEntry = (
    tenantId: string,
    systemId: string,
    timestamp: string,
    inputs: InfluentInput[]
  ) => {
    return this.api_request(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/influent/entry`,
      {
        body: {
          timestamp,
          inputs,
        },
      }
    )
  }

  // SYSTEMS - LOTS ////////////////////////////////////////////////

  listSystemLots = (tenantId: string, systemId: string, args: any) => {
    return this.api_request<Lot[]>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/lots`,
      {
        body: args,
      }
    )
  }

  // SYSTEMS - TAGS ////////////////////////////////////////////////

  createTag = (tenantId: string, systemId: string, newTag: Partial<Tag>) => {
    return this.api_request<Tag>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/tags`,
      {
        body: newTag,
      }
    )
  }

  getTag = (tenantId: string, systemId: string, tagId: string) => {
    return this.api_request<Tag>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/tags/${tagId}`
    )
  }

  updateTag = (
    tenantId: string,
    systemId: string,
    tagId: string,
    tag: Partial<Tag>
  ) => {
    return this.api_request<Tag>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/tags/${U(tagId)}`,
      {
        body: tag,
      }
    )
  }

  deleteTag = (tenantId: string, systemId: string, tagId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/tags/${U(tagId)}`
    )
  }

  // SYSTEMS - TESTS ///////////////////////////////////////////////

  listSystemTests = (tenantId: string, systemId: string, args: any) => {
    return this.api_request<SystemTest[]>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/systems/${U(systemId)}/tests`,
      {
        body: args,
      }
    )
  }

  // SYSTEMS - TRIP REPORTS ////////////////////////////////////////

  listTripReports = (tenantId: string) => {
    return this.api_request<TripReport[]>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports`
    )
  }

  createTripReport = (tenantId: string, tripReport: Partial<TripReport>) => {
    return this.api_request<TripReport>(
      'POST',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports/`,
      {
        body: tripReport,
      }
    )
  }

  updateTripReport = (
    tenantId: string,
    tripReportId: string,
    tripReport: Partial<TripReport>
  ) => {
    return this.api_request<TripReport>(
      'PATCH',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports/${U(tripReportId)}`,
      {
        body: tripReport,
      }
    )
  }

  deleteTripReport = (tenantId: string, tripReportId: string) => {
    return this.api_request<boolean>(
      'DELETE',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports/${U(tripReportId)}`
    )
  }

  getTripReportUrl = (tenantId: string, tripReportId: string) => {
    return this.api_request<TripReportDownload>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports/${U(tripReportId)}/url`
    )
  }

  getTripReportUploadUrl = (tenantId: string, path: string) => {
    return this.api_request<FileUpload>(
      'GET',
      'data',
      `/api/tenants/${U(tenantId)}/trip_reports/upload_url/${P(path)}`
    )
  }

  // OTHER /////////////////////////////////////////////////////////

  /**
   * Cause an API error
   */
  getApiError = () => {
    return this.api_request('GET', 'data', `/api/error`)
  }
}
