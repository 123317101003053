import { useState } from 'react'

import { Typography } from '@material-ui/core'
import { VictoryChart } from 'victory'

import { useDimensions } from '../../lib/hooks'
import { hasValue } from '../../lib/utils'
import { ChartableTag, findMinMax, CHART_PADDING } from './shared'
import TagLegend from './TagLegend'
import TagLine from './TagLine'
import Tooltip from './Tooltip'
import XAxis from './XAxis'
import YAxis from './YAxis'

const CHART_HEIGHT = 120

/**
 * Per-unit chart info
 */
export type UnitChart = {
  unitKey: string
  units: string
  unitsLabel: string
  min_value?: number
  max_value?: number
  tagIndexes: number[]
}

type Props = {
  startTime: Date
  endTime: Date
  rows: any[][]
  tags: ChartableTag[]
  unitChart: UnitChart
  syncTooltips: boolean
  showDots: boolean
  forceMinMax: boolean
}

/**
 * Chart of tags that are grouped by the same unit
 */
const PerUnitChart: React.FC<Props> = (props: Props) => {
  const {
    startTime,
    endTime,
    rows,
    tags,
    unitChart,
    syncTooltips,
    showDots,
    forceMinMax,
  } = props

  // Measure the width of the wrapper to size the chart
  const [refDimensions, dimensions] = useDimensions()

  // Track which tag has been focused in the legend
  const [focusedTag, setFocusedTag] = useState<string | null>(null)

  // Find the range to fit all the tag data
  const [yMin, yMax] = unitChart.tagIndexes.reduce(
    ([min, max], tagIndex) => {
      const minMax = findMinMax(rows, tags[tagIndex], tagIndex, forceMinMax)
      return [Math.min(min, minMax.min), Math.max(max, minMax.max)]
    },
    [unitChart.min_value ?? Infinity, unitChart.max_value ?? -Infinity]
  )

  return (
    <div ref={refDimensions}>
      {dimensions && (
        <>
          <Typography>
            {unitChart.unitsLabel} ({unitChart.units})
          </Typography>
          <VictoryChart
            width={dimensions?.width}
            height={CHART_HEIGHT}
            domain={{
              x: [startTime, endTime],
              y: [yMin, yMax],
            }}
            padding={CHART_PADDING}
            scale={{
              x: 'time',
              y: 'linear',
            }}
            prependDefaultAxes={true}
            defaultAxes={{
              dependent: <XAxis />,
              independent: <YAxis />,
            }}
          >
            {unitChart.tagIndexes.map((tagIndex) => (
              <TagLine
                key={`tag-${tags[tagIndex].name}`}
                rows={rows}
                tags={tags}
                tagIndex={tagIndex}
                selectedTag={focusedTag}
                showDots={showDots}
                multiple={true}
              />
            ))}
            <Tooltip
              rows={rows}
              tags={tags}
              tagIndexes={unitChart.tagIndexes}
              sync={syncTooltips}
            />
          </VictoryChart>
          <TagLegend
            tags={tags}
            tagIndexes={unitChart.tagIndexes}
            focusedTag={focusedTag}
            setFocusedTag={setFocusedTag}
            orientation="horizontal"
          />
        </>
      )}
    </div>
  )
}

/**
 * Constrain the axis with the tag's min/max values
 */
const applyMinMax = (unitChart: UnitChart, tag: ChartableTag) => {
  if (hasValue(tag.min_value) || hasValue(tag.max_value)) {
    // Apply the tag's constraints to the axis
    if (hasValue(tag.min_value)) {
      unitChart.min_value = Math.min(
        tag.min_value,
        unitChart.min_value ?? Infinity
      )
    }
    if (hasValue(tag.max_value)) {
      unitChart.max_value = Math.min(
        tag.max_value,
        unitChart.max_value ?? -Infinity
      )
    }
  } else if (tag.units === '%') {
    // Force the V-axis range for percentages
    unitChart.min_value = Math.min(0, unitChart.min_value ?? Infinity)
    unitChart.max_value = Math.max(100, unitChart.max_value ?? -Infinity)
  }
}

/**
 * Building chart info for all the tgs grouped by unit
 *
 * @param tags tag defintions
 * @returns chart info grouped by unit
 */
export const groupTagsByUnit = (tags: ChartableTag[]): UnitChart[] => {
  const unitCharts: UnitChart[] = []
  tags.slice(1).forEach((tag, i) => {
    const unitKey = `${tag.unitsLabel} (${tag.units})`
    let unitChart: UnitChart | null =
      unitCharts.find((c) => c.unitKey === unitKey) ?? null

    if (!unitChart) {
      unitChart = {
        unitKey,
        units: tag.units,
        unitsLabel: tag.unitsLabel,
        min_value: tag.min_value,
        max_value: tag.max_value,
        tagIndexes: [],
      }
      unitCharts.push(unitChart)
    }

    // Apply the tag's min/max constraints to the Y-Axis
    applyMinMax(unitChart, tag)

    unitChart.tagIndexes.push(i + 1)
  })

  return unitCharts
}

export default PerUnitChart
