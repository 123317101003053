/**
 * Access to common App services like the the REST API, busy indicator, etc.
 *
 * For example, with a ClassComponent:
 *   import { withApp, WithAppProps } from '../providers';
 *   type Props = {
 *     // Local properties
 *    } & WithAppProps;
 *
 *   class Example extends react.Component<Props, State> {
 *      async render() {
 *        const data = this.props.api.getEntities();
 *
 *        return (
 *           ...
 *        );
 *      }
 *   }
 *
 *   export default withApp(Example);
 *
 * or a FunctionComponent:
 *
 *   import { useApp } from '../providers';
 *   export default async Example(props) {
 *      const { api } = useApp();
 *
 *      const data = await api.getEntities();
 *      return (
 *        ...
 *      );
 *   }
 */

import React, { useContext, useMemo } from 'react'

import hoistNonReactStatics from 'hoist-non-react-statics'

import RestAPI from '../lib/api'
import { AppStore, addError, incUiBusy, decUiBusy } from '../lib/redux'
import getDisplayName from './getDisplayName'

/*
 * Properties added by withApp()
 */
export interface WithAppProps {
  /** Interface to the REST API */
  api: RestAPI
  /** Report an error to the user */
  addError: (error: any) => void
  /** Wrap an event handler to catch and report errors */
  withCatch: (callback: any) => (...args: any[]) => void
  /** Show the busy indicator while running the callback */
  asBusy: <T>(callback: () => Promise<T>) => Promise<T>
}

/** Context for the App */
export const AppContext = React.createContext<WithAppProps>({} as WithAppProps)

/**
 * Provides the App context
 *
 * @returns the App context
 */
export const useApp = (): WithAppProps => useContext(AppContext)

/**
 * Wraps a component to provide the WithAppProps
 *
 * @param WrappedComponent the component to wrap
 * @returns the wrapper component
 */
export function withApp<
  P extends WithAppProps,
  R = Omit<P, keyof WithAppProps>
>(WrappedComponent: React.ComponentType<P>): React.ComponentType<R> {
  // Wrap the component with the consumer
  const Wrapper = (props: R) => (
    <AppContext.Consumer>
      {(context) => (
        <WrappedComponent
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(props as any)}
          api={context.api}
          addError={context.addError}
          withCatch={context.withCatch}
          asBusy={context.asBusy}
        />
      )}
    </AppContext.Consumer>
  )

  // Copy static methods from WrappedComponent to Wrapper
  hoistNonReactStatics(Wrapper, WrappedComponent)

  // Make the component name prettier for debugging
  if (process.env.NODE_ENV !== 'production') {
    Wrapper.displayName = `WithApp(${getDisplayName(WrappedComponent)})`
  }

  return Wrapper
}

type Props = {
  /** Redux store */
  store: AppStore
  /** Child components to render in the body */
  children?: React.ReactNode
}

/**
 * Component to provide the App context
 *
 * Note: This should only be used in index.jsx.
 */
const AppProvider: React.FC<Props> = (props: Props) => {
  // Memoize the context values to avoid unnecessary re-rendering
  const value = useMemo<WithAppProps>(
    () => ({
      // Interface to the REST API
      api: new RestAPI(props.store),

      // Report an error to the user
      addError: (error: any) => props.store.dispatch(addError(error)),

      // Wrap an event handler to catch and report errors
      withCatch:
        (callback: any) =>
        async (...args: any) => {
          try {
            await callback(...args)
          } catch (err) {
            props.store.dispatch(addError(err))
          }
        },

      // Show the busy indicator while running the callback
      asBusy: async <T,>(callback: () => Promise<T>): Promise<T> => {
        await incUiBusy(props.store)
        try {
          return await callback()
        } finally {
          await decUiBusy(props.store)
        }
      },
    }),
    // Update the memo if the store ever changes
    [props.store]
  )

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  )
}

export default AppProvider
