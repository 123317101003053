import { MenuItem, IconButton, Tooltip, SelectProps } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

import Picker from '../common/Picker'
import { RefreshRate, TimeRange } from './shared'

type Props = {
  /** Current selected time range */
  timeRange: TimeRange
  /** Current refresh rate */
  refreshRate: RefreshRate
  /** Callback triggered when the refresh rate is changed */
  onChange: (refreshRate: RefreshRate) => void
  /** Callback triggered when the refresh button is clicked */
  refresh: () => void
} & Omit<SelectProps, 'onChange'>

/**
 * Refresh rate selector and refresh button
 */
const RefreshPicker: React.FC<Props> = (props: Props) => {
  const { refreshRate, onChange, refresh, timeRange, ...selectProps } = props

  // The refresh is only active when using relative time ranges
  const disabled = !timeRange.relative

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
    >
      <Picker
        label="Refresh"
        value={refreshRate}
        onChange={(event) => onChange(event.target.value as RefreshRate)}
        disabled={disabled}
        {...selectProps}
      >
        <MenuItem value="None">None</MenuItem>
        <MenuItem value="1M">1 minute</MenuItem>
        <MenuItem value="5M">5 minutes</MenuItem>
        <MenuItem value="15M">15 minutes</MenuItem>
        <MenuItem value="1H">1 hour</MenuItem>
      </Picker>
      <Tooltip title="Refresh now">
        <IconButton size="small" onClick={refresh} disabled={disabled}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default RefreshPicker
