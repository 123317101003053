import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import fscreen from 'fscreen'

import { HMI, Tag } from '../../lib/types'
import { useApp, useSystem } from '../../providers'

import { ContentContainer, LoadPage } from '../common'
import ActiveTagChart from './ActiveTagChart'
import HmiRender from './HmiRender'

// How often to refresh the HMI data
const REFRESH_MS = 10 * 1000

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      card: {
        minHeight: '50vh',
        background: 'white',
      },
    }),
  { name: 'HmiView' }
)

type RouteParams = {
  hmi_name: string
}

/**
 * Parent view for the HMI
 */
const HmiView: React.FC = () => {
  const { hmi_name } = useParams<RouteParams>()
  const hmiRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()

  const [hmi, setHmi] = useState<HMI | null>(null)
  const [data, setData] = useState<any>(null)
  const [activeTag, setActiveTag] = useState<Tag | null>(null)

  const { system } = useSystem()
  const { api } = useApp()

  useEffect(() => {
    // Find the matching HMI
    const hmi = system.hmis?.find((h) => h.name === hmi_name) ?? null
    setHmi(hmi)

    const refreshData = async () => {
      // Fetch the current snapshot
      const data = await api.getSystemSnapshot(
        system.tenant_id,
        system.system_id
      )
      setData(data)
    }

    // Fetch the initial data
    refreshData()

    // Periodically reload
    const intervalId = window.setInterval(refreshData, REFRESH_MS)

    // Stop the refresh when unmounting
    return () => clearInterval(intervalId)
  }, [api, system, hmi_name])

  const fullscreenButton = fscreen.fullscreenEnabled ? (
    <IconButton
      onClick={() => {
        if (hmiRef.current) {
          fscreen.requestFullscreen(hmiRef.current)
        }
      }}
      title="Show fullscreen"
    >
      <FullscreenIcon />
    </IconButton>
  ) : null

  return (
    <ContentContainer>
      <Card>
        <CardHeader
          title={`HMI: ${hmi?.name ?? '-'}`}
          action={fullscreenButton}
        />
        <CardContent className={classes.card} ref={hmiRef}>
          {!hmi && ( // Bad HMI name
            <Typography>Unknown HMI: {hmi_name}</Typography>
          )}
          {hmi &&
            !data && ( // Still loading
              <LoadPage />
            )}
          {hmi && data && (
            <HmiRender hmi={hmi} data={data} setActiveTag={setActiveTag} />
          )}
          <ActiveTagChart
            tag={activeTag}
            onClose={() => setActiveTag(null)}
            container={hmiRef.current}
          />
        </CardContent>
      </Card>
    </ContentContainer>
  )
}

export default HmiView
