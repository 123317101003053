import { useState } from 'react'

import {
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Tooltip,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'

import { Position, POSITION_SCHEMA } from '../../../lib/types'

import BarcodeReader, { BarcodeScan } from '../../common/BarcodeReader'
import { AppTextField } from '../../common'
import { useApp } from '../../../providers'

/** Projected Life options - value in milliseconds */
const LIFE_OPTIONS = [
  { label: 'None', value: 0 },
  { label: '1 Month', value: 30 * 24 * 60 * 60 * 1000 },
  { label: '3 Months', value: 3 * 30 * 24 * 60 * 60 * 1000 },
  { label: '6 Months', value: 6 * 30 * 24 * 60 * 60 * 1000 },
  { label: '1 Year', value: 365 * 24 * 60 * 60 * 1000 },
  { label: '2 Years', value: 2 * 365 * 24 * 60 * 60 * 1000 },
]

/** Placeholder onScanDone callback for the BarcodeReader */
const DEFAULT_ON_SCAN_DONE = (_scan: BarcodeScan) => null

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      positionForm: {
        '& > *': {
          margin: theme.spacing(1, 0),
        },
      },
    }),
  { name: 'PositionDialog' }
)

type Props = {
  /** The position being edited */
  position: Position | null
  onClose: (refresh: boolean) => any
}

/** Position editing dialog */
const PositionDialog: React.FC<Props> = (props: Props) => {
  const { position, onClose } = props
  const { api } = useApp()
  const { enqueueSnackbar } = useSnackbar()

  /** Callback for the BarcodeReader */
  const [onScanDone, setOnScanDone] = useState<
    null | ((scan: BarcodeScan) => any)
  >(null)

  const classes = useStyles()

  if (!position) {
    return null
  }

  // Use the scan data
  const handleScanDone = (scan: BarcodeScan, form: FormikProps<Position>) => {
    const now = new Date()

    // Update fields in the position editing dialog
    form.setFieldValue('serial', scan.data.serialNumber)
    form.setFieldValue('installDate', now.toISOString())

    // Close the scanning dialog
    setOnScanDone(null)
  }

  // Open the scanning dialog
  const handleScanStart = (form: FormikProps<Position>) => {
    // Note: the leading '() =>' wraps the onScanDone function since useState()
    // will call functions assuming thy are lazy initializers
    setOnScanDone(() => (scan: BarcodeScan) => handleScanDone(scan, form))
  }

  const cancelScan = () => setOnScanDone(null)

  /** Save the updates from the position editing form*/
  const savePosition = async (updates: Position) => {
    const position = await api.updatePosition(
      updates.tenant_id,
      updates.position_id,
      updates
    )
    if (position) {
      // Notify the user
      enqueueSnackbar(`Updated position "${position.name}"`, {
        variant: 'success',
      })
      // All done
      onClose(true)
    }
  }

  return (
    <Formik
      validationSchema={POSITION_SCHEMA}
      initialValues={position}
      onSubmit={(values, { setSubmitting }) => {
        savePosition(values)
        setSubmitting(false)
      }}
    >
      {(f) => (
        <Dialog
          open={!!position}
          scroll="paper"
          fullWidth
          onClose={() => onClose(false)}
        >
          <DialogTitle>Edit Position &quot;{position.name}&quot;</DialogTitle>
          <DialogContent>
            <form
              id="PositionForm"
              noValidate
              onSubmit={f.handleSubmit}
              className={classes.positionForm}
            >
              <AppTextField
                name="serial"
                label="Serial Number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Scan the barcode on the part">
                        <Button onClick={() => handleScanStart(f)}>Scan</Button>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <AppTextField
                name="installDate"
                label="Date Installed"
                type="date"
                fullWidth
                InputLabelProps={{
                  // Keep the date placeholder and input label separated
                  shrink: true,
                }}
              />
              <AppTextField
                name="lifeExpectancy"
                label="Projected List"
                select
                fullWidth
              >
                {LIFE_OPTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </AppTextField>
              <AppTextField
                name="notes"
                label="Notes"
                fullWidth
                multiline
                minRows={3}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <BarcodeReader
              dialogStyle={{ width: '300px' }}
              delay={500}
              title="Scan Product"
              open={!!onScanDone}
              onDone={onScanDone ?? DEFAULT_ON_SCAN_DONE}
              onCancel={cancelScan}
              parseScan={parseScan}
              formatScan={formatScan}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>Cancel</Button>
            <Button
              onClick={() => f.submitForm()}
              disabled={!f.dirty || f.isSubmitting}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

/** Parse the barcode data for the barcode dialog */
const parseScan = (text: string) => {
  // The product scan text should look like:
  //   $!001||SPROBAND|KDP3887-CH|SPIRAL,HP 8038-RO-HT-ES1||KM8062777-9071
  //   or
  //   $!P01|KM8055925-028
  if (!text || text.search(/\$![A-Za-z0-9]{3}\|/) !== 0) {
    // Maybe this was some other barcode
    return null
  }

  const tokens = text.split('|')

  const output: any = {
    serialNumber: tokens[tokens.length - 1],
  }

  // check for different barcode types
  if (text.search('$!P01') === 0) {
    // data matrix barcode
    output.partNumber = tokens[3]
  } else if (text.search('$!001') === 0) {
    // pdf-417 barcode
    // Nothing to do yet
  }

  return output
}

/** Show the serial and part number below the scan in the barcode dialog */
const formatScan = (data: any) => {
  return (
    <List>
      <ListItem>
        <ListItemText
          primary={<span>Serial # {data.serialNumber}</span>}
          secondary={<span> Part # {data.partNumber}</span>}
        />
      </ListItem>
    </List>
  )
}

export default PositionDialog
