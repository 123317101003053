import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Alarm } from '../../lib/types'
import StatusIcon from '../common/StatusIcon'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        margin: theme.spacing(1, 0),
      },
      colorBox: {
        border: '1px solid',
        '&.error': {
          borderColor: theme.palette.error.main,
        },
        '&.warning': {
          borderColor: theme.palette.warning.main,
        },
      },

      statusIcon: {
        marginRight: theme.spacing(1),
      },
    }),
  { name: 'SystemAlarms' }
)

type Props = {
  alarms: Alarm[] | null
}

const SystemAlarms: React.FC<Props> = (props: Props) => {
  const { alarms } = props
  const classes = useStyles()
  if (!alarms || !alarms.length) {
    return null
  }

  const { alerts, warnings } = alarms.reduce(
    (a, alarm) => {
      switch (alarm.level) {
        case 1:
          a.warnings.push(alarm)
          break
        case 2:
          a.alerts.push(alarm)
          break
        default:
          break
      }
      return a
    },
    {
      alerts: [] as Alarm[],
      warnings: [] as Alarm[],
    }
  )

  return (
    <div className={classes.root}>
      {!!alerts.length && (
        <ExpansionPanel className={`${classes.colorBox} error`}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="system-errors"
            id="system-errors"
          >
            <StatusIcon status="error" className={classes.statusIcon} />
            <Typography>
              {alerts.length === 1
                ? `There is ${alerts.length} system alarm`
                : `There are ${alerts.length} system alarms`}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {alerts.map((alert) => (
                <ListItem key={alert.tag.tag_id}>
                  <ListItemText
                    primary={alert.label}
                    secondary={alert.tag.name}
                  />
                </ListItem>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      {!!warnings.length && (
        <ExpansionPanel className={`${classes.colorBox} warning`}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="system-warnings"
            id="system-warnings"
          >
            <StatusIcon status="warn" className={classes.statusIcon} />
            <Typography>
              {warnings.length === 1
                ? `There is ${warnings.length} system warning`
                : `There are ${warnings.length} system warnings`}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List className={classes.root}>
              {warnings.map((warning) => (
                <ListItem key={warning.tag.tag_id}>
                  <ListItemText
                    primary={warning.label}
                    secondary={warning.tag.name}
                  />
                </ListItem>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </div>
  )
}

export default SystemAlarms
