import { useEffect } from 'react'

import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import * as d3 from 'd3'

import { formatDate } from '../../lib/dates'
import { HMI, HMIKey, Tag } from '../../lib/types'
import { useSystem } from '../../providers'

// Type alias for the selected SVGElement
type tSVG = d3.Selection<SVGElement, any, HTMLElement, any>

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      hmi: {
        width: '100%',
        height: '100%',
        position: 'relative',

        '& svg': {
          width: '100%',
          height: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          '& .chart': {
            cursor: 'pointer',
            '& > rect': {
              fill: theme.palette.primary.main,
            },
            '& text': {
              fill: theme.palette.primary.contrastText,
            },
            '&:hover': {
              '& > rect': {
                fill: theme.palette.secondary.main,
              },
              '& text': {
                fill: theme.palette.secondary.contrastText,
              },
            },
          },
        },

        '& .hmi-wrapper': {
          width: '100%',
          height: '100%',
        },
        '& .hmi-timestamp': {
          position: 'absolute',
          bottom: '0',
          right: '0',
        },
      },
    }),
  { name: 'HmiRender' }
)

// Message Template variable pattern
//   /*N:4 {INTEG_DECAY} NOFILL DP:2*/
//   /*N:4 {[PLC]T2_MC_BF1_REM} NOFILL DP:0*/
const RE_BUTTON_TEMPLATE = /\/\*.*?{(?:\[PLC\])?(\w+)}.*?(?:DP:(\d+))?\*\//g

type Props = {
  hmi: HMI
  data: any
  setActiveTag: (tag: Tag) => void
}

const HMIRender: React.FC<Props> = (props: Props) => {
  const { hmi, data, setActiveTag } = props

  const classes = useStyles()
  const { system } = useSystem()

  useEffect(() => {
    // Find all the tags available for graphing
    const tags = system.tags?.filter((tag) => tag.is_trending) || []
    initChartableTags(hmi, tags, setActiveTag)
  }, [system, hmi])

  useEffect(() => updateHMI(hmi, data), [hmi, data])

  return (
    <div className={classes.hmi}>
      <div
        className="hmi-wrapper"
        id={`hmi-svg--${hmi.name}`}
        dangerouslySetInnerHTML={{ __html: hmi.svg }}
      />
      <Typography color="textSecondary" className="hmi-timestamp">
        {formatDate(data.timestamp)}
      </Typography>
    </div>
  )
}

/**
 * Update the HMI SVG elements
 */
const updateHMI = (hmi: HMI, data: any) => {
  const svg = d3.select<SVGElement, any>(`#hmi-svg--${hmi.name} svg`)
  if (svg.empty()) {
    // The element is not on the page...
    return
  }

  // Loop over the HMI keys and try to find to matching tag data in the
  // snapshot.
  for (const key of hmi.keys) {
    if (!key.tag) {
      // Key has not yet been mapped to a tag
      continue
    }

    // Look for the tag data
    const tag_key = `T:${key.tag}`
    const value = data[tag_key]

    if (value === null || value === undefined) {
      // No tag data
      continue
    }

    // Update each key according to its type
    switch (key.type) {
      case 'text':
        updateText(svg, key, value)
        break
      case 'button':
        updateButton(svg, key, value, data)
        break
      case 'color':
        animateColor(svg, key, value)
        break
      case 'visible':
        animateVisible(svg, key, value)
        break
      case 'fill':
        animateFill(svg, key, value)
        break
      default:
        break
    }
  }
}

/**
 * Initialize the chartable tags
 */
const initChartableTags = (
  hmi: HMI,
  tags: Tag[],
  setActiveTag: (tag: Tag) => void
) => {
  const svg = d3.select<SVGElement, any>(`#hmi-svg--${hmi.name} svg`)
  if (svg.empty()) {
    // The element is not on the page...
    return
  }

  for (const key of hmi.keys) {
    if (!key.tag) {
      // Key has not yet been mapped to a tag
      continue
    }

    const tag = tags.find((tag) => tag.tag_id === key.tag)
    if (!tag) {
      // Not a Live Data tag
      continue
    }

    switch (key.type) {
      case 'text':
        // Highlight the chartable tags and bind click listeners
        svg.select<SVGElement>(`#text-${key.id}`).each(function () {
          d3.select(this.closest('g'))
            .classed('chart', true)
            .datum(tag)
            .on('click', () => setActiveTag(tag))
        })
        break
      default:
        break
    }
  }
}

/**
 * Format a number to a certain number of places
 */
const formatNumber = (value: string, places: number) => {
  return Number.parseFloat(value).toFixed(places)
}

const updateText = (svg: tSVG, key: HMIKey, value: any) => {
  if (!isNaN(value)) {
    // Round the value
    let places = 2
    if (key.args && key.args.length >= 1) {
      places = key.args[0]
    }
    value = formatNumber(value, places)
  }
  svg.select(`#text-${key.id}`).text(value)
}

const updateButton = (svg: tSVG, key: HMIKey, value: any, data: any) => {
  // Find the button
  const button = svg.select(`#button-${key.id}`)

  // Show the button state
  button.attr('state', value)

  // Now deal with placeholders in the caption ...

  // Find the matching text element
  const text = button.select(`.button-state[state="${value}"] text[template]`)
  if (text.empty()) {
    // No template for this state - show the caption as-is
    return
  }

  // Find the template
  const template: string = text.attr('template')

  // Generate the caption by replacing the template placeholders
  const caption = template.replaceAll(
    RE_BUTTON_TEMPLATE,
    (m, tag: string, places: number) => {
      // Get the placeholder value
      const pv = data[`T:${tag}`]

      if (pv === null || pv === undefined) {
        // No tag data - show something
        return '###'
      }
      if (isNaN(pv)) {
        // Tag is not a number - use the value as-is
        return pv
      }

      // Format the data as a number
      return formatNumber(pv, places || 0)
    }
  )

  // Update the caption text
  text.text(caption)
}

const animateColor = (svg: tSVG, key: HMIKey, value: any) => {
  svg.select(`.animate--${key.id}`).attr('animate', value)
}

const animateVisible = (svg: tSVG, key: HMIKey, value: any) => {
  svg.select(`.animate--${key.id}`).attr('animate', value)
}

const animateFill = (svg: tSVG, key: HMIKey, value: any) => {
  // Fill values are percentages
  value = Number.parseFloat(value) / 100.0

  // Find the background rect
  const bg = svg.select(`.animate--${key.id} rect.animate-fill-bg`)

  // Adjust the height and position to lower the "liquid"
  const full_y = parseFloat(bg.attr('full_y'))
  const full_height = parseFloat(bg.attr('full_height'))

  const new_height = full_height * value
  const new_y = full_y + (full_height - new_height)

  bg.attr('y', new_y).attr('height', new_height)
}

export default HMIRender
