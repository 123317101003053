/**
 * CSV Download utilities
 */

// Delay before auto-revoking the CSV blob: URL
const CSV_AUTO_REVOKE_DELAY_MS = 10 * 1000

// Pattern for valid numeric tag data
const RE_NUMBER = /^[-+]?([0-9]+|[0-9]*[.,][0-9]+)$/

// Pattern for an ISO8601 UTC timestamp, likely from the API
const RE_UTC = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\dZ$/

/**
 * Format a number as two digits with leading 0s
 *
 * @param {number} num the number to format
 * @returns {string} formatted number as text
 */
const pad00 = (num: number): string => {
  return num < 10 ? `0${num}` : num.toString()
}

/**
 * Format a Date in a way that Excel will honor.
 * Note: this formats the date in the browser's timezone
 *
 * @param {Date} date
 * @returns {string} formatted Date as text
 */
const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const mon = pad00(date.getMonth() + 1)
  const day = pad00(date.getDate())
  const hour = pad00(date.getHours())
  const min = pad00(date.getMinutes())
  const sec = pad00(date.getSeconds())

  return `${year}-${mon}-${day} ${hour}:${min}:${sec}`
}

/**
 * Format a single cell value
 * @param {*} value
 * @returns {string} formatted cell value, escaped and quoted for CSV
 */
const formatValue = (value: any): string => {
  if (value === null || value === undefined) {
    // Empty cells should empty, not "null"
    value = ''
  } else if (typeof value === 'string') {
    if (value.match(RE_UTC)) {
      // Format date strings in a more Excel-friendly manner
      value = formatDate(new Date(value))
    } else if (!value.match(RE_NUMBER)) {
      // Escape double quotes
      value = value.replace(/"/g, '""')
      // Escape possible CSV injection characters
      // See: https://affinity-it-security.com/how-to-prevent-csv-injection/
      value = value.replace(/^(\+|^-|^=|^@)/g, "'$1")
    }
  } else if (value instanceof Date) {
    // Format dates in a more Excel-friendly manner
    value = formatDate(value)
  }

  // All cells get quoted
  return `"${value}"`
}

/**
 * Format an array of values into a CSV line
 *
 * @param {*[]} values
 * @returns {string} CSV line
 */
const makeLine = (values: any[] | null): string => {
  if (!values) {
    return ''
  }

  return values.map(formatValue).join(',')
}

/**
 * Format the header and rows into CSV data
 *
 * @param {*[]} header Array of column headers
 * @param {*[][]} rows Array of data rows
 * @returns {string} CSV text
 */
export const makeCsvData = (header: string[], rows: any[][]): string => {
  const lines = []
  // Format the header
  lines.push(makeLine(header))

  // Format each line
  rows.forEach((row) => lines.push(makeLine(row)))

  // Combine into one string
  return lines.join('\r\n')
}

/**
 * Construct a URL to download the data as a CSV file
 *
 * Note: this function may return a blob: URL that should be revoked once used to avoid memory leaks.
 * Setting autoRevoke to true will automatically revoke the blob: URL after a delay.
 *
 * @param {*[]} header Array of column headers
 * @param {*[][]} data Array of data rows
 * @param {boolean} [autoRevoke=true] if true, automatically revoke the Object URL after a delay
 * @returns {string} URL for CSV download
 */
export const makeCsvUrl = (
  header: string[],
  data: any[][],
  autoRevoke = true
): string => {
  // Assemble the CSV data
  const csv = makeCsvData(header, data)

  if (URL.createObjectURL) {
    // Create a blob: URL to the CSV data
    const csvUrl = URL.createObjectURL(
      new Blob([csv], {
        type: 'text/csv',
      })
    )

    if (autoRevoke) {
      // Revoke the blob: URL after a brief delay
      setTimeout(() => URL.revokeObjectURL(csvUrl), CSV_AUTO_REVOKE_DELAY_MS)
    }

    return csvUrl
  } else {
    // As a fallback, use a data: URL
    return `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
  }
}

/**
 * Download the header/data as a CSV file.
 *
 * @param {string} label Name of downloaded file
 * @param {*[]} header Array of column headers
 * @param {*[][]} data Array of data rows
 */
export const downloadCsv = (
  label: string,
  header: string[],
  data: any[][]
): void => {
  // Assemble the CSV content as a blob: or data: URL
  const csvUrl = makeCsvUrl(header, data)

  // Make sure the filename has no directory characters
  const filename = label.replace(/[\\/]/g, '_') + '.csv'

  // Click on a fake <A> element to trigger the download
  const link = document.createElement('a')
  link.setAttribute('href', csvUrl)
  link.setAttribute('download', filename)
  link.click()
}
