import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Divider, Grid, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import DateFnsUtils from '@date-io/date-fns'
import cx from 'classnames'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import formatUTC from '../../lib/formatUTC'
import { Input } from '../../lib/types'
import { useApp, useSystem } from '../../providers'
import { ContentContainer } from '../common'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      submitButton: {
        fontWeight: 'bold',
        margin: '1em 0em',
      },
      topHeader: {
        color: theme.palette.primary.light,
        textAlign: 'left',
      },
      row: {
        padding: '0.5em 0em',
      },
      oddRow: {
        backgroundColor: '#E6EDE8',
      },
    }),
  { name: 'InfluentEntry' }
)

/**
 * Form to submit new Influent log entries.
 */
const InfluentEntry: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  const { api, asBusy } = useApp()
  const { system } = useSystem()

  const [inputTypes, setInputTypes] = useState<Input[]>([])
  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const [timestamp, setTimestamp] = useState<Date>(new Date())

  useEffect(() => {
    // Fetch the inputs
    api
      .listSystemInputs(system.tenant_id, system.system_id)
      .then((inputTypes) => {
        if (inputTypes) {
          setInputTypes(inputTypes)
          setFormValues({})
        }
      })
  }, [api, system])

  const updateFormValue = (inputType: Input, value: any) => {
    // Change the value for this single input
    setFormValues({
      ...formValues,
      [inputType.input_id]: value,
    })
  }

  const inputRows = inputTypes.map((inputType, i) => {
    // Build an input control for the input type
    let inputControl
    switch (inputType.type) {
      case 'number':
        inputControl = (
          <TextField
            type="number"
            value={formValues[inputType.input_id]}
            onChange={(e) => updateFormValue(inputType, e.target.value)}
            inputProps={{ step: 1 }}
          />
        )
        break

      default:
        inputControl = `[Unknown type "${inputType.type}"]`
        break
    }

    // Assemble the form row
    return (
      <Grid
        key={inputType.input_id}
        item
        xs={12}
        container
        alignItems="center"
        className={cx(classes.row, { [classes.oddRow]: i % 2 })}
      >
        <Grid item xs={9}>
          {inputType.name}
        </Grid>
        <Grid item xs={2}>
          {inputControl}
        </Grid>
        <Grid item xs={1}>
          {inputType.units}
        </Grid>
      </Grid>
    )
  })

  const onFormSubmit = async () => {
    const inputValues = inputTypes
      .map((inputType) => {
        // Map to the format the API is expecting
        return {
          input_id: inputType.input_id,
          value: formValues[inputType.input_id],
        }
      })
      .filter((inputValue) => {
        // But only include those with actual values
        return inputValue.value !== undefined && inputValue.value !== ''
      })
    if (!inputValues.length) {
      // Nothing to send
      return
    }

    asBusy(async () => {
      // Send the entry to the API
      await api.postInfluentEntry(
        system.tenant_id,
        system.system_id,
        formatUTC(timestamp),
        inputValues
      )
    })

    // Check out the new entry in the chart.
    setTimeout(() => history.push('/influent/logs'), 1500)
  }

  return (
    <ContentContainer title="Influent - Enter Data">
      <Grid container direction="column">
        <Grid item xs={12} container classes={{ root: classes.topHeader }}>
          <Grid item xs={9}>
            Name
          </Grid>
          <Grid item xs={2}>
            Value
          </Grid>
          <Grid item xs={1}>
            Units
          </Grid>
        </Grid>
        <Divider style={{ margin: '0.6em 0' }} />
        {inputRows}
        <Divider style={{ margin: '0.6em 0' }} />
      </Grid>
      <Grid container alignItems="center" justifyContent="flex-end" spacing={4}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="Entry time"
              value={timestamp}
              onChange={(value) => setTimestamp(value ?? new Date())}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.submitButton }}
            onClick={onFormSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default InfluentEntry
