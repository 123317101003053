import { useTheme } from '@material-ui/core/styles'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RadialGauge } from 'react-canvas-gauges'

import formatDecimal from '../../lib/formatDecimal'

import {
  GAUGE_WIDTH,
  Highlights,
  TagCardProps,
  findNeedleColor,
  findTicks,
} from './shared'

type Props = {
  highlights: Highlights
} & TagCardProps

/**
 * Display a tag value as a radial gauge
 */
const TagRadialGauge: React.FC<Props> = (props: Props) => {
  const { perfTag, demo, highlights } = props

  const theme = useTheme()

  const { tag, value } = perfTag

  const ticks = findTicks(perfTag)
  const needleColor = findNeedleColor(theme, demo)

  return (
    <RadialGauge
      units={demo.radial_units ? tag.units : false}
      value={value}
      minValue={tag.min_value}
      maxValue={tag.max_value}
      majorTicks={ticks}
      highlights={highlights.ranges}
      width={GAUGE_WIDTH}
      height={GAUGE_WIDTH}
      borders={false}
      borderShadowWidth={0}
      needle={demo.radial_needle}
      colorNeedle={needleColor}
      colorNeedleEnd={needleColor}
      colorBarProgress={theme.palette.primary.main}
      barWidth={demo.radial_bar ? demo.bar_width : 0}
      valueBox={demo.radial_valuebox}
      valueText={formatDecimal(value, 1)}
      ticksAngle={demo.radial_half ? 180 : 270}
      startAngle={demo.radial_half ? 90 : 45}
      animation={true}
      animationRule="quad"
      animateOnInit={true}
      animationDuration={1000}
      animationTarget="needle"
    />
  )
}

export default TagRadialGauge
