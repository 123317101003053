import { Checkbox, FormControlLabel } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CircleCheckedIcon from '@material-ui/icons/CheckCircle'
import CircleIcon from '@material-ui/icons/Brightness1'

import cx from 'classnames'

import { ChartableTag } from './shared'

/** Maximum number of tags that can be selected to show their Y-axis */
const DEFAULT_MAX_SELECTIONS = 4

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        fontSize: '12px',
        padding: theme.spacing(2),
        display: 'flex',
        '&.horizontal': {
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        '&.vertical': {
          display: 'flex',
          flexDirection: 'column',
        },
      },
      tagWrapper: {
        padding: theme.spacing(0.5, 1),
      },
      tagName: {
        fontSize: 12,
        whiteSpace: 'nowrap',
        '&.horizontal': {
          borderBottom: '2px solid transparent',
        },
        '&.vertical': {
          paddingLeft: theme.spacing(0.5),
          borderLeft: '2px solid transparent',
        },
        '&.selected': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiFormControlLabel-label': {
          marginLeft: theme.spacing(1),
          fontSize: 12,
        },
      },
      tagMarker: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        display: 'inline-block',
      },
      checkbox: {
        padding: 0,
      },
    }),
  { name: 'TagLegend' }
)

type Props = {
  /** All displayed tags */
  tags: ChartableTag[]
  /** Tag indexes, for picking colors */
  tagIndexes?: number[]
  /** Orientation of tag name list */
  orientation: 'horizontal' | 'vertical'
  /** Tag which is currently focused */
  focusedTag?: string | null
  /** Callback triggered when a tag is focused */
  setFocusedTag?: (tag: string | null) => any
  /** Tags which are currently selected to show their Y-axis */
  selectedTags?: string[]
  /** Callback triggered when a tag is selected to show its Y-axis */
  setSelectedTags?: (tags: string[]) => any
  /** How many tags can be selected at the same time */
  maxSelections?: number
}

/**
 * Legend of tag colors and names.
 *
 * Hovering on a tag label sets the selected tag for highlighting.
 */
const TagLegend: React.FC<Props> = (props: Props) => {
  const {
    tags,
    tagIndexes,
    orientation,
    focusedTag = null,
    setFocusedTag = () => null,
    selectedTags = [],
    setSelectedTags,
    maxSelections = DEFAULT_MAX_SELECTIONS,
  } = props

  const classes = useStyles()

  // Callback to toggle a tag selection
  const toggleTag = (tagName: string, checked: boolean) => {
    if (setSelectedTags) {
      if (checked) {
        setSelectedTags(selectedTags.filter((t) => t !== tagName))
      } else if (selectedTags.length < maxSelections) {
        setSelectedTags([...selectedTags, tagName])
      }
    }
  }

  return (
    <div className={cx(classes.container, orientation)}>
      {tags.map((tag, tag_index) => {
        if (tag.units === 'time') {
          // Skip the Time pseudo-tag
          return null
        }

        if (tagIndexes && !tagIndexes.includes(tag_index)) {
          // This tag is not in the current graph
          return null
        }
        const color = tag.color
        const label = `${tag.name} (${tag.units})`
        const checked = selectedTags.includes(tag.name)

        return (
          <div
            key={tag.name}
            className={classes.tagWrapper}
            onMouseEnter={() => setFocusedTag(tag.name)}
            onMouseLeave={() => setFocusedTag(null)}
          >
            <FormControlLabel
              label={label}
              className={cx(classes.tagName, orientation, {
                selected: focusedTag === tag.name,
              })}
              onChange={() => toggleTag(tag.name, checked)}
              control={
                <Checkbox
                  size="small"
                  className={classes.checkbox}
                  checked={checked}
                  style={{ color }}
                  checkedIcon={<CircleCheckedIcon fontSize="small" />}
                  icon={<CircleIcon fontSize="small" />}
                />
              }
            />
          </div>
        )
      })}
    </div>
  )
}

export default TagLegend
