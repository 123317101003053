// OpenAPI types ---------------------------------------------------------------
// These correspond to the schemas of the same names in openapi.json from the
// kss-septrac-api stack.

import * as yup from 'yup'

// Don't complain about the yup templates
/* eslint-disable no-template-curly-in-string */

// NOTE: This is a work-in-progress
// TODO: Comment the classes and fields
// TODO: Fix the optional fields

/** Maximum size of System image */
const MAX_SYSTEM_IMAGE_BYTES = 350000

// COMMMON ////////////////////////////////////////////////////////////

export type AlarmLevel = 0 | 1 | 2

export type Alarm = {
  tag: Tag
  level: AlarmLevel
  label: string
}

export type FileUpload = {
  key: string
  put_url: string
  headers: Record<string, string>
  s3_url: string
}

export type FileDownload = {
  url: string
  ttl: number
}

// USERS //////////////////////////////////////////////////////////////

export type User = {
  username: string
  email: string
  role: string
  status: string
  enabled: boolean
  tenants?: UserTenant[]
  tenant_ids?: string[]
}

export type UserTenant = {
  tenant_id: string
  name: string
}

export const USER_SCHEMA = yup.object({
  username: yup.string().label('Username').required(),
  email: yup.string().label('Email Address').required().email(),
  role: yup.string().label('Role').required(),
  tenant_ids: yup.array().when('role', {
    is: (value: string) => value === 'customer' || value === 'premium',
    then: yup.array().min(1, 'Must select at least ${min} Customers'),
  }),
})

// TENANTS ////////////////////////////////////////////////////////////

export type Tenant = {
  tenant_id: string
  group_name: string
  role_arn: string
  created: string
  updated: string
  name: string
  status: string
  customer_number: string
  totalVisits: number
  remainingVisits: number
  techName: string
  assistStartDate: string
  assistEndDate: string
  sort: number
  system_names: Record<string, string>
}

export const TENANT_SCHEMA = yup.object({
  name: yup
    .string()
    .label('Name')
    .required()
    .matches(/^[^,"]+$/, 'Name cannot include quotes (") or commas (,).'),
  customer_number: yup.string().label('Customer Number').default(''),
  techName: yup.string().label('Service Tech').default(''),
  assistStartDate: yup.date().label('Start Date'),
  assistEndDate: yup.date().label('End Date'),
  totalVisits: yup
    .number()
    .label('Site Visits Included')
    .min(0)
    .default(0)
    .required(),
  remainingVisits: yup
    .number()
    .label('Site Visits Remaining')
    .min(0)
    .default(0)
    .required(),
})

export type OrderHistoryItem = {
  status: string
  part_number: string
  part_description: string
  quantity_ordered: string
  quantity_shipped: string
  tracking_number: string
  carrier: string
}

export type OrderHistory = {
  tenant_id: string
  customer_number: string
  order_id: string
  customer_po: string
  order_date: string
  st_no: string
  st_city: string

  items: OrderHistoryItem[]
}

export type ProblemReport = {
  SO_number: string
  application: string
  company_name: string
  customer_email: string
  customer_name: string
  customer_phone: string
  part_no: string
  part_quantity: string
  plant_location: string
  problem_descrip: string
  prod_descrip: string
  serial_numbers: string
}

// DOCS ///////////////////////////////////////////////////////////////

export type Doc = {
  tenant_id: string
  system_id: string
  doc_id: string
  name: string
  url: string
  ttl: number
  created: string
  updated: string
}

export type DocDownload = Doc & FileDownload

// HMIS ///////////////////////////////////////////////////////////////

export type HMI = {
  tenant_id: string
  system_id: string
  hmi_id: string
  name: string
  svg: string
  keys: HMIKey[]
  created: string
  updated: string
  sort: number
}

export type HMIKey = {
  id: string
  type: string
  tag: string
  args?: any[]
}

// INPUTS /////////////////////////////////////////////////////////////

export type Input = {
  name: string
  type:
    | 'number'
    | 'datetime'
    | 'string'
    | 'flag'
    | 'message'
    | 'alarm'
    | 'total'
  units: string
  unitsLabel?: string
  sort: number
  system_id: string
  tenant_id: string
  input_id: string
  created: string
  updated: string
  color: string
}

export const INPUT_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  type: yup.string().label('Type').oneOf(['number']).required(),
  units: yup.string().label('Units').required(),
  unitsLabel: yup.string().label('Units Label').required(),
  min_value: yup.number().label('Min Value').nullable(),
  max_value: yup.number().label('Max Value').nullable(),
  color: yup.string().label('Color').nullable(),
})

// MODULES ////////////////////////////////////////////////////////////

export type Module = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  module_id: string
  name: string
  created: string
  updated: string
  sort: number
}

export const MODULE_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
})

// PARTS //////////////////////////////////////////////////////////////

export type Part = {
  tenant_id: string
  system_id: string
  part_id: string
  name: string
  partType: string
  partNumber: string
  qty: number
  created: string
  updated: string
  sort: number
}

export const PART_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  partType: yup.string().label('Type').required(),
  partNumber: yup.string().label('Part Number').required(),
})

export type OrderInformation = {
  part_number: string
  description: string
  quantity: string | number // TODO: ??
}

// POSITIONS //////////////////////////////////////////////////////////

export type Position = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  module_id: string
  position_id: string
  created: string
  updated: string
  name: string
  partNumber: string
  serial: string
  installDate: string
  lifeExpectancy: number
  notes: string
  sort: number

  // Admin form fields
  part?: Part | null
}

export const POSITION_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  serial: yup.string().label('Serial Number').nullable(),
  installDate: yup.date().label('Date Installed').nullable(),
  lifeExpectancy: yup.number().label('Projected Life').nullable(),
  notes: yup.string().label('Notes').nullable(),
})

// SUBSYSTEMS /////////////////////////////////////////////////////////

export type Subsystem = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  name: string
  type: string
  default_tags: string[] | null
  created: string
  updated: string
  sort: number
}

export const SUBSYSTEM_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  type: yup.string().label('Type').required(),
})

export type TrendingData = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  start_utc: string
  end_utc: string
  tags: Tag[]
  rows: any[][]
}

export type SubsystemPerformance = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  name: string
  values: TagPerformance[]

  // GUI
  sort?: number
  alarmLevel?: AlarmLevel
  alarms?: Alarm[]
}

export type SubsystemStatus = Subsystem & {
  alarmLevel: AlarmLevel
  alarms: Alarm[]
}

// SYSTEMS ////////////////////////////////////////////////////////////

export type System = {
  tenant_id: string
  system_id: string
  created: string
  updated: string

  alarm_topic: string

  name: string
  device_id: string
  location: string
  nominal_flow_rate: string
  membrane_product: string
  number_filtration_trains?: string
  st_no: string
  sort: number

  imageSrc: string
  show_influent?: boolean
  show_subsystems?: boolean
  show_lots?: boolean
  show_tests?: boolean
  timezone: string

  docs?: Doc[]
  inputs?: Input[]
  parts?: Part[]
  subsystems?: Subsystem[]
  tags?: Tag[]
  hmis?: HMI[]

  // Admin form fields
  docSlots?: Doc[]
}

export const SYSTEM_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  imageSrc: yup
    .string()
    .label('Image')
    .max(MAX_SYSTEM_IMAGE_BYTES, 'Image is too large - ${max} bytes maximum'),
})

export type SystemStatus = {
  tenant_id: string
  system_id: string
  subsystems: SubsystemStatus[]
  alarmLevel: AlarmLevel
  otherAlarms?: Alarm[]
}

export type SystemPerformance = {
  tenant_id: string
  system_id: string
  name: string
  subsystems: SubsystemPerformance[]
}

// SYSTEMS - ALARM LOG ////////////////////////////////////////////////

export type AlarmLogEntry = {
  tenant_id: string
  system_id: string
  subsystem_id?: string
  tag_id: string
  tag_name: string
  timestamp: string
  alarm?: string
  level: number
  text: string
  tags?: any
}

export type AlarmLog = {
  tenant_id: string
  system_id: string
  start_utc: string
  end_utc: string
  rows: AlarmLogEntry[]
}

// SYSTEMS - ALARM SUBSCRIPTIONS //////////////////////////////////////

export type SubscriptionStatus = {
  tenant_id: string
  system_id: string
  endpoint: string
  protocol: string
  status: 'SUBSCRIBED' | 'NOT_SUBSCRIBED' | 'PENDING_CONFIRMATION' | 'NO_TOPIC'
}

// SYSTEMS - INFLUENT /////////////////////////////////////////////////

export type InfluentLog = {
  tenant_id: string
  system_id: string
  start_utc: string
  inputs: Input[]
  rows: any[][]
}

export type InfluentInput = {
  input_id: string
  value: any
}
export type InfluentEntryRequest = {
  timestamp: string
  inputs: InfluentInput[]
}

// SYSTEMS - LOTS /////////////////////////////////////////////////////

export type Lot = {
  tenant_id: string
  system_id: string
  lot_id: string
  tag_id: string
  start_utc: string
  end_utc: string

  // GUI
  startTime: Date
  endTime: Date
  color: string
}

// SYSTEMS - TAGS /////////////////////////////////////////////////////

export type Tag = {
  tenant_id: string
  system_id: string
  subsystem_id: string
  // note: openapi docs say name, type, and units are all optional,
  // but here we assume they're required
  name: string
  type:
    | 'number'
    | 'datetime'
    | 'string'
    | 'flag'
    | 'message'
    | 'alarm'
    | 'total'
  units: string

  unitsLabel?: string
  is_performance?: boolean
  is_trending?: boolean
  min_value?: number
  max_value?: number
  low_warning?: number
  high_warning?: number
  low_error?: number
  high_error?: number
  alarm_notify?: boolean
  sort?: number
  tag_id: string
  created: string
  updated: string
  alarm_notified?: string
  color?: string
}

export const TAG_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  tag_id: yup.string().label('Tag ID').required(),
  subsystem_id: yup.string().label('Subsystem'),
  type: yup
    .string()
    .label('Type')
    .oneOf([
      'number',
      'datetime',
      'string',
      'flag',
      'message',
      'alarm',
      'total',
    ])
    .required(),
  units: yup
    .string()
    .label('Units')
    .when(['type'], {
      is: (type: string) => type === 'number' || type === 'total',
      then: (schema) => schema.required(),
    })
    .nullable(),
  unitsLabel: yup
    .string()
    .label('Units Label')
    .when(['type'], {
      is: (type: string) => type === 'number' || type === 'total',
      then: (schema) => schema.required(),
    })
    .nullable(),
  is_performance: yup.boolean().label('Performance').nullable(),
  is_trending: yup.boolean().label('Trending').nullable(),
  min_value: yup
    .number()
    .label('Min Value')
    .when('is_performance', {
      is: true,
      then: (schema) => schema.required(),
    })
    .nullable(),
  max_value: yup
    .number()
    .label('Max Value')
    .when('is_performance', {
      is: true,
      then: (schema) => schema.required(),
    })
    .nullable(),
  low_warning: yup.number().label('Low Warning Value').nullable(),
  high_warning: yup.number().label('High Warning Value').nullable(),
  low_error: yup.number().label('Low Error Value').nullable(),
  high_error: yup.number().label('High Error Value').nullable(),
})

export type TagPerformance = {
  tag: Tag
  value: number | string
  average?: number
  daily_total?: number
}

// SYSTEMS - TESTS ////////////////////////////////////////////////////

export type TagTest = {
  tag: Tag
  value: number | string
}

export type SystemTest = {
  tenant_id: string
  system_id: string
  timestamp: string
  values: TagTest[]

  // GUI - values mapped by tag ID
  value_map?: Record<string, any>
}

// SYSTEMS - TRIP REPORTS /////////////////////////////////////////////

export type TripReport = {
  tenant_id: string
  trip_report_id: string
  name: string
  date: string
  url: string
  ttl: number
  sort: number
  created: string
  updated: string
}

export const TRIP_REPORT_SCHEMA = yup.object({
  name: yup.string().label('Name').required(),
  date: yup.date().label('Date').required(),
  url: yup.string().label('URL').required(),
})

export type TripReportDownload = TripReport & FileDownload

// UI TYPES ///////////////////////////////////////////////////////////

/** A labelled form option */
export type Option = {
  label: string
  value: any
}

/** The current logged-in AWS user */
export type AwsUser = {
  username: string
  attributes: Record<string, string>
}

/** Role options for the current logged-in AWS user */
export type AwsUserRole = 'none' | 'customer' | 'premium' | 'admin'
