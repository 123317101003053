import { CircularProgress, Dialog, Grid } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import OkIcon from '@material-ui/icons/Done'

import { useAppSelector } from '../../lib/redux'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      childContainer: {
        minWidth: '200px',
        minHeight: '200px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      progressIconSvg: {
        color: theme.palette.primary.main,
      },
      okIconSvg: {
        color: theme.palette.success.main,
      },
    }),
  { name: 'BusyIndicator' }
)

/**
 * Dialog to indicate that the app is busy.
 */
const BusyIndicator: React.FC = () => {
  const classes = useStyles()
  const uiBusy = useAppSelector((state) => state.uiBusy)

  const childIcon =
    uiBusy === 'ok' ? (
      <OkIcon fontSize="large" classes={{ root: classes.okIconSvg }} />
    ) : (
      <CircularProgress classes={{ svg: classes.progressIconSvg }} />
    )

  return (
    <Dialog open={uiBusy !== 0}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.childContainer }}
      >
        {childIcon}
      </Grid>
    </Dialog>
  )
}

export default BusyIndicator
