import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import cx from 'classnames'

import { hasValue } from '../../lib/utils'

export type Fact = {
  label: string
  value: React.ReactNode
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      table: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        '&.divided': {
          // Use background color and row gap to create dividers
          backgroundColor: theme.palette.grey[300],
          gridRowGap: 1,
          '& > div': {
            background: 'white',
          },
        },
      },
      label: {
        fontWeight: 700,
        padding: theme.spacing(1),
      },
      value: {
        padding: theme.spacing(1),
      },
    }),
  { name: 'FactTable' }
)

type Props = {
  facts: Fact[]
  divided?: boolean
  className?: string
} & Omit<React.HTMLProps<HTMLDivElement>, 'className'>

/**
 * Basic table of labeled facts
 */
const FactTable: React.FC<Props> = (props: Props) => {
  const { facts, divided = false, className, ...divProps } = props
  const classes = useStyles()

  return (
    <div className={cx(classes.table, className, { divided })} {...divProps}>
      {facts.map((f) => (
        <React.Fragment key={f.label}>
          <div className={classes.label}>{f.label}</div>
          <div className={classes.value}>{formatValue(f.value)}</div>
        </React.Fragment>
      ))}
    </div>
  )
}

/** Format missings values as dashes */
const formatValue = (value: React.ReactNode): React.ReactNode => {
  if (!hasValue(value) || value === '') {
    return '\u2014'
  }
  return value
}

export default FactTable
