import { useRef } from 'react'

import { FormControl, InputLabel, Select, SelectProps } from '@material-ui/core'

type Props = {
  label: React.ReactNode
} & SelectProps

/**
 * Common Select-based value picker.
 *
 * Tweaked to work in fullscreen components
 */
const Picker: React.FC<Props> = (props: Props) => {
  const { label, disabled, children, ...selectProps } = props

  // Container for the menu so it will appear above a fullscreen parent
  const ref = useRef<HTMLDivElement>(null)

  return (
    <FormControl disabled={disabled} ref={ref}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...selectProps}
        MenuProps={{
          container: ref.current,
          // Display the options below the input, in part to avoid being clipped
          // by the site header.
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default Picker
