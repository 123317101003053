import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IconButton, Typography } from '@material-ui/core'
import GraphIcon from '@material-ui/icons/Timeline'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { formatDate } from '../../lib/dates'
import { byString } from '../../lib/sortItems'
import { MIN_CELL, NO_WRAP, renderLite } from '../../lib/tables'
import { Lot } from '../../lib/types'
import { useApp, useSystem } from '../../providers'

import { ContentContainer } from '../common'
import LoadPage from '../common/LoadPage'

// How many days of data to fetch
const DEFAULT_DAYS = 365

/**
 * History of Lots
 */
const LotHistory: React.FC = () => {
  const history = useHistory()
  const { api } = useApp()
  const { system } = useSystem()

  const [lots, setLots] = useState<Lot[] | null>(null)

  useEffect(() => {
    // Fetch the recent lots
    api
      .listSystemLots(system.tenant_id, system.system_id, {
        days: DEFAULT_DAYS,
      })
      .then((lots) => {
        if (lots) {
          // Put the most recent lots first
          lots.sort(byString('end_utc')).reverse()
          setLots(lots)
        } else {
          setLots([])
        }
      })
  }, [api, system])

  if (!lots) {
    return <LoadPage />
  }

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    viewColumns: false,
  }
  const data = lots
  const columns: MUIDataTableColumn[] = [
    {
      name: 'view',
      label: 'View',
      options: {
        sort: false,
        filter: false,
        download: false,
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
        customBodyRenderLite: renderLite(data, (lot) => (
          <IconButton onClick={() => history.push('/livedata', { lot })}>
            <GraphIcon />
          </IconButton>
        )),
      },
    },
    {
      name: 'lot_id',
      label: 'Lot',
    },
    {
      name: 'start_utc',
      label: 'Start',
      options: {
        filter: false,
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
        customBodyRenderLite: renderLite(data, (lot) =>
          formatDate(lot.start_utc)
        ),
      },
    },
    {
      name: 'end_utc',
      label: 'End',
      options: {
        filter: false,
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
        customBodyRenderLite: renderLite(data, (lot) =>
          formatDate(lot.end_utc)
        ),
      },
    },
  ]

  return (
    <ContentContainer>
      <MUIDataTable
        title={<Typography variant="h5">Lot History </Typography>}
        options={options}
        columns={columns}
        data={data}
      />
    </ContentContainer>
  )
}

export default LotHistory
