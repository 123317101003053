import React, { useEffect } from 'react'

import { Route, Switch } from 'react-router-dom'

import RestAPI from './lib/api'
import { BY_NAME, byString } from './lib/sortItems'
import {
  useAppSelector,
  useAppDispatch,
  AppDispatch,
  setTenants,
  selectSystem,
  KEY_LAST_TENANT_ID,
  KEY_LAST_SYSTEM_ID,
} from './lib/redux'
import { Tenant } from './lib/types'
import { loadLocal } from './lib/utils'

import BusyIndicator from './components/common/BusyIndicator'
import Errors from './components/common/Errors'
import LoadPage from './components/common/LoadPage'
import AppWrapper from './components/layout/AppWrapper'
import SignIn from './components/SignIn'
import { useAwsUser } from './lib/hooks'
import { useApp } from './providers'
import StandardRouter from './routers/StandardRouter'

// import './styles/App.css'

const AdminRouter = React.lazy(
  () => import('./components/admin-ui/AdminRouter')
)

const selectDefaultSystem = async (
  api: RestAPI,
  dispatch: AppDispatch,
  tenants: Tenant[] | null
) => {
  if (!tenants) {
    // Tenants have not been loaded yet - this should not happen
    return
  }

  tenants.sort(BY_NAME)

  let tenant: Tenant | null = null

  // Try to use the last selected tenant
  const tenantId = loadLocal(KEY_LAST_TENANT_ID)
  if (tenantId) {
    tenant = tenants.find((t) => t.tenant_id === tenantId) ?? null
  }
  if (!tenant) {
    // Use the first tenant in the list
    tenant = tenants[0]
  }

  let systemId = loadLocal(KEY_LAST_SYSTEM_ID)
  if (!systemId || !tenant.system_names?.[systemId]) {
    const systems = Object.entries(tenant.system_names).map(
      ([system_id, name]) => ({ system_id, name })
    )
    systems.sort(byString('name'))
    // Use the first system in the list
    systemId = systems[0].system_id
  }

  const system = await api.getSystem(tenant.tenant_id, systemId)

  // Set the global tenants for the nav bar
  dispatch(setTenants(tenants))
  dispatch(selectSystem(tenant, system))
}

/**
 * Top-level Application component
 */
const App: React.FC = () => {
  const { api } = useApp()

  const tenants = useAppSelector((state) => state.tenants)
  const dispatch = useAppDispatch()
  const awsUser = useAwsUser()

  useEffect(() => {
    if (!awsUser) {
      dispatch(setTenants([]))
      dispatch(selectSystem(null, null))
    } else {
      api.listTenants().then((tenants) => {
        return selectDefaultSystem(api, dispatch, tenants)
      })
    }
  }, [awsUser, api, dispatch])

  if (!awsUser) {
    // User needs to login
    return <SignIn />
  }

  if (!tenants) {
    // Tenants have not been loaded yet
    return <LoadPage />
  }

  return (
    <AppWrapper>
      <Errors />
      <BusyIndicator />
      <Switch>
        <Route path="/admin" component={AdminRouter} />
        <Route component={StandardRouter} />
      </Switch>
    </AppWrapper>
  )
}

export default App
