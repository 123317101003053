type NumberLike = null | undefined | string | number
/**
 * Format a decimal number in the current locale
 */
export default function formatDecimal(
  value: NumberLike,
  digits = 2,
  fallback = '-'
): string {
  if (value === null || value === undefined) {
    return fallback
  }

  const num = Number(value)
  if (isNaN(num)) {
    return fallback
  }

  return num.toLocaleString(undefined, {
    maximumFractionDigits: digits,
  })
}
