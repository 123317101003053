import { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { SubscriptionStatus } from '../../lib/types'
import { useApp, useSystem } from '../../providers'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(2),
      },
      message: {
        flex: '1 1 100%',
      },
      action: {
        display: 'block',
        flex: '0 0 auto',
      },
      subscribe: {
        margin: theme.spacing(1, 0),
      },
    }),
  { name: 'AlarmSubscription' }
)

/**
 * Alarm Subscription status and action buttons
 */
const AlarmSubscription: React.FC = () => {
  const classes = useStyles()
  const { system } = useSystem()
  const { api, asBusy } = useApp()

  // Subcription status
  const [status, setStatus] = useState<SubscriptionStatus | null>(null)

  // Is the unsubscribe confirmation dialog open?
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  // Keep checking while the subscription is pending confirmation
  useEffect(() => {
    // Callback to fetch the subscription status
    const fetchStatus = () => {
      api
        .getSubscriptionStatus(system.tenant_id, system.system_id)
        .then((status) => {
          setStatus(status)
        })
    }

    // Initialize the status
    fetchStatus()

    const timer =
      status?.status === 'PENDING_CONFIRMATION'
        ? window.setInterval(fetchStatus, 10 * 1000)
        : undefined

    return () => window.clearInterval(timer)
  }, [api, system, status?.status])

  // Callback to subscribe to alarms
  const subscribe = () => {
    asBusy(async () => {
      const status = await api.postSubscribe(system.tenant_id, system.system_id)
      setStatus(status)
    })
  }

  // Callback to open the confirm unsubscribe dialog
  const startUnsubscribe = () => setConfirmOpen(true)

  // Callback to close the confirm unsubscribe dialog
  const cancelUnsubscribe = () => setConfirmOpen(false)

  // Callback to unsubscribe to the alarms
  const unsubscribe = () => {
    setConfirmOpen(false)

    asBusy(async () => {
      const status = await api.postUnsubscribe(
        system.tenant_id,
        system.system_id
      )
      setStatus(status)
    })
  }

  if (!status || status.status === 'NO_TOPIC') {
    // This system has no topic - the user cannot subscribe
    return null
  }

  if (status.status === 'NOT_SUBSCRIBED') {
    return (
      <Button
        onClick={subscribe}
        className={classes.subscribe}
        variant="contained"
        color="primary"
      >
        Subscribe to alarms at {status.endpoint}
      </Button>
    )
  }

  return (
    <Paper className={classes.paper}>
      {status.status === 'SUBSCRIBED' && (
        <>
          <p className={classes.message}>
            Subscribed to alarms at <b>{status.endpoint}</b>.
          </p>
          <Button
            onClick={startUnsubscribe}
            className={classes.action}
            variant="outlined"
            size="small"
          >
            Unsubscribe
          </Button>
        </>
      )}
      {status.status === 'PENDING_CONFIRMATION' && (
        <>
          <p className={classes.message}>
            Subscribed to alarms at <b>{status.endpoint}</b>, but waiting for
            confirmation.
          </p>
          <Button
            onClick={subscribe}
            className={classes.action}
            variant="outlined"
            color="primary"
            size="small"
          >
            Resend confirmation
          </Button>
        </>
      )}

      {/* Unsubscribe confirmation dialog */}
      <Dialog open={confirmOpen} onClose={cancelUnsubscribe}>
        <DialogTitle>Unsubscribe</DialogTitle>
        <DialogContent>
          Are you sure you want to unsubscribe from alarm notifications for{' '}
          {system.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelUnsubscribe} autoFocus>
            Cancel
          </Button>
          <Button onClick={unsubscribe} color="primary" variant="contained">
            Unsubscribe
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default AlarmSubscription
