import { MenuItem, SelectProps } from '@material-ui/core'

import Picker from '../common/Picker'
import { Resolution } from './shared'

type Props = {
  resolution: Resolution
  onChange: (resolution: Resolution) => void
} & Omit<SelectProps, 'onChange'>

const ResolutionPicker: React.FC<Props> = (props: Props) => {
  const { resolution, onChange, ...selectProps } = props

  return (
    <Picker
      label="Resolution"
      value={resolution}
      onChange={(event) => onChange(event.target.value as Resolution)}
      {...selectProps}
    >
      <MenuItem value="auto">Automatic</MenuItem>
      <MenuItem value="raw">Raw</MenuItem>
      <MenuItem value="1M">1 minute</MenuItem>
      <MenuItem value="5M">5 minutes</MenuItem>
      <MenuItem value="15M">15 minutes</MenuItem>
      <MenuItem value="1H">1 hour</MenuItem>
    </Picker>
  )
}

export default ResolutionPicker
