import { ListItemIcon, MenuItem, SelectProps } from '@material-ui/core'
import CircleIcon from '@material-ui/icons/Brightness1'

import { Lot, System } from '../../lib/types'
import Picker from '../common/Picker'

type Props = {
  system: System
  lots: Lot[] | null
  selected: string
  onChange: (lotId: string) => void
} & Omit<SelectProps, 'onChange'>

const LotPicker: React.FC<Props> = (props: Props) => {
  const { system, lots, selected, onChange, ...selectProps } = props

  if (!system.show_lots) {
    // This system does not do Lots
    return null
  }

  if (!lots || lots.length === 0) {
    // Nothing to select
    return null
  }

  return (
    <Picker
      label="Lot"
      value={selected}
      onChange={(event) => onChange(event.target.value as string)}
      {...selectProps}
    >
      <MenuItem value="None">None</MenuItem>
      {lots.map((lot) => (
        <MenuItem key={lot.lot_id} value={lot.lot_id}>
          <ListItemIcon style={{ minWidth: 32 }}>
            <CircleIcon htmlColor={lot.color} fontSize="small" />
          </ListItemIcon>
          {lot.lot_id}
        </MenuItem>
      ))}
    </Picker>
  )
}

export default LotPicker
