import { VictoryAxis, VictoryAxisProps } from 'victory'

import { Y_AXIS_WIDTH } from './shared'

const DEFAULT_COLOR = '#666666'

type Props = {
  color?: string
} & VictoryAxisProps

/**
 * Standard Y Axis showing a few value ticks
 */
export const YAxis: React.FC<Props> = (props: Props) => {
  const { color = DEFAULT_COLOR, ...victoryAxisProps } = props

  return (
    <VictoryAxis
      tickCount={3}
      offsetX={Y_AXIS_WIDTH}
      {...victoryAxisProps}
      dependentAxis
      crossAxis={false}
      style={{
        axis: {
          stroke: 'none',
        },
        axisLabel: {
          padding: 50,
          fontFamily: 'Roboto',
          fill: color,
        },
        grid: {
          stroke: '#EEEEEE',
        },
        ticks: {
          stroke: '#EEEEEE',
          size: 5,
        },
        tickLabels: {
          fill: color,
        },
      }}
    />
  )
}

export default YAxis
