import { BY_SORT_VALUE } from '../../../lib/sortItems'
import { useSystem } from '../../../providers'

import { ContentContainer } from '../../common'
import SubsystemSection from './SubsystemSection'

const MembraneView: React.FC = () => {
  const { system } = useSystem()

  const subsystems = system.subsystems?.sort(BY_SORT_VALUE) ?? []

  return (
    <ContentContainer>
      {subsystems.length === 0 && <p>No subsystems</p>}
      {subsystems.map((ss) => (
        <SubsystemSection
          key={ss.subsystem_id}
          system={system}
          subsystem={ss}
        />
      ))}
    </ContentContainer>
  )
}

export default MembraneView
