import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Link, Tooltip } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'

import { useIsAdmin, useIsAdminPath } from '../../lib/hooks'

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      link: {
        whiteSpace: 'nowrap',
        fontWeight: 800,
      },
    }),
  { name: 'AdminToggle' }
)

/**
 * Navigation component to switch between Standard and Admin modes
 */
const AdminToggle: React.FC = () => {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const isAdminPath = useIsAdminPath()

  if (!isAdmin) {
    return null
  }

  if (isAdminPath) {
    return (
      <Link
        component={RouterLink}
        className={classes.link}
        color="inherit"
        to="/"
      >
        Standard View
      </Link>
    )
  } else {
    return (
      <Tooltip title="Customer Administration">
        <Link
          component={RouterLink}
          className={classes.link}
          color="inherit"
          to="/admin"
        >
          <SettingsIcon />
        </Link>
      </Tooltip>
    )
  }
}

export default AdminToggle
