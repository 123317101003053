import { useEffect, useState } from 'react'

import { Button, ButtonGroup } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { VictoryChart, VictoryBrushContainer } from 'victory'

import { useDimensions } from '../../lib/hooks'
import { NullComponent, X_AXIS_HEIGHT } from './shared'
import XAxis from './XAxis'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(2),
      },
      brush: {
        flex: '1 1 100%',
        overflow: 'hidden',
      },
      button: {
        flex: '0 0 auto',
      },
    }),
  { name: 'TimeBrush' }
)

type BrushDomain = {
  x: [Date, Date]
  y: [number, number]
}

type Props = {
  startTime: Date
  endTime: Date
  onBrushUpdate: (brushStart: Date, brushEnd: Date, apply: boolean) => void
}

/**
 * Chart control that allows zooming and panning
 */
const TimeBrush: React.FC<Props> = (props: Props) => {
  const { startTime, endTime, onBrushUpdate } = props

  const classes = useStyles()

  // Measure the width of the wrapper to size the chart
  const [refDimensions, dimensions] = useDimensions()

  const [brushDomain, setBrushDomain] = useState<BrushDomain>()

  // Initialize the brush domain
  useEffect(() => {
    setBrushDomain({ x: [startTime, endTime], y: [0, 1] })
  }, [startTime, endTime])

  const onUpdate = (domain: BrushDomain) => {
    // Update the local brush
    setBrushDomain(domain)
    // Update the parent brush
    onBrushUpdate(domain.x[0], domain.x[1], false)
  }

  // Is the brushDomain different than the chart domain?
  const canApply =
    brushDomain &&
    brushDomain?.x[0].valueOf() !== startTime.valueOf() &&
    brushDomain?.x[1].valueOf() !== endTime.valueOf()

  const onApply = () => {
    if (brushDomain) {
      // Use the brush domain as the chart domain
      onBrushUpdate(brushDomain.x[0], brushDomain.x[1], true)
    }
  }

  const onReset = () => {
    // Reset the local brush
    // The Math.random() is to force a redraw
    // https://github.com/FormidableLabs/victory/issues/1541
    setBrushDomain({ x: [startTime, endTime], y: [0, 1 + Math.random()] })
    // Reset the parent brush
    onBrushUpdate(startTime, endTime, false)
  }

  return (
    <div className={classes.container}>
      <div>Zoom:</div>
      <div ref={refDimensions} className={classes.brush}>
        {dimensions && (
          <VictoryChart
            width={dimensions.width}
            height={X_AXIS_HEIGHT}
            scale={{ x: 'time' }}
            domain={{
              x: [startTime, endTime],
            }}
            padding={{
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}
            containerComponent={
              <VictoryBrushContainer
                responsive={false}
                brushDimension="x"
                brushDomain={brushDomain}
                onBrushDomainChangeEnd={onUpdate as any}
              />
            }
            prependDefaultAxes={true}
            defaultAxes={{
              independent: <XAxis />,
              dependent: <NullComponent />,
            }}
          />
        )}
      </div>
      <ButtonGroup size="small" variant="outlined" className={classes.button}>
        <Button onClick={onApply} disabled={!canApply}>
          Apply
        </Button>
        <Button onClick={onReset}>Reset</Button>
      </ButtonGroup>
    </div>
  )
}

export default TimeBrush
