import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { System, Tag } from '../../lib/types'
import { withSystem, WithSystemProps } from '../../providers'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tagList: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 2),
      },
      button: {
        margin: theme.spacing(0.5, 0),
        padding: theme.spacing(0.5),
      },
    }),
  { name: 'AllTags' }
)

type Props = {
  updateSelectedTags: (selected: Tag[]) => void
  selectedTags: Tag[]
  availTags: Tag[]
} & WithSystemProps

type TagGroup = {
  subsystem_id: string
  subsystem_name: string
  tags: Tag[]
}

/**
 * Tag selection of Live Data charst
 */
const AllTags = (props: Props) => {
  const { system, selectedTags, availTags } = props

  const classes = useStyles()

  // Skip the Timestamp pseudo-tag
  const allTags = availTags.filter((t) => t.type !== 'datetime')

  const tagGroups = groupTagsBySubsystem(system, allTags)

  // Which tags are selected
  const selected_ids = selectedTags.map((t) => t.tag_id)

  /** Toggle the selection state of a tag */
  const toggleTag = (tag: Tag, selected: boolean) => {
    const { selectedTags, updateSelectedTags } = props

    if (selected) {
      // Remove the tag
      updateSelectedTags(selectedTags.filter((t) => t.tag_id !== tag.tag_id))
    } else {
      // Add the tag
      updateSelectedTags([...selectedTags, tag])
    }
  }

  const makeButton = (tag: Tag) => {
    const is_selected = selected_ids.includes(tag.tag_id)
    return (
      <Button
        key={tag.tag_id}
        variant="contained"
        size="small"
        fullWidth
        color={is_selected ? 'primary' : 'default'}
        className={classes.button}
        onClick={() => toggleTag(tag, is_selected)}
      >
        {tag.name} ({tag.units})
      </Button>
    )
  }

  if (tagGroups.length === 1) {
    return (
      <div className={classes.tagList}>
        {tagGroups[0].tags.map((t) => makeButton(t))}
      </div>
    )
  }

  return (
    <>
      {tagGroups.map((tagGroup) => (
        <Accordion key={tagGroup.subsystem_id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {tagGroup.subsystem_name}
          </AccordionSummary>
          <AccordionDetails className={classes.tagList}>
            {tagGroup.tags.map((t) => makeButton(t))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

const groupTagsBySubsystem = (system: System, allTags: Tag[]): TagGroup[] => {
  const groups: TagGroup[] = []

  allTags.forEach((tag) => {
    const subsystem_id = tag.subsystem_id ?? ''
    const group = groups.find((g) => g.subsystem_id === subsystem_id)
    if (group) {
      group.tags.push(tag)
    } else {
      const subsystem_name =
        system.subsystems?.find((sub) => sub.subsystem_id === subsystem_id)
          ?.name ?? ''
      groups.push({
        subsystem_id,
        subsystem_name,
        tags: [tag],
      })
    }
  })

  return groups
}

export default withSystem(AllTags)
