import { useState } from 'react'

import { FormControlLabel, Switch, Typography } from '@material-ui/core'

import { useIsSmallScreen } from '../../lib/hooks'
import { Lot } from '../../lib/types'
import { loadLocal, saveLocal } from '../../lib/utils'
import { ChartableTag, ChartView } from './shared'
import AllTogetherChart from './AllTogetherChart'
import PerTagChart from './PerTagChart'
import PerUnitChart, { groupTagsByUnit } from './PerUnitChart'
import { LoadPage } from '../common'

type Props = {
  rows?: any[][]
  lots?: Lot[] | null
  tags: ChartableTag[]
  title: string
  startTime: Date
  endTime: Date
  updateTimeRange?: (start: Date, end: Date) => void
  chartView: ChartView
  showDemo?: boolean
  showDots?: boolean
  localPrefix: string
  maxAxes?: number
}

const useDemoFlag = (prefix: string, key: string, defaultValue: boolean) =>
  useState<boolean>(() => loadLocal(`${prefix}.${key}`, defaultValue))

const setDemoFlag =
  (prefix: string, key: string, callback: (value: boolean) => any) =>
  (_: any, checked: boolean) => {
    saveLocal(`${prefix}.${key}`, checked)
    callback(checked)
  }

/**
 * Common container for the chart types
 */
const CommonChart: React.FC<Props> = (props: Props) => {
  const {
    rows,
    tags,
    chartView,
    startTime,
    endTime,
    lots,
    localPrefix,
    maxAxes,
    showDemo = false,
    showDots: _showDots = false,
  } = props

  const isSmallScreen = useIsSmallScreen()

  const [syncTooltips, setSyncTooltips] = useDemoFlag(
    localPrefix,
    'syncTooltips',
    false
  )
  const [showDots, setShowDots] = useDemoFlag(
    localPrefix,
    'showDots',
    _showDots
  )
  const [forceMinMax, setForceMinMax] = useDemoFlag(
    localPrefix,
    'forceMinMax',
    true
  )

  if (!tags || !rows) {
    return <LoadPage />
  }

  const demoControls = showDemo ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Typography style={{ marginRight: '16px' }}>Demo:</Typography>

      <FormControlLabel
        control={
          <Switch
            checked={showDots}
            onChange={setDemoFlag(localPrefix, 'showDots', setShowDots)}
          />
        }
        label="Show dots"
      />
      <FormControlLabel
        control={
          <Switch
            checked={forceMinMax}
            onChange={setDemoFlag(localPrefix, 'forceMinMax', setForceMinMax)}
          />
        }
        label="Tag min/max"
        title="Limit displayed values to a tag's configured min/max range"
      />

      {chartView !== ChartView.AllTogether && (
        <FormControlLabel
          control={
            <Switch
              checked={syncTooltips}
              onChange={setDemoFlag(
                localPrefix,
                'syncTooltips',
                setSyncTooltips
              )}
            />
          }
          label="Sync Tooltips"
        />
      )}
    </div>
  ) : null

  if (isSmallScreen || chartView === ChartView.PerUnit) {
    const unitCharts = groupTagsByUnit(tags)
    return (
      <div>
        {demoControls}
        {unitCharts.map((unitChart) => (
          <PerUnitChart
            key={`unitChart-${unitChart.unitKey}`}
            startTime={startTime}
            endTime={endTime}
            rows={rows}
            tags={tags}
            unitChart={unitChart}
            syncTooltips={syncTooltips}
            showDots={showDots}
            forceMinMax={forceMinMax}
          />
        ))}
      </div>
    )
  }

  if (chartView === ChartView.PerTag) {
    return (
      <div style={{ position: 'relative' }}>
        {demoControls}
        {tags.slice(1).map((tag, i) => (
          <PerTagChart
            key={`tagChart-${tag.name}`}
            startTime={startTime}
            endTime={endTime}
            rows={rows}
            tags={tags}
            tagIndex={i + 1}
            syncTooltips={syncTooltips}
            showDots={showDots}
            forceMinMax={forceMinMax}
          />
        ))}
      </div>
    )
  }

  if (chartView === ChartView.AllTogether) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <div style={{ flex: '0 1 auto' }}>{demoControls}</div>
        <div
          style={{
            flex: '1 1 100%',
            overflow: 'hidden',
          }}
        >
          <AllTogetherChart
            startTime={startTime}
            endTime={endTime}
            rows={rows}
            tags={tags}
            lots={lots ?? null}
            showDots={showDots}
            forceMinMax={forceMinMax}
            localPrefix={localPrefix}
            maxAxes={maxAxes}
          />
        </div>
      </div>
    )
  }

  return null
}

export default CommonChart
