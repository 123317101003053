import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import sortItems from '../../lib/sortItems'
import { Alarm, SubsystemPerformance } from '../../lib/types'
import { useApp, useSystem } from '../../providers'
import { ContentContainer, LoadPage } from '../common'

import SystemAlarms from './SystemAlarms'
import Subsystem from './Subsystem'
import SystemInfo from './SystemInfo'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      noResultsStyle: {
        fontStyle: 'italic',
        marginTop: theme.spacing(2),
      },
      systemImage: {
        height: '100%',
        objectFit: 'contain',
        border: `solid 2px ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      systemInfoRoot: {
        height: '200px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      systemInfoAttribs: {
        height: '100%',
        maxHeight: '100%',
      },
      systemInfoAttrib: {
        margin: theme.spacing(1),
      },
    }),
  { name: 'SystemOverview' }
)

// How often to refresh the Performance and Status data
const REFRESH_MS = 10 * 1000

const SystemOverview: React.FC = () => {
  const [subsystems, setSubsystems] = useState<SubsystemPerformance[] | null>(
    null
  )
  const [otherAlarms, setOtherAlarms] = useState<Alarm[] | null>(null)
  const classes = useStyles()

  const { api } = useApp()
  const { system } = useSystem()

  useEffect(() => {
    const loadData = async () => {
      const [performance, status] = await Promise.all([
        api.getSystemPerformance(system.tenant_id, system.system_id),
        api.getSystemStatus(system.tenant_id, system.system_id),
      ])

      if (!performance || !status) {
        // Something failed
        return
      }

      performance.subsystems.forEach((subperf) => {
        // Find the matching status
        const substatus = status.subsystems?.find(
          (ss) => ss.subsystem_id === subperf.subsystem_id
        )

        if (substatus) {
          // Merge in the status properties
          subperf.alarmLevel = substatus.alarmLevel
          subperf.alarms = substatus.alarms
          subperf.sort = substatus.sort
        }
      })

      // Sort the subsystems
      sortItems(performance.subsystems)

      setSubsystems(performance.subsystems)
      setOtherAlarms(status.otherAlarms ?? null)
    }

    // Do the first load
    loadData()

    // Periodically reload
    const intervalId = window.setInterval(loadData, REFRESH_MS)

    // Stop refreshing when unmounted
    return () => clearInterval(intervalId)
  }, [api, system])

  return (
    <ContentContainer>
      <SystemAlarms alarms={otherAlarms} />
      <SystemInfo system={system} />
      {!subsystems && <LoadPage />}
      {subsystems?.length === 0 && (
        <Typography variant="h5" classes={{ root: classes.noResultsStyle }}>
          No Subsystems
        </Typography>
      )}
      {subsystems?.map((subsystem) => (
        <Subsystem key={subsystem.subsystem_id} subsystem={subsystem} />
      ))}
    </ContentContainer>
  )
}

export default SystemOverview
