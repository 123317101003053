import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { AppBar, Link, Typography, Tooltip, Hidden } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import LogOutIcon from '@material-ui/icons/ExitToApp'
import ReportIcon from '@material-ui/icons/Report'
import DevIcon from '@material-ui/icons/Build'

import Auth from '@aws-amplify/auth'

import SystemSelector from './SystemSelector'
import AdminToggle from './AdminToggle'

import logo from '../../images/logo-white.svg'
import CompactNav from './CompactNav'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        whiteSpace: 'nowrap',
      },
      bar: {
        backgroundColor: 'black',
        color: 'white',
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1, 4),
        alignItems: 'center',
      },
      systemSelect: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
        marginRight: 'auto',
      },
      actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
      },
      link: {
        whiteSpace: 'nowrap',
        fontWeight: 800,
      },
    }),
  { name: 'AppNav' }
)

/**
 * Common App navigation used in both Standard and Admin mode
 */
const AppNav: React.FC = () => {
  const classes = useStyles()

  const stage = process.env.REACT_APP_STAGE

  return (
    <AppBar
      id="appNavBar"
      className={classes.bar}
      position="static"
      elevation={1}
    >
      <img id="logo" height="35px" src={logo} alt="SepTrac Logo" />
      <Typography color="inherit" className={classes.title} variant="h5">
        SepTrac
        {stage !== 'prod' && (
          <DevIcon fontSize="small" style={{ marginLeft: 8 }} />
        )}
      </Typography>
      <div className={classes.systemSelect}>
        <Hidden xsDown>
          <SystemSelector />
        </Hidden>
      </div>
      <div className={classes.actions}>
        <Hidden xsDown>
          <AdminToggle />
          <Tooltip title="Report a Problem">
            <Link
              component={RouterLink}
              className={classes.link}
              color="inherit"
              to="/problem"
            >
              <ReportIcon />
            </Link>
          </Tooltip>
          <Tooltip title="Contact Us">
            <Link
              component={RouterLink}
              className={classes.link}
              color="inherit"
              to="/contact"
            >
              <HelpIcon />
            </Link>
          </Tooltip>
          <Tooltip title="Log Out">
            <Link
              component={RouterLink}
              className={classes.link}
              color="inherit"
              to="/"
              onClick={(event) => {
                event.preventDefault()
                Auth.signOut()
              }}
            >
              <LogOutIcon />
            </Link>
          </Tooltip>
        </Hidden>
        <Hidden smUp>
          <CompactNav />
        </Hidden>
      </div>
    </AppBar>
  )
}

export default AppNav
