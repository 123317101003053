import {
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'

import formatDecimal from '../../lib/formatDecimal'
import { TagCardProps } from './shared'

/**
 * Render the tag value as a Daily Total
 */
const TagTotal: React.FC<TagCardProps> = (props: TagCardProps) => {
  const { perfTag } = props
  const { tag, daily_total } = perfTag

  return (
    <>
      <CardHeader
        title="Daily Performance"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <Typography variant="h6">
          {formatDecimal(daily_total, 3)} {tag.units}
        </Typography>
        <Typography>
          Treated Today
          <br />
        </Typography>
      </CardContent>
      <CardActions>{tag.name}</CardActions>
    </>
  )
}

export default TagTotal
