import React, { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import DownloadIcon from '@material-ui/icons/GetApp'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { parseDate, inDateRange, formatDate } from '../lib/dates'
import { TripReport } from '../lib/types'
import { renderLite, MIN_CELL, BARE_TABLE } from '../lib/tables'
import { useApp, useSystem } from '../providers'
import { byString } from '../lib/sortItems'
import { U } from '../lib/utils'

import { ContentContainer, FactTable } from './common'
import RestAPI from '../lib/api'

const contactEmail = 'getinfo@kochsep.com'
const assistEmail = 'techsupport@kochind.com'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      action: {
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      actions: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
      },
    }),
  { name: 'Assist' }
)

const Assist: React.FC = () => {
  const { api } = useApp()
  const { tenant } = useSystem()

  const classes = useStyles()

  const [tripReports, setTripReports] = useState<TripReport[] | null>(null)
  const [showTripReports, setShowTripReports] = useState<boolean>(false)

  // Fetch the trip reports
  useEffect(() => {
    api.listTripReports(tenant.tenant_id).then((tripReports) => {
      if (tripReports) {
        tripReports.sort(byString('date'))
        tripReports.reverse()
        setTripReports(tripReports)
      }
    })
  }, [api, tenant])

  const {
    assistStartDate,
    assistEndDate,
    totalVisits,
    remainingVisits,
    techName,
  } = tenant

  const startDate = parseDate(assistStartDate)
  const endDate = parseDate(assistEndDate)

  const status = inDateRange(startDate, endDate) ? 'Active' : 'Inactive'

  // Get the date of the most recent trip report
  let lastVisit = null
  if (tripReports && tripReports.length > 0) {
    lastVisit = parseDate(tripReports[0].date)
  }

  return (
    <ContentContainer title="KSS Assist">
      <FactTable
        divided={true}
        facts={[
          { label: 'Status', value: status },
          { label: 'Start Date', value: formatDate(startDate, false) },
          { label: 'End Date', value: formatDate(endDate, false) },
          { label: 'KSS Site Visits Included', value: totalVisits },
          { label: 'KSS Site Visits Remaining', value: remainingVisits },
          { label: 'Last KSS Visit Date', value: formatDate(lastVisit, false) },
          { label: 'KSS Service Tech', value: techName },
        ]}
      />

      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.action }}
          onClick={() => {
            openEmail(contactEmail, 'SepTrac Customer Request: Contact KSS')
          }}
        >
          Contact KSS
        </Button>
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.action }}
          onClick={() => {
            openEmail(
              assistEmail,
              `SepTrac Customer Request: KSS Assist Vist for ${tenant.name}`,
              `Hello,\n\nPlease contact me to schedule an on-site visit for ${tenant.name}. The reason for the visit are:\n\n{Place details here}`
            )
          }}
        >
          Request KSS Assist Visit
        </Button>
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.action }}
          onClick={() => {
            openEmail(
              assistEmail,
              `SepTrac Customer Request: KSS Assist Renewal for ${tenant.name}`,
              `Hello,\n\nPlease contact me about renewing the KSS Assist contract for ${tenant.name}.`
            )
          }}
        >
          Request KSS Assist Renewal
        </Button>
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.action }}
          onClick={() => setShowTripReports(true)}
        >
          See Trip Reports
        </Button>
      </div>
      <TripReportsDialog
        open={showTripReports}
        onClose={() => setShowTripReports(false)}
        api={api}
        tripReports={tripReports}
      />
    </ContentContainer>
  )
}

const openEmail = (email: string, subject: string, body?: string) => {
  const url = `mailto:${email}?subject=${U(subject)}&body=${U(body ?? '')}`
  window.open(url, '_blank')
}

const openTripReport = async (api: RestAPI, tripReport: TripReport) => {
  const url = await api.getTripReportUrl(
    tripReport.tenant_id,
    tripReport.trip_report_id
  )
  if (url) {
    window.open(url.url, '_blank')
  }
}

type TripReportsDialogProps = {
  open: boolean
  onClose: () => unknown
  api: RestAPI
  tripReports: TripReport[] | null
}
const TripReportsDialog = (props: TripReportsDialogProps) => {
  const { open, onClose, api, tripReports } = props

  if (!open || !tripReports) {
    return null
  }

  const options: MUIDataTableOptions = {
    ...BARE_TABLE,
    pagination: tripReports.length > 10,
  }

  const columns: MUIDataTableColumn[] = [
    {
      name: 'download',
      label: 'Download',
      options: {
        sort: false,
        setCellHeaderProps: MIN_CELL,
        customHeadLabelRender: () => null,
        customBodyRenderLite: renderLite(tripReports, (tripReport) => (
          <Tooltip title="Download">
            <IconButton onClick={() => openTripReport(api, tripReport)}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )),
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        setCellHeaderProps: MIN_CELL,
        customBodyRenderLite: renderLite(tripReports, (tripReport) =>
          formatDate(tripReport.date, false)
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
    },
  ]

  return (
    <Dialog open={true} scroll="paper" fullWidth onClose={onClose}>
      <DialogTitle>Trip Reports</DialogTitle>
      <DialogContent>
        <MUIDataTable
          title={null}
          options={options}
          columns={columns}
          data={tripReports}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Assist
