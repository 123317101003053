import { Redirect, Route, Switch } from 'react-router-dom'

import { useAppSelector } from '../lib/redux'
import SystemProvider from '../providers/SystemProvider'

import LoadPage from '../components/common/LoadPage'
import StandardWrapper from '../components/layout/StandardWrapper'

import AlarmHistory from '../components/hmi/AlarmHistory'
import InfluentEntry from '../components/influent/InfluentEntry'
import InfluentChart from '../components/influent/InfluentChart'
import InfluentLogs from '../components/influent/InfluentLogs'
import LotHistory from '../components/hmi/LotHistory'
import SystemOverview from '../components/overview/SystemOverview'
import HmiView from '../components/hmi/HmiView'
import OrderParts from '../components/parts/OrderParts'
import Documents from '../components/Documents'
import Membrane from '../components/parts/membrane/Membrane'
import SystemTestHistory from '../components/hmi/SystemTestHistory'
import LiveDataView from '../components/livedata/LiveDataView'
import Assist from '../components/Assist'
import Contact from '../components/Contact'
import ReportProblem from '../components/ReportProblem'
import OrderHistory from '../components/OrderHistory'

/**
 * Router for pages that require a valid System
 */
const StandardRouter: React.FC = () => {
  const tenant = useAppSelector((state) => state.tenant)
  const system = useAppSelector((state) => state.system)

  // The system has not been selected or is being changed
  if (!tenant || !system) {
    return <LoadPage />
  }

  return (
    <SystemProvider tenant={tenant} system={system}>
      <StandardWrapper>
        <Switch>
          <Route exact path="/livedata" component={LiveDataView} />
          <Route exact path="/documents" component={Documents} />
          <Route exact path="/hmi/alarms" component={AlarmHistory} />
          <Route exact path="/hmi/lots" component={LotHistory} />
          <Route exact path="/hmi/tests" component={SystemTestHistory} />
          <Route exact path="/hmi/view/:hmi_name" component={HmiView} />
          <Route exact path="/influent/enter" component={InfluentEntry} />
          <Route exact path="/influent/chart" component={InfluentChart} />
          <Route exact path="/influent/logs" component={InfluentLogs} />
          <Route exact path="/assist" component={Assist} />
          <Route exact path="/parts/order" component={OrderParts} />
          <Route exact path="/parts/membrane" component={Membrane} />
          <Route exact path="/order_history" component={OrderHistory} />
          <Route exact path="/problem" component={ReportProblem} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/" component={SystemOverview} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </StandardWrapper>
    </SystemProvider>
  )
}

export default StandardRouter
