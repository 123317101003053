import { VictoryAxis, VictoryAxisProps } from 'victory'

import { NullComponent, Y_AXIS_LABELED_WIDTH } from './shared'

/**
 * Common Y Grid lines
 */
export const YGrid: React.FC<VictoryAxisProps> = (props: VictoryAxisProps) => {
  return (
    <VictoryAxis
      tickCount={5}
      offsetX={4 * Y_AXIS_LABELED_WIDTH}
      {...props}
      dependentAxis
      crossAxis={false}
      axisComponent={<NullComponent />}
      axisLabelComponent={<NullComponent />}
      tickLabelComponent={<NullComponent />}
      tickComponent={<NullComponent />}
      style={{
        grid: {
          stroke: '#EEEEEE',
          strokeWidth: 1,
        },
        ticks: {
          stroke: 'none',
        },
        tickLabels: {
          fill: 'none',
        },
      }}
    />
  )
}

export default YGrid
