import { useEffect, useReducer } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogProps,
  IconButton,
} from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import { sub, Duration } from 'date-fns'
import { VictoryChart } from 'victory'

import formatUTC from '../../lib/formatUTC'
import { useDimensions, useIsSmallScreen } from '../../lib/hooks'
import { Tag, TrendingData } from '../../lib/types'
import { useApp, useSystem } from '../../providers'

import { LoadPage } from '../common'
import TagLine from '../charts/TagLine'
import { ChartableTag, CHART_PADDING, findMinMax } from '../charts/shared'
import XAxis from '../charts/XAxis'
import YAxis from '../charts/YAxis'
import Tooltip from '../charts/Tooltip'
import TagLegend from '../charts/TagLegend'
import { hasValue } from '../../lib/utils'

/** How much data to show in chart */
const RECENT_DURATION: Duration = {
  hours: 6,
}

/** Index of tag in row data */
const TAG_INDEX = 1
const TAG_INDEXES = [TAG_INDEX]

const CHART_HEIGHT = 300

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      chartWrapper: {
        width: '100%',
        // height: CHART_HEIGHT,
      },
    }),
  { name: 'HmiRender' }
)

type State = {
  data: TrendingData | null
  startTime: Date
  endTime: Date
  minValue: number
  maxValue: number
}

type Action =
  | {
      type: 'setData'
      data: TrendingData | null
      startTime: Date
      endTime: Date
      minValue: number
      maxValue: number
    }
  | { type: 'clearData' }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setData':
      return { ...state, ...action }
    case 'clearData':
      return { ...state, data: null }
    default:
      return state
  }
}

const makeInitialState = (): State => {
  const { startTime, endTime } = makeTimeRange()
  return {
    data: null,
    startTime,
    endTime,
    minValue: 0,
    maxValue: 100,
  }
}

type Props = {
  tag: Tag | null
  onClose: () => void
} & Omit<DialogProps, 'open' | 'onClose'>

const ActiveTagChart: React.FC<Props> = (props: Props) => {
  const { tag, onClose, ...dialogProps } = props
  const { api } = useApp()
  const { system } = useSystem()
  const isSmallScreen = useIsSmallScreen()
  const theme = useTheme()

  const [{ data, startTime, endTime, minValue, maxValue }, dispatch] =
    useReducer(reducer, {} as State, makeInitialState)

  // Measure the width of the wrapper to size the chart
  const [refDimensions, dimensions] = useDimensions()

  const classes = useStyles()

  useEffect(() => {
    if (!tag) {
      dispatch({ type: 'clearData' })
      return
    }

    const { startTime, endTime } = makeTimeRange()

    const args = {
      start_utc: formatUTC(startTime),
      end_utc: formatUTC(endTime),
      tags: [tag.tag_id],
    }

    api
      .fetchSystemTrending(system.tenant_id, system.system_id, args)
      .then((data) => {
        if (data) {
          data.rows.forEach((row: any[]) => {
            row[0] = new Date(row[0])
            row[1] = hasValue(row[1]) ? parseFloat(row[1]) : null
          })

          if (!data.tags[1].color) {
            // No color configured - fallback to the UI primary
            data.tags[1].color = theme.palette.primary.main
          }

          const minMax = findMinMax(
            data.rows,
            tag as ChartableTag,
            TAG_INDEX,
            true
          )
          dispatch({
            type: 'setData',
            data: data,
            startTime,
            endTime,
            minValue: minMax.min,
            maxValue: minMax.max,
          })
        } else {
          dispatch({ type: 'clearData' })
        }
      })
  }, [api, system, tag])

  if (!open || !tag) {
    return null
  }

  return (
    <Dialog
      open={!!tag}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      {...dialogProps}
    >
      <Card>
        {!isSmallScreen && (
          <CardHeader
            title={`${tag.name} (${tag.units})`}
            action={
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
          />
        )}
        <CardContent>
          <div ref={refDimensions} className={classes.chartWrapper}>
            {!data && <LoadPage />}
            {data && (
              <>
                <VictoryChart
                  width={dimensions?.width ?? 800}
                  height={CHART_HEIGHT}
                  domain={{
                    x: [startTime, endTime],
                    y: [minValue, maxValue],
                  }}
                  singleQuadrantDomainPadding={{ y: false }}
                  padding={CHART_PADDING}
                  scale={{ x: 'time', y: 'linear' }}
                  prependDefaultAxes={true}
                  defaultAxes={{
                    dependent: <XAxis />,
                    independent: <YAxis />,
                  }}
                >
                  <TagLine
                    rows={data.rows}
                    tagIndex={TAG_INDEX}
                    tags={data.tags as ChartableTag[]}
                  />
                  <Tooltip
                    rows={data.rows}
                    tags={data.tags as ChartableTag[]}
                    tagIndexes={TAG_INDEXES}
                  />
                </VictoryChart>
                {isSmallScreen && (
                  <TagLegend
                    tags={data.tags as ChartableTag[]}
                    tagIndexes={TAG_INDEXES}
                    orientation="horizontal"
                  />
                )}
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </Dialog>
  )
}

const makeTimeRange = () => {
  const endTime = new Date()
  const startTime = sub(endTime, RECENT_DURATION)

  return {
    startTime,
    endTime,
  }
}

export default ActiveTagChart
