import React, { useState } from 'react'

import { Auth } from '@aws-amplify/auth'
import { API } from '@aws-amplify/api'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useApp } from '../../providers'
import QrReader from '../../thirdparty/react-qr-reader'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      scanData: {
        color: theme.palette.success.main,
        overflow: 'auto',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
      },
    }),
  { name: 'BarcodeReader' }
)

export type BarcodeScan = {
  text: string
  image: any
  data?: any
}

const defaultProps = {
  open: false,
  dialogStyle: {
    width: '300px',
  },
  title: 'Scan',
  // Handler for the Okay button
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDone: (_scan: BarcodeScan): void => {},
  // Handler for the Cancel button
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCancel: (): void => {},

  // Callback to parse the scanned text
  parseScan: (text: string): any => text,
  // Callback to format the parsed scan data for the user
  formatScan: (data: any): React.ReactNode => data,

  // Delay (in ms) between barcode API calls
  delay: 500,
}

type Props = typeof defaultProps

/**
 * Dialog to scan a barcode
 */
const BarcodeReader: React.FC<Props> = (props: Props) => {
  const {
    open,
    dialogStyle,
    title,
    onDone,
    onCancel,
    parseScan,
    formatScan,
    delay,
  } = props
  const { addError } = useApp()

  const [scan, setScan] = useState<any>(null)

  const classes = useStyles()

  const handleCancel = () => {
    onCancel()
    setScan(null)
  }

  /*
  const handleDone = () => {
    if (scan !== null) {
      onDone(scan);
    }
    setScan(null);
  }
  */

  const onScan = (scan: BarcodeScan | null) => {
    if (scan === null || scan.text === null) {
      // Just in case... nothing to do
      return
    }

    // Is this the data we were expecting?
    const data = parseScan(scan.text)
    if (data === null) {
      // Nope...
      return
    }

    scan.data = data

    // Found a valid scan!
    // Notify the caller and clear the scan to close the dialog
    onDone(scan)
    setScan(null)
  }

  let imageElement = null
  if (!open) {
    imageElement = ''
  } else if (scan) {
    imageElement = (
      <img src={scan.image} style={{ width: '100%' }} alt="Scanned" />
    )
  } else {
    imageElement = (
      <QrReader
        delay={delay}
        resolution={600}
        apiConfig={apiConfig}
        imageType="image/jpeg"
        imageQuality={0.8}
        onScan={onScan}
        onError={addError}
      />
    )
  }

  return (
    <Dialog open={open} PaperProps={{ style: dialogStyle }}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {imageElement}
        {scan ? (
          <div className={classes.scanData}>{formatScan(scan.data)}</div>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        {/*<Button
          disabled={!scan}
          style={scan ? activeButtonColors : null}
          variant="outlined"
          onClick={this.handleDone}
        >
          Okay
        </Button>*/}
      </DialogActions>
    </Dialog>
  )
}

BarcodeReader.defaultProps = defaultProps

/** Build the barcode API config */
const apiConfig = async () => {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()
  const endpoint = await API.endpoint('barcode')

  return {
    auth: `Bearer ${token}`,
    url: `${endpoint}/api/barcode`,
  }
}

export default BarcodeReader
