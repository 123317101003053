import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextFieldProps,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import { useApp, useSystem } from '../providers'
import { AppTextField, ContentContainer } from './common'

const FORM_SCHEMA = yup.object({
  customer_name: yup.string().label('Your Name').required(),
  application: yup.string(),
  company_name: yup.string().label('Company Name').required(),
  prod_descrip: yup.string(),
  plant_location: yup.string().label('Plant Location').required(),
  part_no: yup.string(),
  customer_phone: yup.string().label('Customer Telephone #').required(),
  part_quantity: yup.string(),
  customer_email: yup
    .string()
    .label('Customer E-Mail Address')
    .required()
    .email(),
  SO_number: yup.string(),
  serial_numbers: yup.string(),
  problem_descrip: yup
    .string()
    .label('Customer Detailed Description')
    .required(),
})

const DEFAULT_VALUES = {
  customer_name: '',
  application: '',
  company_name: '',
  prod_descrip: '',
  plant_location: '',
  part_no: '',
  customer_phone: '',
  part_quantity: '',
  customer_email: '',
  SO_number: '',
  serial_numbers: '',
  problem_descrip: '',
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      cell: {
        padding: theme.spacing(1),
      },
    }),
  { name: 'ReportProblem' }
)

/**
 * Form to report a problem to KSS
 */
const ReportProblem: React.FC = () => {
  const { api, asBusy } = useApp()
  const { tenant } = useSystem()
  const { enqueueSnackbar } = useSnackbar()

  const submit = async (values: any): Promise<boolean> => {
    // Submit the problem report
    const resp = await asBusy(() => api.reportProblem(tenant.tenant_id, values))
    if (resp) {
      // Notify the user
      enqueueSnackbar('Sent Problem Report', { variant: 'success' })
    }

    return !!resp
  }

  return (
    <ContentContainer>
      <Formik
        validationSchema={FORM_SCHEMA}
        initialValues={DEFAULT_VALUES}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (await submit(values)) {
            resetForm()
          }
          setSubmitting(false)
        }}
      >
        {(f) => (
          <form id="problem" noValidate onSubmit={f.handleSubmit}>
            <Card>
              <CardHeader title="Report A Problem"></CardHeader>
              <CardContent>
                <Grid container>
                  <FormRow>
                    <FormInput
                      name="customer_name"
                      label="Your Name"
                      required={true}
                      placeholder="Enter a name or address"
                    />
                    <FormInput
                      name="application"
                      label="Application"
                      placeholder="Application"
                    />
                  </FormRow>
                  <FormRow>
                    <FormInput
                      name="company_name"
                      label="Company Name"
                      required={true}
                    />
                    <FormInput
                      name="prod_descrip"
                      label="Product Description"
                    />
                  </FormRow>
                  <FormRow>
                    <FormInput
                      name="plant_location"
                      label="Plant Location"
                      required={true}
                    />
                    <FormInput
                      name="part_no"
                      label="KSS Part #"
                      placeholder="Part Number"
                    />
                  </FormRow>
                  <FormRow>
                    <FormInput
                      name="customer_phone"
                      label="Customer Telephone #"
                      type="tel"
                      required={true}
                    />
                    <FormInput
                      name="part_quantity"
                      label="Quantity of Units Involved"
                    />
                  </FormRow>
                  <FormRow>
                    <FormInput
                      name="customer_email"
                      label="Customer E-Mail Address"
                      required={true}
                    />
                    <FormInput name="SO_number" label="Sales Order #" />
                  </FormRow>
                  <FormRow>
                    <FormInput
                      name="serial_numbers"
                      label="Serial Number(s)"
                      multiline
                      rows={4}
                    />

                    <FormInput
                      name="problem_descrip"
                      label="Customer Detailed Description"
                      required={true}
                      multiline
                      rows={4}
                    />
                  </FormRow>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  style={{ marginLeft: '12px', marginRight: '12px' }}
                  type="reset"
                  form="problem"
                  onClick={() => f.resetForm()}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '12px', marginRight: '12px' }}
                  type="submit"
                  form="problem"
                  disabled={f.isSubmitting}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Formik>
    </ContentContainer>
  )
}

const FormRow = (props: any) => {
  const { children, ...other } = props

  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} md={6} className={classes.cell} {...other}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={6} className={classes.cell} {...other}>
        {children[1]}
      </Grid>
    </>
  )
}

const FormInput = (props: TextFieldProps) => {
  // Use FastField for more responsive typing
  // Note: FastField only works if the fields are independant
  return <AppTextField {...props} variant="outlined" margin="dense" fullWidth />
}

export default ReportProblem
