import { useState, useEffect } from 'react'

import { ScaleLinear } from 'd3-scale'

import {
  useAppSelector,
  useAppDispatch,
  setTooltipRow,
  setTooltipLocked,
} from '../../../lib/redux'
import { ChartableTag, VictoryLayoutProps } from '../shared'
import EventOverlay from './EventOverlay'
import ActiveDisplay from './ActiveDisplay'
import { useIsSmallScreen } from '../../../lib/hooks'

export type Props = {
  /** All chart data */
  rows: any[][]
  /** Displayed tags */
  tags: ChartableTag[]
  /** Indexes of tags in the tag list, for picking colors */
  tagIndexes: number[]
  /** Scales to fit tag data onto shared chart */
  tagScales?: ScaleLinear<number, number>[]
  /** Are tooltips synced between charts? */
  sync?: boolean
} & VictoryLayoutProps

/**
 * Chart tooltip, showing tag values at the cursor position.
 */
const Tooltip: React.FC<Props> = (props: Props) => {
  const { rows, sync = false, ...victoryProps } = props
  const { domain, scale } = victoryProps

  const [localRow, setLocalRow] = useState<any>(null)

  const isSmallScreen = useIsSmallScreen()

  // Fetch some global state
  const tooltipRow = useAppSelector((state) => state.tooltipRow)
  const tooltipLocked = useAppSelector((state) => state.tooltipLocked)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      if (!sync) {
        // Unlock the tooltip when unmounted
        dispatch(setTooltipLocked(false))
      }
    }
  }, [dispatch, sync])

  // Callback to set the selected data row
  const setRow = (row: any, force = false) => {
    if (tooltipLocked && !force) {
      return
    }
    if (!isSmallScreen && sync) {
      // Share the data row between charts
      dispatch(setTooltipRow(row))
    } else {
      // Only set for this chart
      setLocalRow(row)
    }
  }

  // Determine the graph bounds and mid-points for easier math later
  const [xMin, xMax] = domain!.x // eslint-disable-line @typescript-eslint/no-non-null-assertion

  // Is a row selected?
  const row = sync && !isSmallScreen ? tooltipRow : localRow
  // Where on the graph is the selected row?
  const xRow = row ? scale.x(row[0]) : 0

  // Clear the locked tooltip if it is now off the chart (e.g. changed time range)
  if (row && tooltipLocked && (xRow < scale.x(xMin) || xRow > scale.x(xMax))) {
    setRow(null, true)
    dispatch(setTooltipLocked(false))
  }

  return (
    <g>
      {row && <ActiveDisplay {...props} row={row} />}
      <EventOverlay rows={rows} setRow={setRow} {...victoryProps} />
    </g>
  )
}

export default Tooltip
