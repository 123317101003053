import { VictoryAxis, VictoryAxisProps } from 'victory'

import { NullComponent, Y_AXIS_WIDTH, OPACITY_UNFOCUSED } from './shared'

const DEFAULT_COLOR = '#CCCCCC'

type Props = {
  /** Is this tag currently focused? */
  focused: boolean | null
  /** Tag color */
  color?: string
} & VictoryAxisProps

/**
 * Standard Y Axis showing a few value ticks
 */
export const YLabels: React.FC<Props> = (props: Props) => {
  const { focused, color = DEFAULT_COLOR, ...other } = props

  const fontSize = 12
  const opacity = focused === false ? OPACITY_UNFOCUSED : 1.0

  return (
    <VictoryAxis
      tickCount={3}
      offsetX={Y_AXIS_WIDTH}
      {...other}
      dependentAxis
      crossAxis={false}
      gridComponent={<NullComponent />}
      style={{
        axis: {
          stroke: 'none',
        },
        axisLabel: {
          padding: 40,
          fontFamily: 'Roboto',
          fill: color,
          fontSize,
          opacity,
        },
        ticks: {
          stroke: color,
          size: 10,
          opacity,
        },
        tickLabels: {
          fill: color,
          fontSize,
          opacity,
        },
      }}
    />
  )
}

export default YLabels
