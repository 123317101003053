import React from 'react'

import { VictoryArea, Selection } from 'victory'
import { useIsSmallScreen } from '../../../lib/hooks'

import {
  useAppSelector,
  useAppDispatch,
  setTooltipLocked,
} from '../../../lib/redux'
import { VictoryLayoutProps } from '../shared'

export type Props = {
  /** All chart data */
  rows: any[][]
  /** Callback to set the row associated with the current tooltip position */
  setRow: (row: any[] | null, force?: boolean) => void
} & VictoryLayoutProps

/**
 * Overlay for Tooltip that handles mouse events
 */
const EventOverlay: React.FC<Props> = (props: Props) => {
  const { rows, setRow, ...victoryProps } = props
  const { domain } = victoryProps

  // Fetch some global state
  const tooltipLocked = useAppSelector((state) => state.tooltipLocked)
  const dispatch = useAppDispatch()

  const isSmallScreen = useIsSmallScreen()

  // Callback to find the best-matching row
  const selectRow = (
    event: React.SyntheticEvent<any>,
    targetProps: any,
    force = false
  ) => {
    setRow(findRow(rows, findDate(event, targetProps)), force)
  }

  // Mouse event handlers
  const onMouseUp = (event: React.SyntheticEvent<any>, targetProps: any) => {
    if (isSmallScreen) {
      // Clear the tooltip
      setRow(null)
    } else {
      // Toggle the locked flag
      dispatch(setTooltipLocked(!tooltipLocked))
      if (tooltipLocked) {
        // Move the tooltip to the current location
        selectRow(event, targetProps, true)
      }
    }
  }
  const onMouseDown = (event: React.SyntheticEvent<any>, targetProps: any) => {
    // Select the row at the current position
    selectRow(event, targetProps, false)
  }

  const onMouseMove = (event: React.SyntheticEvent<any>, targetProps: any) => {
    // Select the row at the current position
    selectRow(event, targetProps, false)
  }
  const onMouseLeave = () => {
    // Clear the tooltip
    setRow(null)
  }

  // Determine the graph bounds and mid-points for easier math later
  const [xMin, xMax] = domain!.x // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const [yMin, yMax] = domain!.y // eslint-disable-line @typescript-eslint/no-non-null-assertion

  return (
    <VictoryArea
      {...victoryProps}
      data={[
        { x: xMin, y0: yMin, y: yMax },
        { x: xMax, y0: yMin, y: yMax },
      ]}
      style={{
        data: {
          fill: 'transparent',
        },
      }}
      events={[
        {
          target: 'parent',
          eventHandlers: {
            onMouseDown,
            onMouseUp,
            onMouseMove,
            onMouseLeave,
            onTouchStart: onMouseDown,
            onTouchMove: onMouseMove,
            onTouchEnd: onMouseUp,
          },
        },
      ]}
    />
  )
}

/** Find the date for the selected position on the graph */
const findDate = (event: any, props: any): Date => {
  const svgCoords = Selection.getSVGEventCoordinates(event, props.parentSVG)
  const dataCoords = Selection.getDataCoordinates(
    props,
    props.scale,
    svgCoords.x,
    svgCoords.y
  )

  return dataCoords.x as unknown as Date
}

/** Find the row that best matches the tooltip date */
const findRow = (rows: any[][], t: Date) => {
  if (!t) {
    // No selected date
    return null
  }

  // Get the timestamp of the selected date, in ms
  const tMs = t.valueOf()
  // Find the first row at or after the selected time
  const index = rows.findIndex((r) => r[0].valueOf() >= tMs)

  if (index === -1) {
    // Did not find any row
    return null
  }

  // Use this row as the end of the span
  const next = rows[index]
  if (index === 0) {
    // There is nothing else before this
    return next
  }

  // Get the row that immediately precedes this
  const prev = rows[index - 1]

  // Figure out which row is actually closer to the selected time
  const prevDelta = Math.abs(prev[0].valueOf() - tMs)
  const nextDelta = Math.abs(next[0].valueOf() - tMs)

  return prevDelta <= nextDelta ? prev : next
}

export default EventOverlay
