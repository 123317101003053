/**
 * User Sign Form
 */
import { Typography } from '@material-ui/core'

import { Authenticator } from '@aws-amplify/ui-react'

import '@aws-amplify/ui-react/styles.css'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import logo from '../images/logo-white.svg'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
      },
      welcome: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
      },
      logo: {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
        marginTop: theme.spacing(4),
      },
    }),
  { name: 'SignIn' }
)

const SignIn: React.FC = () => {
  const classes = useStyles()

  // Custom components to be inserted into the Authenticator form
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
  const components = {
    SignIn: {
      Header() {
        return (
          <Typography variant="h6" className={classes.welcome}>
            Welcome to SepTrac
          </Typography>
        )
      },
    },
    Footer() {
      return <img src={logo} alt="KSS Logo" className={classes.logo} />
    },
  }

  return (
    <div className={classes.container}>
      <Authenticator components={components} hideSignUp={true} />
    </div>
  )
}

export default SignIn
