import React from 'react'

import { TextField, TextFieldProps } from '@material-ui/core'
import { Field, FastField, FieldProps, getIn } from 'formik'

type Props = {
  /** If true, use the FastField (no cross field valiation) */
  fast?: boolean
} & TextFieldProps

/**
 * Standard TextField that can be used in Formik forms
 */
const AppTextField: React.FC<Props> = (props) => {
  const { fast = false, ...rest } = props
  return fast ? (
    <FastField component={InnerField} {...rest} />
  ) : (
    <Field component={InnerField} {...rest} />
  )
}

/**
 * Utility component for use with Formik <Field/> and <FastField/>
 *
 * For example:
 *
 *   <FastField name="title" component={InnerField} />
 *
 * See: https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/
 */
const InnerField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form: _form, ...rest } = props

  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={isTouched && errorMessage ? errorMessage : helperText}
      {...rest} // includes any Material-UI specific props
      {...field} // includes all props contributed by the Formik Field/FastField
    />
  )
}

export default AppTextField
