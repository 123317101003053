import React from 'react'

import { VictoryLabelProps } from 'victory'

import { Layout, TooltipLabel } from './shared'
type Props = {
  labels: TooltipLabel[]
  layout: Layout
  timestamp: Date
} & VictoryLabelProps

/**
 * Custom tooltip labels
 */
const CustomLabels: React.FC<Props> = (props: Props) => {
  const { labels, layout, timestamp } = props

  return (
    <svg
      x={layout.xLabels}
      y={layout.yLabels}
      width={layout.wLabels}
      height={layout.hLabels}
    >
      <text
        x={0}
        y={0}
        style={{
          fontSize: layout.fontSize,
        }}
      >
        {/* Timestamp */}
        <tspan dx={0} dy={layout.fontSize} style={{ fontWeight: 700 }}>
          {timestamp.toLocaleString()}
        </tspan>
        {
          /* Labels */
          labels.map((l) => (
            <React.Fragment key={l.label}>
              <tspan x={layout.xValue} dy={layout.lineHeight}>
                {l.value}
              </tspan>
              <tspan x={layout.xLabel} dy={0}>
                {l.label}
              </tspan>
            </React.Fragment>
          ))
        }
      </text>
      {
        /* Color dots */
        labels.map((l, index) => (
          <circle
            key={l.label}
            cx={layout.xDot}
            cy={layout.fontSize + (index + 0.75) * layout.lineHeight}
            r={4}
            style={{
              fill: l.color,
            }}
          />
        ))
      }
    </svg>
  )
}

export default CustomLabels
