import { useState, useEffect } from 'react'

import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DetailsIcon from '@material-ui/icons/OpenInBrowser'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { renderLite, MIN_CELL, BARE_TABLE } from '../lib/tables'
import { byString } from '../lib/sortItems'
import { OrderHistory, System } from '../lib/types'
import { useApp, useSystem } from '../providers'
import { ContentContainer } from './common'
import LoadPage from './common/LoadPage'

// Align numbers to the right
const ALIGN_RIGHT = () => ({ align: 'right' })

/**
 * Order History
 */
const _OrderHistory: React.FC = () => {
  const { api } = useApp()
  const { tenant, system } = useSystem()

  const [orders, setOrders] = useState<OrderHistory[] | null>(null)
  const [order, setOrder] = useState<OrderHistory | null>(null)

  useEffect(() => {
    // Fetch the orders
    api.listOrders(tenant.tenant_id).then((orders) => {
      if (!orders) {
        // Nothing returned!
        return
      }

      // Filter the orders to the current system
      orders = filterOrders(system, orders)

      // Sort the orders by by date desc
      sortOrders(orders, 'order_date', 'desc')

      // Use these orders
      setOrders(orders)
    })
  }, [api, tenant, system])

  if (!orders) {
    return <LoadPage />
  }

  const options: MUIDataTableOptions = {
    ...BARE_TABLE,
    pagination: orders.length > 10,
  }

  const columns: MUIDataTableColumn[] = [
    {
      name: 'details',
      options: {
        setCellProps: MIN_CELL,
        customHeadLabelRender: () => null,
        customBodyRenderLite: renderLite(orders, (order) => (
          <Tooltip title="Show Details">
            <IconButton onClick={() => setOrder(order)} color="primary">
              <DetailsIcon />
            </IconButton>
          </Tooltip>
        )),
      },
    },
    {
      name: 'order_id',
      label: 'Order Number',
    },
    {
      name: 'customer_po',
      label: 'Purchase Order',
    },
    {
      name: 'order_date',
      label: 'Date Ordered',
    },
  ]

  // The orders will be filtered to the system if the st_no is available.
  const name = system?.st_no ? system.name : tenant.name

  return (
    <ContentContainer>
      <MUIDataTable
        title={<Typography variant="h5">Order History for {name}</Typography>}
        options={options}
        columns={columns}
        data={orders}
      />
      <OrderDetails order={order} onClose={() => setOrder(null)} />
    </ContentContainer>
  )
}

type OrderDetailsProps = {
  order: OrderHistory | null
  onClose: () => void
}
const OrderDetails = (props: OrderDetailsProps) => {
  const { order, onClose } = props

  if (!order) {
    // Nothing to show
    return null
  }

  const options = {
    ...BARE_TABLE,
  }

  const columns: MUIDataTableColumn[] = [
    {
      name: 'part_number',
      label: 'Part Number',
    },
    {
      name: 'part_description',
      label: 'Part Description',
    },
    {
      name: 'quantity_ordered',
      label: 'QTY Ordered',
      options: {
        setCellProps: ALIGN_RIGHT,
      },
    },
    {
      name: 'quantity_shipped',
      label: 'QTY Shipped',
      options: {
        setCellProps: ALIGN_RIGHT,
      },
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'carrier',
      label: 'Carrier',
    },
    {
      name: 'tracking_number',
      label: 'Tracking #',
    },
  ]
  const data = order.items

  return (
    <Dialog
      open={!!order}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell>Purchase Order</TableCell>
              <TableCell>Date Ordered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{order.order_id}</TableCell>
              <TableCell>{order.customer_po}</TableCell>
              <TableCell>{order.order_date}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogTitle>
      <DialogContent>
        <MUIDataTable
          title={null}
          options={options}
          columns={columns}
          data={data}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Filter orders to those shipped to the currently selected system
 *
 * @param {*} orders
 * @returns filtered orders
 */
const filterOrders = (system: System | null, orders: OrderHistory[]) => {
  // Get the selected system
  if (!system || !system.st_no) {
    // System does not have a Ship To number, so show all orders
    return orders
  }

  // Filter to the orders shipped to this system
  return orders.filter((o) => o.st_no === system.st_no)
}

/**
 * Sort the orders by the table column
 */
const sortOrders = (
  orders: OrderHistory[] | null,
  sortCol: string,
  sortDir: 'asc' | 'desc'
) => {
  if (orders) {
    // Sort by the column
    orders.sort(byString(sortCol))
    if (sortDir === 'desc') {
      orders.reverse()
    }
  }
}

export default _OrderHistory
