import React, { useReducer, useRef } from 'react'

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  Tab,
  Tabs,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CalendarIcon from '@material-ui/icons/DateRange'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import NextIcon from '@material-ui/icons/NavigateNext'

import cx from 'classnames'
import {
  add,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  startOfDay,
} from 'date-fns'
import { DateRange, Range } from 'react-date-range'

import { isStartOfDay, isEndOfDay } from '../../lib/dates'
import { useIsSmallScreen } from '../../lib/hooks'
import {
  AbsoluteTimeRange,
  RelativeTimeRange,
  RelativeTimeSpan,
  TimeRange,
} from './shared'

// We use non-null asssertions in a few places to deal with
// relative vs absolute selections
/* eslint-disable @typescript-eslint/no-non-null-assertion */

/** How many days of overlap when shifting by whole days */
const SHIFT_DAYS_OVERLAP = 1
/** How much range overlap, as a ratio, when shifting by time range */
const SHIFT_RANGE_OVERLAP = 0.2

type TabName = 'absolute' | 'relative'

const RELATIVE_PRESETS: RelativeTimeRange[] = [
  { count: 3, span: 'hours' },
  { count: 6, span: 'hours' },
  { count: 3, span: 'days' },
  { count: 1, span: 'weeks' },
]

type Props = {
  /** Selected time range */
  timeRange: TimeRange
  /** Callback triggered when the time range is updated */
  onChange: (timeRange: TimeRange) => void
  /** Actual start time of the range */
  startTime: Date
  /** Actual end time of the range */
  endTime: Date
}

type State = {
  /** Is the dialog open? */
  open: boolean
  /** Selected tag name */
  tab: TabName

  /** Absolute range info, used by DateRange component */
  absoluteRange: Range
  /** Relative range info */
  relativeRange: RelativeTimeRange
}

/** Dispatcher actions */
type Action =
  | { type: 'open' }
  | { type: 'openAbsolute' }
  | { type: 'openRelative' }
  | { type: 'close' }
  | { type: 'switchTab'; tab: TabName }
  | { type: 'setAbsoluteRange'; range: Range }
  | { type: 'setRelativeRange'; relativeRange: RelativeTimeRange }
  | { type: 'setRelativeCount'; count: number }
  | { type: 'setRelativeSpan'; span: RelativeTimeSpan }
  | { type: 'shiftTimeRange'; timeRange: TimeRange }

/**
 * Reducer to update the component state based on the action.
 *
 * @param state current state
 * @param action dispatched action
 * @returns new state
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'open':
      return { ...state, open: true }
    case 'openAbsolute':
      return { ...state, open: true, tab: 'absolute' }
    case 'openRelative':
      return { ...state, open: true, tab: 'relative' }
    case 'close':
      return { ...state, open: false }
    case 'switchTab':
      return { ...state, tab: action.tab }
    case 'setAbsoluteRange':
      return { ...state, absoluteRange: { ...action.range } }
    case 'setRelativeRange':
      return { ...state, relativeRange: { ...action.relativeRange } }
    case 'setRelativeCount':
      return {
        ...state,
        relativeRange: { ...state.relativeRange, count: action.count },
      }
    case 'setRelativeSpan':
      return {
        ...state,
        relativeRange: { ...state.relativeRange, span: action.span },
      }
    case 'shiftTimeRange':
      return {
        ...state,
        absoluteRange: {
          ...state.absoluteRange,
          startDate: action.timeRange.absolute!.startDate,
          endDate: action.timeRange.absolute!.endDate,
        },
        tab: 'absolute',
      }
    default:
      return state
  }
}

/**
 * Build the initial state based on current selection
 *
 * @param timeRange selected time range
 * @returns initial state
 */
const makeInitialState = (timeRange: TimeRange): State => {
  // Fill out the default state
  const state: State = {
    open: false,
    tab: 'relative',
    absoluteRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    relativeRange: {
      count: 6,
      span: 'hours',
    },
  }

  if (timeRange.absolute) {
    // Starting with an absolute range
    state.tab = 'absolute'
    state.absoluteRange = {
      startDate: timeRange.absolute.startDate,
      endDate: timeRange.absolute.endDate,
      key: 'selection',
    }
  } else if (timeRange.relative) {
    // Starting with a relative range
    state.tab = 'relative'
    state.relativeRange = {
      count: timeRange.relative.count,
      span: timeRange.relative.span,
    }
  }

  return state
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tabContent: {
        position: 'relative',
      },
      tab: {
        visibility: 'hidden',
      },
      visibleTab: {
        visibility: 'visible',
      },

      // Assuming that the Absolute tab will always be the biggest,
      relativeTab: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        '& > div > div': {
          alignItems: 'baseline',
        },
      },
      dialog: {
        '& .MuiDialog-paper': {
          [theme.breakpoints.down('xs')]: {
            // Give the dialog a bit more room to grow in mobile
            margin: theme.spacing(4, 0),
            maxWidth: '100%',
            width: 'auto',
          },
        },
      },
    }),
  { name: 'TimeRangePicker' }
)

const TimeRangePicker: React.FC<Props> = (props: Props) => {
  const { timeRange, onChange, startTime, endTime } = props

  // Container for the dialog
  const ref = useRef<HTMLDivElement>(null)

  const isSmallScreen = useIsSmallScreen()

  const [{ open, tab, absoluteRange, relativeRange }, dispatch] = useReducer(
    reducer,
    timeRange as any,
    makeInitialState
  )
  const classes = useStyles()

  // Some common callbacks

  // Open the dialog to the abolsute tab
  const openAbsolute = () => dispatch({ type: 'openAbsolute' })
  // Close the dialog
  const closeDialog = () => dispatch({ type: 'close' })
  // Set a relative range
  const setRelativeRange = (relativeRange: RelativeTimeRange) =>
    dispatch({ type: 'setRelativeRange', relativeRange })
  // Set a relative range and applyt the change
  const selectRelativePreset = (relativeRange: RelativeTimeRange) => {
    dispatch({ type: 'setRelativeRange', relativeRange })
    onChange({ relative: relativeRange })
  }
  // Shift the time range and apply the change
  const onShiftTimeRange = (forwards: boolean) => {
    const newTimeRange = shiftTimeRange(timeRange, startTime, endTime, forwards)
    dispatch({ type: 'shiftTimeRange', timeRange: newTimeRange })
    onChange(newTimeRange)
  }
  // Apply the time range selection from the current tab
  const applyTimeRange = () => {
    if (tab === 'absolute') {
      onChange({
        absolute: {
          startDate: startOfDay(absoluteRange.startDate!),
          endDate: endOfDay(absoluteRange.endDate!),
        },
      })
    } else {
      onChange({ relative: relativeRange })
    }
    closeDialog()
  }

  // Assumble the picker buttons
  const buttons: React.ReactNode[] = []

  // Shift backwards
  buttons.push(
    <Button key="prior" onClick={() => onShiftTimeRange(false)}>
      <PrevIcon />
    </Button>
  )
  if (timeRange.absolute) {
    // Absolute time range
    buttons.push(
      <Button
        key="absolute"
        onClick={openAbsolute}
        startIcon={isSmallScreen ? null : <CalendarIcon />}
      >
        {absoluteLabel(timeRange.absolute, isSmallScreen)}
      </Button>
    )
  } else if (timeRange.relative) {
    // Open to Absolute
    buttons.push(
      <Button key="calendar" onClick={openAbsolute}>
        <CalendarIcon color="inherit" />
      </Button>
    )

    if (!isSmallScreen) {
      // Relative presets
      RELATIVE_PRESETS.forEach((p) => {
        buttons.push(
          <RelativeButton
            key={`relative-${p.span}-${p.count}`}
            span={p.span}
            count={p.count}
            selected={relativeRange}
            onSelect={selectRelativePreset}
          />
        )
      })
    }

    // Assemble custome relative label
    const range = timeRange.relative
    const isCustom =
      isSmallScreen ||
      !RELATIVE_PRESETS.some(
        (p) => p.span === range.span && p.count === range.count
      )
    const customLabel = isCustom ? `Custom (${relativeLabel(range)})` : 'Custom'

    // Custom relative range
    buttons.push(
      <Button
        key="custom"
        onClick={() => dispatch({ type: 'openRelative' })}
        variant={isCustom ? 'contained' : 'outlined'}
        color={isCustom ? 'primary' : undefined}
      >
        {customLabel}
      </Button>
    )
  }

  // Shift forwards
  buttons.push(
    <Button key="next" onClick={() => onShiftTimeRange(true)}>
      <NextIcon />
    </Button>
  )

  return (
    <FormControl ref={ref}>
      <InputLabel shrink>Time Range</InputLabel>
      <div style={{ marginTop: '16px' }}>
        <ButtonGroup
          size="small"
          variant="outlined"
          disabled={open}
          disableElevation
        >
          {buttons}
        </ButtonGroup>
      </div>
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth={false}
        fullWidth={false}
        container={ref.current}
        className={classes.dialog}
      >
        <Tabs
          value={tab}
          onChange={(_event: any, tab: TabName) =>
            dispatch({ type: 'switchTab', tab })
          }
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value="relative" label="Relative" />
          <Tab value="absolute" label="Absolute" />
        </Tabs>
        <div className={classes.tabContent}>
          <Grid
            container
            direction="column"
            className={cx(classes.tab, classes.relativeTab, {
              [classes.visibleTab]: tab === 'relative',
            })}
          >
            <Grid item>
              <RelativeRow
                label="Hours"
                span="hours"
                counts={[1, 3, 6, 12]}
                selected={relativeRange}
                onSelect={setRelativeRange}
              />
            </Grid>
            <Grid item>
              <RelativeRow
                label="Days"
                span="days"
                counts={[1, 2, 3, 4, 5, 6]}
                selected={relativeRange}
                onSelect={setRelativeRange}
              />
            </Grid>
            <Grid item>
              <RelativeRow
                label="Weeks"
                span="weeks"
                counts={[1, 2, 3, 4]}
                selected={relativeRange}
                onSelect={setRelativeRange}
              />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item sm={2} xs={12}>
                  Custom:
                </Grid>
                <Grid item sm={10} xs={12}>
                  <TextField
                    value={relativeRange.count}
                    onChange={(event) =>
                      dispatch({
                        type: 'setRelativeCount',
                        count: parseInt(event.target.value),
                      })
                    }
                    inputProps={{
                      type: 'number',
                      style: { textAlign: 'right' },
                    }}
                    style={{ width: '100px', marginRight: '8px' }}
                  />
                  <Select
                    value={relativeRange.span}
                    onChange={(event) =>
                      dispatch({
                        type: 'setRelativeSpan',
                        span: event.target.value as RelativeTimeSpan,
                      })
                    }
                    style={{ width: '100px' }}
                    MenuProps={{
                      container: ref.current,
                    }}
                  >
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            className={cx(classes.tab, {
              [classes.visibleTab]: tab === 'absolute',
            })}
          >
            <DateRange
              onChange={(item) =>
                dispatch({ type: 'setAbsoluteRange', range: item.selection })
              }
              calendarFocus="backwards"
              moveRangeOnFirstSelection={false}
              months={isSmallScreen ? 1 : 2}
              ranges={[absoluteRange]}
              direction="horizontal"
              maxDate={new Date()}
            />
          </div>
        </div>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!validate(tab, absoluteRange, relativeRange)}
            onClick={applyTimeRange}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  )
}

type RelativeButtonProps = {
  /** Currently selected relative time range */
  selected: RelativeTimeRange
  /** Callback triggered when the button is clicked */
  onSelect: (range: RelativeTimeRange) => void
} & RelativeTimeRange

/**
 * Relative time range button (e.g. 3D)
 */
const RelativeButton = (props: RelativeButtonProps) => {
  const { span, count, onSelect, selected, ...other } = props

  // Is this time reange currently selected?
  const isSelected = span === selected.span && count === selected.count
  const label = span.charAt(0).toUpperCase()

  return (
    <Button
      {...other}
      color={isSelected ? 'primary' : undefined}
      variant={isSelected ? 'contained' : 'outlined'}
      onClick={() => onSelect(props)}
    >
      {count}
      {label}
    </Button>
  )
}

type RelativeRowProps = {
  /** Label for the row */
  label: string
  /** Relative time span for buttons */
  span: RelativeTimeSpan
  /** Relative time count for each button */
  counts: number[]
  /** Currently selected relative time range */
  selected: RelativeTimeRange
  /** Callback triggered when the button is clicked */
  onSelect: (range: RelativeTimeRange) => void
}

/**
 * A row of relative time range buttons for the same span (e.g. 1,3,6 hours)
 */
const RelativeRow = (props: RelativeRowProps) => {
  const { label, span, counts, selected, onSelect } = props

  return (
    <Grid container>
      <Grid item sm={2} xs={12}>
        {label}:
      </Grid>
      <Grid item sm={10} xs={12}>
        <ButtonGroup variant="outlined" disableElevation>
          {counts.map((count) => (
            <RelativeButton
              key={count}
              span={span}
              count={count}
              selected={selected}
              onSelect={onSelect}
            />
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}

/**
 * Label for the TimeRangePicker when an absolute range is selected
 */
const absoluteLabel = (range: AbsoluteTimeRange, isSmallScreen: boolean) => {
  const [startLabel, endLabel] =
    isStartOfDay(range.startDate) && isEndOfDay(range.endDate)
      ? [
          range.startDate.toLocaleDateString(),
          range.endDate.toLocaleDateString(),
        ]
      : [range.startDate.toLocaleString(), range.endDate.toLocaleString()]

  if (isSmallScreen) {
    return (
      <span>
        {startLabel}
        <br />
        {endLabel}
      </span>
    )
  } else {
    return `${startLabel} - ${endLabel}`
  }
}

/**
 * Label for the relative ranges (e.g. 6H)
 */
const relativeLabel = (range: RelativeTimeRange) =>
  `${range.count}${range.span.charAt(0).toUpperCase()}`

/**
 * Validate the form selections.
 *
 * @param tab selected tab
 * @param _absoluteRange absolute range selection
 * @param relativeRange relative range selection
 * @returns true if the form can be submitted
 */
const validate = (
  tab: TabName,
  _absoluteRange: Range,
  relativeRange: RelativeTimeRange
): boolean => {
  if (tab === 'relative') {
    // Make sure a valid number has been entered for the custom relative range
    if (isNaN(relativeRange.count)) {
      return false
    }
  }

  return true
}

/**
 * Build a new time range that is shifted from the current time range by about one screen width.
 *
 * Some overlap is kept between screens to provide context.
 *
 * @param timeRange selected time range
 * @param startTime actual start time of the range
 * @param endTime actual end time of the range
 * @param forward If true, the range is shifted forward in time;
 *                if false, the range is shifted backwards in time
 * @returns a new shifted time range
 */
const shiftTimeRange = (
  timeRange: TimeRange,
  startTime: Date,
  endTime: Date,
  forward: boolean
): TimeRange => {
  const direction = forward ? 1 : -1

  if (timeRange.absolute && isStartOfDay(startTime) && isEndOfDay(endTime)) {
    // Shift by whole days, leaving one day of overlap
    const deltaDays = differenceInDays(endTime, startTime) + 1
    if (deltaDays > SHIFT_DAYS_OVERLAP) {
      const shift = { days: direction * (deltaDays - SHIFT_DAYS_OVERLAP) }
      return {
        absolute: {
          startDate: add(startTime, shift),
          endDate: add(endTime, shift),
        },
      }
    }
  }

  // Shift by 80% of the window
  const diff = differenceInMinutes(endTime, startTime)
  const shift = {
    minutes: direction * (1 - SHIFT_RANGE_OVERLAP) * diff,
  }

  return {
    absolute: {
      startDate: add(startTime, shift),
      endDate: add(endTime, shift),
    },
  }
}

export default TimeRangePicker
