/**
 * Glyph width utilities intended for SVG layout.
 *
 * Assumes the Roboto font.
 */

// Glyph ratios (relative to font size) for ISO-8859 characters
const GLYPHS = [
  0, 0.443, 0, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.248, 0, 0.443, 0.443,
  0.248, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443,
  0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.248, 0.258, 0.32,
  0.625, 0.563, 0.75, 0.688, 0.188, 0.375, 0.348, 0.5, 0.567, 0.259, 0.313,
  0.264, 0.5, 0.563, 0.562, 0.563, 0.563, 0.563, 0.563, 0.563, 0.563, 0.563,
  0.563, 0.25, 0.212, 0.509, 0.549, 0.522, 0.5, 0.898, 0.75, 0.625, 0.651,
  0.656, 0.568, 0.563, 0.682, 0.713, 0.272, 0.552, 0.688, 0.563, 0.873, 0.713,
  0.688, 0.631, 0.688, 0.625, 0.625, 0.625, 0.648, 0.75, 0.938, 0.627, 0.688,
  0.625, 0.313, 0.438, 0.328, 0.438, 0.563, 0.31, 0.563, 0.563, 0.563, 0.564,
  0.563, 0.375, 0.563, 0.551, 0.25, 0.302, 0.563, 0.243, 0.877, 0.552, 0.57,
  0.563, 0.568, 0.375, 0.516, 0.39, 0.552, 0.563, 0.752, 0.5, 0.563, 0.5, 0.375,
  0.244, 0.438, 0.688, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443,
  0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443,
  0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443, 0.443,
  0.443, 0.443, 0.443, 0.248, 0.25, 0.563, 0.625, 0.713, 0.625, 0.24, 0.625,
  0.438, 0.813, 0.447, 0.5, 0.554, 0, 0.813, 0.458, 0.375, 0.563, 0.375, 0.375,
  0.313, 0.566, 0.489, 0.261, 0.25, 0.367, 0.455, 0.5, 0.75, 0.776, 0.813, 0.5,
  0.75, 0.75, 0.75, 0.75, 0.75, 0.75, 1, 0.651, 0.568, 0.568, 0.568, 0.568,
  0.335, 0.313, 0.375, 0.375, 0.75, 0.713, 0.688, 0.688, 0.688, 0.688, 0.688,
  0.533, 0.688, 0.648, 0.648, 0.648, 0.648, 0.688, 0.591, 0.625, 0.563, 0.563,
  0.563, 0.563, 0.563, 0.563, 0.875, 0.563, 0.563, 0.563, 0.563, 0.563, 0.313,
  0.313, 0.375, 0.375, 0.586, 0.552, 0.57, 0.57, 0.57, 0.57, 0.57, 0.571, 0.566,
  0.552, 0.552, 0.552, 0.552, 0.563, 0.571, 0.563,
]
// Average Glyph ratio for non-ISO-8859 characters, just in case
const NON_ISO8859_AVERAGE = 0.843

/**
 * Estimates the width of the test when rendered in Roboto at the font size.
 *
 * @param text test to display in Roboto font
 * @param fontSize font size, in pixels
 * @returns estimate text width, in pixels
 */
export const estimateTextWidth = (text: string, fontSize: number): number => {
  let total = 0
  for (let i = 0; i < text.length; ++i) {
    const c = text.charCodeAt(i)
    total += c < 256 ? GLYPHS[c] : NON_ISO8859_AVERAGE
  }

  return total * fontSize
}

/**
 * Estimate the maximum width of all the text strings when rendered in Roboto at
 * the font size.
 *
 * Intended to help with the layout of columns of text.
 *
 * @param texts all text strings
 * @param fontSize font size, in pixels
 * @returns maximum estimate width, in pixels
 */
export const estimateMaxWidth = (texts: string[], fontSize: number): number =>
  texts.reduce(
    (maxLength, text) => Math.max(maxLength, estimateTextWidth(text, fontSize)),
    0
  )
