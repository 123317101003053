import { FileUpload } from './types'

/** Shorthand for encoding variables in URLs */
export const U = encodeURIComponent
/** Shorthand for encoding a path into the URLs */
export const P = (p: string): string =>
  p.split('/').map(encodeURIComponent).join('/')

/**
 * Convert an array of items to a Record keyed by key.
 *
 * For example:
 *
 *   tenantsByName = toRecord(tenants, 'name');
 *
 * https://stackoverflow.com/a/61414615/4867429
 *
 * @param items array of items
 * @param key the item field to use as the record key
 * @returns the items as a record
 */
export function toRecord<T extends Record<string, any>, K extends keyof T>(
  items: T[],
  key: K
): Record<T[K], T> {
  return items.reduce(
    (acc, item) => ({ ...acc, [item[key]]: item }),
    {} as Record<T[K], T>
  )
}

/**
 * Loads a value from Local Storage
 *
 * @param key unique key into local storage
 * @param defaultValue value to return if key is not in storage
 * @returns data from storage, or null is none exists
 */
export function loadLocal(key: string, defaultValue: unknown = null): any {
  const data = localStorage.getItem(key)
  return data === null ? defaultValue : JSON.parse(data)
}

/**
 * Saves a value into Local Storage
 *
 * @param key unique key into local storage
 * @param data to be stored
 */
export function saveLocal(key: string, data: unknown): void {
  localStorage.setItem(key, JSON.stringify(data))
}

/**
 * Upload a file to S3 using s pre-signed URL
 *
 * @param upload Upload info, including pre-signed URL and headers
 * @param file User-selected file
 */
export async function uploadFile(
  upload: FileUpload,
  file: File
): Promise<void> {
  await fetch(upload.put_url, {
    headers: upload.headers,
    method: 'PUT',
    body: file,
  })
}

/**
 * Check if a variable has a value.
 *
 * @param value the value to test
 * @returns true if the value is neither null or undefined
 */
export function hasValue<T>(value: T): value is Exclude<T, null | undefined> {
  return (value ?? null) != null
}
