/**
 * Material UI Theme for KSS
 */

import { ThemeOptions, createTheme } from '@material-ui/core'

export const fontFamily = '"Roboto", sans-serif'

// KSS Palette
export const midBlue = '#0093d0'
export const darkBlue = '#005a8a'

export const errorColor = '#B50000' // from heui-error
export const warnColor = '#FFA500' // '#F7FA61';
export const alertColor = '#FAB061'
export const severeAlertColor = '#FA6161'

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: darkBlue,
      light: midBlue,
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: warnColor,
    },
  },
  typography: {
    fontFamily,
  },
  overrides: {
    MuiTableCell: {
      root: {
        // Compact the table a bit
        padding: '8px 16px',
      },
      head: {
        // Use blue text in the table headers
        color: midBlue,
        '& .MuiButton-root': {
          color: midBlue,
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        // Avoid the double scroll bars
        overflowY: 'hidden',
      },
    },
  },
}

export const defaultTheme = createTheme(themeOptions)
