import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { BARE_TABLE, renderLite } from '../../lib/tables'
import { Part } from '../../lib/types'
import { AppTextField } from '../common'

type Props = {
  title: string
  type: string
  parts: Part[]
}

/** Order table for a specific kind of part */
const PartsTable: React.FC<Props> = (props: Props) => {
  const { title, type, parts } = props

  const options: MUIDataTableOptions = {
    // Strip the decorations from the table
    ...BARE_TABLE,
  }

  // Filter to just the the one kind of part
  const data = parts.filter((part) => part.partType === type)

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Part Name',
      options: {
        setCellProps: () => ({ width: '50%' }),
      },
    },
    {
      name: 'partNumber',
      label: 'Part Number',
      options: {
        setCellProps: () => ({ width: '20%' }),
      },
    },
    {
      name: 'qtyInstalled',
      label: 'Qty Installed',
      options: {
        setCellProps: () => ({ width: '15%' }),
      },
    },
    {
      name: 'orderQty',
      label: 'Order Qty',
      options: {
        setCellProps: () => ({ width: '15%' }),
        customBodyRenderLite: renderLite(data, (row) => (
          <AppTextField
            name={`qtys.${row.partNumber}`}
            type="number"
            InputProps={{ inputProps: { min: 0, step: 1 } }}
          />
        )),
      },
    },
  ]

  return (
    <MUIDataTable
      title={title}
      options={options}
      columns={columns}
      data={data}
    />
  )
}

export default PartsTable
