import React, { useEffect, useReducer, useRef } from 'react'

import {
  Button,
  Container,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Paper,
  Hidden,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import addDays from 'date-fns/addDays'

import formatUTC from '../../lib/formatUTC'
import { InfluentLog, Input, System } from '../../lib/types'
import { useSystem, useApp } from '../../providers'

import { ContentContainer, LoadPage } from '../common'
import ChartRangeSelector from '../charts/ChartRangeSelector'
import { fetchInfluentLogs, sortInputs } from './shared'
import CommonChart from '../charts/CommonChart'
import { ChartableTag, ChartView, tagColor } from '../charts/shared'
import AllInputs from './AllInputs'
import { useIsSmallScreen } from '../../lib/hooks'

const DEFAULT_TIME_INDEX = 3
const TIME_RANGES = [
  { days: 1 * 7, label: '1W' },
  { days: 4 * 7, label: '4W' },
  { days: 13 * 7, label: '3M' },
  { days: 366, label: '1Y' },
]

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      },
      controlCard: {
        flex: '0 0 auto',
        width: '100%',
        '& .MuiCardHeader-action': {
          marginTop: 0,
          marginRight: 0,
        },
      },
      controls: {
        flex: '0 0 auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
      },
      hideDrawerButton: {
        margin: theme.spacing(1),
      },
      controlsDrawer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        height: '100%',
        minWidth: '50vw',
        maxWidth: '75vw',
      },
      chart: {
        padding: theme.spacing(2),
        flex: '1 1 100%',
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      },
    }),
  { name: 'InfluentChart' }
)

type State = {
  availInputs: Input[]
  selectedInputs: Input[]
  fetchedLogs: InfluentLog | null
  selectedTimeIndex: number | null
  startTime: Date
  endTime: Date
  showControls: boolean
}

/** Dispatcher actions */
type Action =
  | { type: 'updateAvailInputs'; inputs: Input[] }
  | { type: 'selectInputs'; inputs: Input[] }
  | { type: 'setFetchedLogs'; fetchedLogs: InfluentLog | null }
  | { type: 'selectTimeIndex'; index: number }
  | { type: 'updateTimeRange'; startTime: Date; endTime: Date }
  | { type: 'setShowControls'; showControls: boolean }

/**
 * Reducer to update the component state based on the action.
 *
 * @param state current state
 * @param action dispatched action
 * @returns new state
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateAvailInputs':
      return {
        ...state,
        availInputs: action.inputs,
        selectedInputs: action.inputs,
      }
    case 'selectInputs':
      return { ...state, selectedInputs: action.inputs }
    case 'setFetchedLogs':
      return { ...state, fetchedLogs: action.fetchedLogs }
    case 'selectTimeIndex': {
      const days = TIME_RANGES[action.index].days
      const endTime = new Date()
      const startTime = addDays(endTime, -days)
      return {
        ...state,
        selectedTimeIndex: action.index,
        startTime,
        endTime,
      }
    }
    case 'updateTimeRange':
      return {
        ...state,
        selectedTimeIndex: null,
        startTime: action.startTime,
        endTime: action.endTime,
      }
    case 'setShowControls':
      return {
        ...state,
        showControls: action.showControls,
      }
    default:
      return state
  }
}

/**
 * Build the initial state based on current selection
 *
 * @param system current system
 * @returns initial state
 */
const makeInitialState = (system: System): State => {
  // Find all the inputs available for graphing
  const availInputs = system.inputs || []

  // Sort the inputs by units and name
  availInputs.sort(sortInputs)

  // Assign colors based on either the DB configuration or a rotating palette
  availInputs.forEach((input, index) => {
    if (!input.color) {
      input.color = tagColor(index)
    }
  })

  const days = TIME_RANGES[DEFAULT_TIME_INDEX].days
  const endTime = new Date()
  const startTime = addDays(endTime, -days)

  return {
    availInputs,
    selectedInputs: [...availInputs],
    fetchedLogs: null,
    selectedTimeIndex: DEFAULT_TIME_INDEX,
    startTime,
    endTime,
    showControls: false,
  }
}

const InfluentChart: React.FC = () => {
  const { system } = useSystem()
  const { api } = useApp()

  const isSmallScreen = useIsSmallScreen()
  const classes = useStyles()

  const contentRef = useRef<HTMLDivElement | null>(null)

  const [
    {
      availInputs,
      selectedInputs,
      fetchedLogs,
      selectedTimeIndex,
      startTime,
      endTime,
      showControls,
    },
    dispatch,
  ] = useReducer(reducer, system as any, makeInitialState)

  useEffect(() => {
    // Find all the inputs available for graphing
    const availInputs = system.inputs || []

    // Sort the inputs by units and name
    availInputs.sort(sortInputs)

    dispatch({ type: 'updateAvailInputs', inputs: availInputs })
  }, [system])

  useEffect(() => {
    if (!selectedInputs || !selectedInputs.length) {
      // No inputs selected - don't fetch or clear the data
      return
    }

    // Fetch the logs
    fetchInfluentLogs(api, system, selectedInputs, {
      start_utc: formatUTC(startTime),
      end_utc: formatUTC(endTime),
    })
      .then((fetchedLogs) => {
        if (fetchedLogs) {
          fetchedLogs.inputs.forEach((input, index) => {
            // Skip the Timestamp column
            if (index > 0 && !input.color) {
              input.color =
                availInputs.find((i) => i.input_id === input.input_id)?.color ??
                tagColor(index)
            }
          })
        }
        // Update the state
        dispatch({ type: 'setFetchedLogs', fetchedLogs })
      })
      .finally(() => {
        // Stop the spinner
      })
  }, [api, system, selectedInputs, startTime, endTime])

  useEffect(() => {
    // Always close the controls when resizing
    dispatch({ type: 'setShowControls', showControls: false })
  }, [isSmallScreen])

  if (availInputs.length === 0) {
    return (
      <ContentContainer title="Influent Data">
        No influent tags
      </ContentContainer>
    )
  }

  const selectTimeIndex = (index: number) =>
    dispatch({ type: 'selectTimeIndex', index })
  const selectInputs = (inputs: Input[]) =>
    dispatch({ type: 'selectInputs', inputs })

  const toggleControls = () =>
    dispatch({
      type: 'setShowControls',
      showControls: !showControls,
    })

  const controls = (
    <>
      <CardContent className={classes.controls}>
        <AllInputs
          availInputs={availInputs}
          selectedInputs={selectedInputs}
          updateSelectedInputs={selectInputs}
        />
        <ChartRangeSelector
          availTimes={TIME_RANGES}
          selectedTimeIndex={selectedTimeIndex}
          updateSelectedTimeIndex={selectTimeIndex}
        />
      </CardContent>
    </>
  )

  // Style the drawers to keep them visible
  const drawerStyle: React.CSSProperties = {
    top: contentRef.current?.getBoundingClientRect()?.top ?? 0,
    bottom: 0,
    height: 'auto',
  }

  return (
    <Container className={classes.container} ref={contentRef}>
      <Card className={classes.controlCard}>
        <CardHeader
          title="Influent Data"
          action={
            <Hidden smUp>
              <Button
                onClick={toggleControls}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<ChevronLeftIcon />}
              >
                Controls
              </Button>
            </Hidden>
          }
        />
        <Hidden xsDown>{controls}</Hidden>
      </Card>
      {/* Controls drawer */}
      <Hidden smUp>
        <Drawer
          anchor="right"
          open={showControls}
          onClose={toggleControls}
          ModalProps={{
            keepMounted: true,
            container: contentRef.current,
          }}
          PaperProps={{ style: drawerStyle }}
        >
          <Paper className={classes.controlsDrawer}>
            <Button
              onClick={toggleControls}
              variant="outlined"
              size="small"
              color="primary"
              className={classes.hideDrawerButton}
              endIcon={<ChevronRightIcon />}
            >
              Hide
            </Button>
            {controls}
          </Paper>
        </Drawer>
      </Hidden>
      <Paper className={classes.chart}>
        {fetchedLogs ? (
          <CommonChart
            title="Live Data"
            rows={fetchedLogs.rows}
            tags={fetchedLogs.inputs as ChartableTag[]}
            startTime={startTime}
            endTime={endTime}
            chartView={ChartView.AllTogether}
            showDots={true}
            localPrefix="influent"
            maxAxes={2}
          />
        ) : (
          <LoadPage />
        )}
      </Paper>
    </Container>
  )
}

export default InfluentChart
