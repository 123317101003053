import { Card } from '@material-ui/core'

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'

import cx from 'classnames'

import { findHighlights, TagCardProps } from './shared'

import TagGauge from './TagGauge'
import TagMessage from './TagMessage'
import TagTotal from './TagTotal'

const gauge_width = 200

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tagCard: {
        // Center children
        textAlign: 'center',
        // Be consistent with the width
        width: gauge_width + 2 * theme.spacing(1) + 'px',

        // Use flex to get consistent layout
        display: 'flex',
        flexDirection: 'column',
        // Add some spacing between rows of card when wrapping
        marginTop: theme.spacing(1),
        // keep cards from getting too close together in small windows
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // give it more z-height
        boxShadow: theme.shadows[2],
        // give it some pop from background:
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        // Tighten the layout
        '& > *': {
          padding: theme.spacing(1),
        },
        '& .MuiCardHeader-root': {
          // White on blue
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          // Fixed size header
          // Allow for two lines of text
          flex: '0 0 70px',
        },
        '&.warning .MuiCardHeader-root': {
          background: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        },
        '&.warning': {
          borderColor: theme.palette.warning.main,
        },
        '&.error .MuiCardHeader-root': {
          background: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
        '&.error': {
          borderColor: theme.palette.error.main,
        },
        '& .MuiCardContent-root': {
          // Let the gauge fill the card
          flex: '1 1',
        },
        '& .MuiCardActions-root': {
          // Add a divider about the footer
          borderTop: `1px solid ${theme.palette.divider}`,
          // Override the flex display in the actions, to honor text-align
          display: 'block',
          // Fixed size footer
          flex: '0 0',
        },
      },
    }),
  { name: 'TagCard' }
)

const TagCard: React.FC<TagCardProps> = (props: TagCardProps) => {
  const { perfTag, demo } = props

  const theme = useTheme()

  // Get the highlights and gauge style
  const highlights = findHighlights(perfTag, theme, demo)

  const classes = useStyles()

  const type = perfTag.tag.type

  return (
    <Card classes={{ root: cx(classes.tagCard, highlights.className) }}>
      {type === 'total' && <TagTotal {...props} />}
      {type === 'number' && <TagGauge {...props} highlights={highlights} />}
      {type === 'message' && <TagMessage {...props} />}
    </Card>
  )
}

export default TagCard
