import { MenuItem, SelectProps } from '@material-ui/core'

import Picker from '../common/Picker'
import { ChartMode } from './shared'

type Props = {
  mode: 'chart' | 'csv'
  onChange: (mode: ChartMode) => void
} & Omit<SelectProps, 'onChange'>

const ModePicker: React.FC<Props> = (props: Props) => {
  const { mode, onChange, ...selectProps } = props

  return (
    <Picker
      label="Mode"
      value={mode}
      onChange={(event) => onChange(event.target.value as ChartMode)}
      {...selectProps}
    >
      <MenuItem value="chart">Chart</MenuItem>
      <MenuItem value="csv">CSV</MenuItem>
    </Picker>
  )
}

export default ModePicker
