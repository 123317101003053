import React from 'react'

import Button from '@material-ui/core/Button'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      buttons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
      },
    }),
  { name: 'ChartRangeSelector' }
)

type Props = {
  /** Labels for the time range buttons */
  availTimes: {
    label: string
  }[]
  /** The currently selected button */
  selectedTimeIndex: number | null
  /** Callback fired when the user clicks one of the buttons */
  updateSelectedTimeIndex: (index: number) => void
}

/**
 * Chart time range buttons (6H, 12H, etc)
 */
const ChartRangeSelector: React.FC<Props> = (props: Props) => {
  const { availTimes, selectedTimeIndex, updateSelectedTimeIndex } = props

  const classes = useStyles()

  return (
    <div className={classes.buttons}>
      {availTimes.map((option, i) => (
        <Button
          key={i}
          variant="contained"
          color={selectedTimeIndex === i ? 'primary' : 'default'}
          onClick={() => {
            updateSelectedTimeIndex(i)
          }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  )
}

export default ChartRangeSelector
