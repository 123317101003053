import {
  VictoryChart,
  VictoryChartProps,
  VictoryLabel,
  VictoryAreaProps,
  VictoryAxis,
} from 'victory'

import { Lot } from '../../lib/types'
import {
  NullComponent,
  VictoryLayoutProps,
  CHART_PADDING,
  Y_AXIS_LABELED_WIDTH,
} from './shared'

export const LOTS_HEIGHT = 24

type Props = {
  lots: Lot[] | null
} & VictoryChartProps &
  VictoryLayoutProps

/**
 * Display of Lot time periods
 */
const LotPeriods: React.FC<Props> = (props: Props) => {
  const { domain, scale } = props

  if (!props.lots) {
    return null
  }

  const [xMin, xMax] = domain!.x // eslint-disable-line @typescript-eslint/no-non-null-assertion

  // Prepare lots and filter to those that will appear on the chart
  const lots = props.lots
    .map((lot) => ({
      ...lot,
      startTime: new Date(lot.start_utc),
      endTime: new Date(lot.end_utc),
    }))
    .filter((lot) => lot.startTime < xMax && lot.endTime > xMin)

  return (
    <VictoryChart
      {...props}
      defaultAxes={{
        dependent: <NullComponent />,
        independent: <NullComponent />,
      }}
      padding={{
        ...CHART_PADDING,
        top: 0,
        bottom: 0,
        left: 4 * Y_AXIS_LABELED_WIDTH,
      }}
      scale={{
        x: 'time',
        y: 'linear',
      }}
      style={{
        parent: {
          height: LOTS_HEIGHT,
        },
      }}
    >
      <VictoryAxis
        tickCount={1}
        tickValues={[0.5]}
        tickFormat={['Lot:']}
        offsetX={4 * Y_AXIS_LABELED_WIDTH}
        axisComponent={<NullComponent />}
        dependentAxis
      />
      {lots.map((lot) => (
        <LotArea key={lot.lot_id} lot={lot} domain={domain} scale={scale} />
      ))}
    </VictoryChart>
  )
}

type LotAreaProps = {
  lot: Lot
} & VictoryAreaProps &
  VictoryLayoutProps

/** Area and label for a single Lot */
const LotArea = (props: LotAreaProps) => {
  const { lot, ...areaProps } = props
  const { domain, scale } = areaProps

  const [xMin, xMax] = domain!.x // eslint-disable-line @typescript-eslint/no-non-null-assertion

  // Find the area start/end
  const xStart = Math.max(scale.x(lot.startTime), scale.x(xMin))
  const xEnd = Math.min(scale.x(lot.endTime), scale.x(xMax))

  const width = xEnd - xStart

  // Determine how to position the label
  const labelProps = calcLabelProps(lot, xMin, xMax, scale)

  return (
    <g>
      <rect
        x={xStart}
        y={0}
        width={width}
        height={LOTS_HEIGHT}
        style={{
          fill: lot.color,
          fillOpacity: 0.2,
          stroke: lot.color,
          strokeWidth: 1,
        }}
      />
      <VictoryLabel
        {...labelProps}
        y={LOTS_HEIGHT / 2}
        text={lot.lot_id}
        verticalAnchor="middle"
        style={{
          fontSize: 14,
          fontFamily: 'Roboto',
          fontWeight: 700,
        }}
      />
    </g>
  )
}

/**
 * Calculate the label position and alignment properties.
 */
const calcLabelProps = (lot: Lot, xMin: Date, xMax: Date, scale: any): any => {
  if (lot.startTime < xMin && lot.endTime < xMax) {
    // Hanging off the left side
    return {
      x: scale.x(xMin) + 8,
      textAnchor: 'start',
    }
  } else if (lot.endTime > xMax && lot.startTime > xMin) {
    // Hanging off the right side
    return {
      x: scale.x(xMax) - 8,
      textAnchor: 'end',
    }
  } else {
    // In the middle, or hanging off both sides
    return {
      x: (scale.x(lot.startTime) + scale.x(lot.endTime)) / 2,
      textAnchor: 'middle',
    }
  }
}

export default LotPeriods
