import { MenuItem, Select } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useIsAdminPath } from '../../lib/hooks'
import {
  useAppDispatch,
  useAppSelector,
  selectSystem,
  AppDispatch,
} from '../../lib/redux'
import { byString } from '../../lib/sortItems'
import { Tenant } from '../../lib/types'
import { useApp, WithAppProps } from '../../providers'

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      single: {
        color: 'inherit',
      },
      select: {
        color: 'inherit',
        '& .MuiSelect-icon': {
          color: 'inherit',
        },
      },
    }),
  { name: 'SystemSelector' }
)

type SystemOption = {
  key: string
  tenant: Tenant
  system_id: string
  system_name: string
}

// Comparators
const BY_TENANT_NAME = byString('name')
const BY_SYSTEM_NAME = byString('system_name')

/** Comparator to sort by tenant name then system name */
const BY_NAMES = (a: SystemOption, b: SystemOption) =>
  BY_TENANT_NAME(a.tenant, b.tenant) || BY_SYSTEM_NAME(a, b)

/**
 * Tenant/System selection component
 */
const SystemSelector: React.FC = () => {
  const app = useApp()
  const classes = useStyles()
  const isAdminPath = useIsAdminPath()

  const tenants = useAppSelector((state) => state.tenants)
  const tenant = useAppSelector((state) => state.tenant)
  const system = useAppSelector((state) => state.system)
  const dispatch = useAppDispatch()

  if (isAdminPath) {
    // Don't show the selector on the admin pages
    return null
  }

  // Organize the systems into options
  const options: SystemOption[] =
    tenants
      ?.map((tenant) =>
        Object.entries(tenant.system_names).map(([system_id, system_name]) => ({
          key: `${tenant.tenant_id}:${system_id}`,
          tenant,
          system_id,
          system_name,
        }))
      )
      .flat()
      .sort(BY_NAMES) ?? []

  // Figure out which option is currently selected
  const currentKey = `${tenant?.tenant_id}:${system?.system_id}`
  const selected = options.find((opt) => opt.key === currentKey)

  if (selected && options.length === 1) {
    // Only one option available - display as text
    return (
      <div className={classes.single}>
        {selected?.tenant.name} » {selected?.system_name}
      </div>
    )
  }

  const value = selected?.key ?? ''
  return (
    <Select value={value} className={classes.select}>
      {value === '' && (
        <MenuItem value="" disabled>
          -- Select system --
        </MenuItem>
      )}
      {options.map((opt) => (
        <MenuItem
          key={opt.key}
          value={opt.key}
          onClick={() => changeSystem(app, dispatch, opt)}
        >
          {opt.tenant.name} » {opt.system_name}
        </MenuItem>
      ))}
    </Select>
  )
}

const changeSystem = (
  app: WithAppProps,
  dispatch: AppDispatch,
  opt: SystemOption
) => {
  app.asBusy(async () => {
    const system = await app.api.getSystem(opt.tenant.tenant_id, opt.system_id)
    if (system) {
      dispatch(selectSystem(opt.tenant, system))
    }
  })
}

export default SystemSelector
