import { MenuItem, SelectProps } from '@material-ui/core'

import Picker from '../common/Picker'
import { ChartView } from './shared'

type Props = {
  chartView: ChartView
  onChange: (chartView: ChartView) => void
} & Omit<SelectProps, 'onChange'>

const ChartViewPicker: React.FC<Props> = (props: Props) => {
  const { chartView, onChange, ...selectProps } = props

  return (
    <Picker
      label="Chart View"
      value={chartView}
      onChange={(event) => onChange(event.target.value as ChartView)}
      {...selectProps}
    >
      <MenuItem value="per-tag">Per-Tag</MenuItem>
      <MenuItem value="per-unit">Per-Unit</MenuItem>
      <MenuItem value="all-together">All Together</MenuItem>
    </Picker>
  )
}

export default ChartViewPicker
