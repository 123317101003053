import { useTheme } from '@material-ui/core/styles'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LinearGauge } from 'react-canvas-gauges'

import {
  GAUGE_WIDTH,
  Highlights,
  TagCardProps,
  findNeedleColor,
  findTicks,
} from './shared'

type Props = {
  highlights: Highlights
} & TagCardProps

/**
 * Display a tag value as a linear gauge
 */
const TagLinearGauge: React.FC<Props> = (props: Props) => {
  const { perfTag, demo, highlights } = props

  const theme = useTheme()

  const { tag, value } = perfTag

  const ticks = findTicks(perfTag)
  const needleColor = findNeedleColor(theme, demo)

  return (
    <LinearGauge
      units={demo.linear_units ? tag.units : false}
      value={value}
      minValue={tag.min_value}
      maxValue={tag.max_value}
      majorTicks={ticks}
      highlights={highlights.ranges}
      width={GAUGE_WIDTH}
      height={GAUGE_WIDTH / 2}
      tickSide={demo.linear_ticks}
      numberSide={demo.linear_ticks}
      needle={demo.linear_needle !== 'off'}
      needleSide={demo.linear_needle}
      barBeginCircle={0}
      barWidth={demo.bar_width}
      borders={false}
      borderShadowWidth={0}
      colorNeedle={needleColor}
      colorNeedleEnd={needleColor}
      colorBarProgress={theme.palette.primary.main}
      needleWidth={demo.needle_width}
      needleType="line"
      valueBox={false}
      animation={true}
      animationRule="quad"
      animateOnInit={true}
      animationDuration={1000}
      animationTarget="needle"
    />
  )
}

export default TagLinearGauge
