import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Menu, MenuItem, Divider } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { Auth } from '@aws-amplify/auth'

import { useAwsUserRole, useIsAdminPath } from '../../lib/hooks'
import { useAppSelector } from '../../lib/redux'
import { U } from '../../lib/utils'
import SystemSelector from './SystemSelector'

type NavItem = {
  path: string
  label: string
  hide?: boolean
}

/**
 * Compact menu intended to replace the navigation for small displays (phones)
 */
const CompactNav: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const tenant = useAppSelector((state) => state.tenant)
  const system = useAppSelector((state) => state.system)
  const isAdminPath = useIsAdminPath()
  const awsUserRole = useAwsUserRole()

  // Close the menu when the system is changed
  useEffect(() => setOpen(false), [system])

  if (!tenant || !system) {
    // Don't show if the system is not yet selected
    return null
  }

  const isAdmin = awsUserRole === 'admin'
  const isPremium = awsUserRole !== 'customer'

  const navigator = (path: string) => (event: React.UIEvent) => {
    // Don't follow the link, etc.
    event.preventDefault()

    // Close all the open menus
    setOpen(false)

    // Go to the new page
    history.push(path)
  }

  const hmiItems = system.hmis?.map((hmi) => ({
    path: `/hmi/view/${U(hmi.name)}`,
    label: `HMI: ${hmi.name}`,
    hide: !isPremium,
  }))

  // User facing navigation links from StandardNav
  const navItems: NavItem[] = [
    { path: '/', label: 'Overview' },
    { path: '/livedata', label: 'Live Data' },
    { path: '/documents', label: 'Documents' },
    { path: '/hmi/alarms', label: 'HMI » Alarm History', hide: !isPremium },
    {
      path: '/hmi/lots',
      label: 'HMI » Lot History',
      hide: !isPremium || !system.show_lots,
    },
    {
      path: '/hmi/tests',
      label: 'HMI » System Tests',
      hide: !isPremium || !system.show_tests,
    },
    ...(hmiItems ?? []),
    {
      path: '/influent/chart',
      label: 'Influent » Chart',
      hide: !isPremium && !system.inputs,
    },
    {
      path: '/influent/logs',
      label: 'Influent » Logs',
      hide: !isPremium && !system.inputs,
    },
    {
      path: '/influent/enter',
      label: 'Influent » Enter',
      hide: !isPremium && !system.inputs,
    },
    { path: '/assist', label: 'Assist' },
    { path: '/parts/membrane', label: 'Parts » Installed Membranes' },
    { path: '/parts/order', label: 'Parts » Order Parts' },
    { path: '/orderhistory', label: 'Order History' },
  ]

  // Common top-level navigation items from AppNav
  const appItems: NavItem[] = [
    { path: '/problem', label: 'Report a Problem' },
    { path: '/contact', label: 'Contact Us' },
  ]

  // Admin facing navigation links
  const adminItems: NavItem[] = []
  if (isAdmin) {
    if (isAdminPath) {
      adminItems.push({
        path: '/',
        label: 'Standard View',
      })
    } else {
      adminItems.push({
        path: '/admin',
        label: 'Customer Administration',
      })
    }
  }

  return (
    <div ref={ref}>
      <div onClick={() => setOpen(true)}>
        <MenuIcon htmlColor="white" />
      </div>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!isAdminPath && (
          <>
            <MenuItem>
              <SystemSelector />
            </MenuItem>
            {navItems
              .filter((item) => !item.hide)
              .map((item) => (
                <MenuItem key={item.path} onClick={navigator(item.path)}>
                  {item.label}
                </MenuItem>
              ))}
            <Divider />
          </>
        )}
        {isAdmin && (
          <>
            {adminItems.map((item) => (
              <MenuItem key={item.path} onClick={navigator(item.path)}>
                {item.label}
              </MenuItem>
            ))}
            <Divider />
          </>
        )}
        {appItems
          .filter((item) => !item.hide)
          .map((item) => (
            <MenuItem key={item.path} onClick={navigator(item.path)}>
              {item.label}
            </MenuItem>
          ))}
        <MenuItem
          onClick={(event) => {
            event.preventDefault()
            Auth.signOut()
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </div>
  )
}

export default CompactNav
