import { useRef } from 'react'

import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@material-ui/core'
import UploadIcon from '@material-ui/icons/Publish'

import { FormikValues, useFormikContext } from 'formik'

import { FileUpload } from '../../lib/types'
import { useApp } from '../../providers'

type Props = {
  /** Formik field name */
  name: string
  /** Callback trigger when a file is selected and should be uploaded */
  onUpload: (file: File) => Promise<FileUpload | null>
} & TextFieldProps

/** Parse the filename out of the path */
const parseFilename = (path: string | null) => path?.split('/')?.pop() ?? ''

/**
 * Formik input for selecting and uploading a file to S3 - sets the URL as the value.
 */
const FileUploadInput: React.FC<Props> = (props: Props) => {
  const { name, onUpload, ...textFieldProps } = props
  const { asBusy } = useApp()

  // The parent Formik context
  const f = useFormikContext<FormikValues>()

  // Reference to the file <input>
  const uploadRef = useRef<HTMLInputElement>(null)

  // The current value - if any
  const value = parseFilename(f.getFieldMeta(name).value as string)

  return (
    <>
      <TextField
        value={value}
        {...textFieldProps}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Upload file">
                <IconButton onClick={() => uploadRef.current?.click()}>
                  <UploadIcon />
                </IconButton>
              </Tooltip>
              <input
                ref={uploadRef}
                type="file"
                style={{ display: 'none' }}
                onChange={async (event: any) => {
                  // Get the selected file
                  const file = event.target?.files?.[0]
                  if (!file) {
                    // Perhaps the user cancelled?
                    return
                  }

                  await asBusy(async () => {
                    // Ask the caller to upload the file
                    const upload = await onUpload(file)
                    if (upload) {
                      // Update the value in the form
                      f.setFieldValue(name, upload.s3_url)
                      f.setFieldTouched(name)
                    }
                  })
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default FileUploadInput
