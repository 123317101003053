import { useState } from 'react'

import {
  CardContent,
  Card,
  CardHeader,
  Grid,
  IconButton,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { AlarmLevel, SubsystemPerformance } from '../../lib/types'
import StatusIcon, { StatusOption } from '../common/StatusIcon'

import { DEMO_DEFAULTS, DemoOptions } from './shared'
import DemoMenu from './DemoMenu'
import SystemAlarms from './SystemAlarms'
import TagCard from './TagCard'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      subsystemCard: {
        // Tighten the layout
        marginTop: theme.spacing(2),
        '& > *': {
          padding: theme.spacing(1),
        },
        '& > .MuiCardContent-root': {
          // Rely on child margin-top (see tagCard)
          paddingTop: 0,
        },
      },
    }),
  { name: 'Subsystem' }
)

type Props = {
  subsystem: SubsystemPerformance
}

/**
 * Row of Subsystem gauges
 */
const Subsystem: React.FC<Props> = (props: Props) => {
  const { subsystem } = props
  const classes = useStyles()

  const [showDetail, setShowDetail] = useState<boolean>(true)
  const [demo, setDemo] = useState<DemoOptions>(DEMO_DEFAULTS)

  if (!subsystem) {
    return null
  }

  const alarmedTags = (subsystem.alarms || []).reduce((map, alarm) => {
    map[alarm.tag.tag_id] = alarm.level
    return map
  }, {} as Record<string, AlarmLevel>)

  const alarms = subsystem.alarms || []

  let status: StatusOption
  if (alarms.length === 0) {
    status = 'ok'
  } else if (subsystem.alarmLevel === 0) {
    status = 'warn'
  } else {
    status = 'error'
  }

  // Sort the tags
  const values = subsystem.values || []
  values.sort((a, b) => (a.tag.sort || 0) - (b.tag.sort || 0))

  return (
    <Card
      key={subsystem.subsystem_id}
      classes={{ root: classes.subsystemCard }}
    >
      <CardHeader
        avatar={<StatusIcon status={status} />}
        title={subsystem.name}
        action={
          <>
            <DemoMenu demo={demo} setDemo={setDemo} />
            <IconButton onClick={() => setShowDetail(!showDetail)}>
              {showDetail ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          </>
        }
        titleTypographyProps={{ variant: 'h6' }}
      />
      {showDetail && (
        <CardContent>
          <SystemAlarms alarms={alarms} />
          <Grid container direction="row" justifyContent="space-evenly">
            {values.map((perfTag) => (
              <TagCard
                key={perfTag.tag.tag_id}
                perfTag={perfTag}
                demo={demo}
                alarmLevel={alarmedTags[perfTag.tag.tag_id] ?? null}
              />
            ))}
          </Grid>
        </CardContent>
      )}
    </Card>
  )
}

export default Subsystem
