import { hasValue } from '../../lib/utils'

/**
 * Common Victoty layout properties
 */
export type VictoryLayoutProps = {
  /** Victory chart domain (data ranges) */
  domain?: {
    x: [Date, Date]
    y: [number, number]
  }
  /** Victory chart scale (data to pixels) */
  scale?: any
}

export type ChartableTag = {
  name: string
  type: 'number' | 'datetime'
  units: string
  unitsLabel: string
  min_value?: number
  max_value?: number
  color: string
}
export type MinMax = {
  min: number
  max: number
}

export type ChartMode = 'chart' | 'csv'
export type Resolution = '1M' | '5M' | '15M' | '1H' | 'auto' | 'raw'

export type RefreshRate = '1M' | '5M' | '15M' | '1H' | 'None'

export enum ChartView {
  PerTag = 'per-tag',
  PerUnit = 'per-unit',
  AllTogether = 'all-together',
}

export type AbsoluteTimeRange = {
  startDate: Date
  endDate: Date
}

export type RelativeTimeSpan = 'hours' | 'days' | 'weeks'

export type RelativeTimeRange = {
  count: number
  span: RelativeTimeSpan
}
export type TimeRange = {
  absolute?: AbsoluteTimeRange
  relative?: RelativeTimeRange
}

/** Colors for the data lines */
export const LINE_COLORS = [
  // '#8884d8',
  // '#82ca9d',
  // '#FFD700',
  // '#0093D0',
  // 'rgb(  0,  0,  0)',
  'rgb(230,159,  0)', // orange
  'rgb( 86,180,233)', // light blue
  'rgb(  0,158,115)', // green
  // 'rgb(240,228, 66)', // light yellow
  'rgb(  0,114,178)', // blue
  'rgb(213, 94,  0)', // dark orange
  'rgb(204,121,167)', // lavender
]

/** Colors for the Lot markers */
export const LOT_COLORS = ['#FFD7003F', '#0093D03F', '#8884d83F', '#82ca9d3F']

/** Height of the X Axis, in pixels */
export const X_AXIS_HEIGHT = 20
/** Width of the Y Axis, in pixels */
export const Y_AXIS_WIDTH = 50
/** Width of the Y Axis with a label, in pixels */
export const Y_AXIS_LABELED_WIDTH = 65
/** Opacity for lines/labels that not for the focused tag */
export const OPACITY_UNFOCUSED = 0.2

/** Common chart padding */
export const CHART_PADDING = {
  left: Y_AXIS_WIDTH,
  right: 0,
  top: 8, // For the top tick label
  bottom: X_AXIS_HEIGHT,
}

/**
 * Find the min/max values for graphing the tag data.
 *
 * Checks both the actual data and the declared limits of the tag.
 *
 * @param rows all graph data
 * @param tag the tag definition
 * @param tagIndex column index of the tag data in the rows
 * @param forceMinMax always use the tag's configured min/max values
 * @returns min/max that span both the actual values and declared limits
 */
export const findMinMax = (
  rows: any[],
  tag: ChartableTag,
  tagIndex: number,
  forceMinMax = false
): MinMax => {
  if (forceMinMax && hasValue(tag.min_value) && hasValue(tag.max_value)) {
    return { min: tag.min_value, max: tag.max_value }
  }

  // Find the actual min/max
  let [yMin, yMax] = rows.reduce(
    ([min, max], row) => {
      const value = row[tagIndex] as number | null
      return value === null
        ? [min, max]
        : [Math.min(min, value), Math.max(max, value)]
    },
    [Infinity, -Infinity]
  )

  // Adjust min/max for the declared limits
  if (hasValue(tag.min_value)) {
    yMin = forceMinMax ? tag.min_value : Math.min(yMin, tag.min_value)
  }
  if (hasValue(tag.max_value)) {
    yMax = forceMinMax ? tag.max_value : Math.max(yMax, tag.max_value)
  }

  return { min: yMin, max: yMax }
}

/**
 * Get the color for the tag.
 *
 * @param tagIndex index of tag in graph data
 * @returns tag's color string
 */
export const tagColor = (tagIndex: number): string =>
  LINE_COLORS[tagIndex % LINE_COLORS.length]

/**
 * Generic null component used in Victory config
 */
export const NullComponent: React.FC = () => null
