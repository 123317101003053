import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { AwsUser, AwsUserRole } from './types'

/**
 * Hook to measure the dimensions of an HTML element and allow for layout calculations.
 *
 * For example:
 *
 *   const [ref, dim] = useDimensions()
 *   const width = dim?.width ?? 600
 *   return (
 *     <div ref={ref}><Child width={width} /></div>
 *   )
 *
 * From: https://github.com/Swizec/useDimensions/issues/36#issuecomment-676104038
 */
export function useDimensions<T extends HTMLElement>(): [
  MutableRefObject<any>,
  DOMRectReadOnly | null
] {
  const ref = useRef<T>(null)
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null)

  const resizeObserver = new ResizeObserver(([entry]) =>
    setDimensions(entry.contentRect)
  )

  useEffect(() => {
    const current = ref.current // enclose current so the reference can be used in the unsubscribe
    if (current) {
      resizeObserver.observe(current)
      return () => resizeObserver.unobserve(current)
    }

    return undefined
  }, [ref.current])

  return [ref, dimensions]
}

/**
 * Hook to check if the current path is part of the Admin UI (/admin/)
 * @returns true if the path starts with /admin/; false otherwise
 */
export const useIsAdminPath = (): boolean => {
  const location = useLocation()
  return location.pathname.startsWith('/admin')
}

/**
 * Hook to check if the screen is considered small (actually 'xs') and is
 * therefore likely a mobile display.
 *
 * @returns true if the screen is at or below the 'xs' breakpoint; false otherwise
 */
export const useIsSmallScreen = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('xs'))
}

/**
 * Hook to provide the current logged in AWS user
 *
 * @returns logged in AWS user, or null
 */
export const useAwsUser = (): AwsUser | null => {
  const { user } = useAuthenticator((context) => [context.user])

  if (!user) {
    return null
  }

  // Force the typing to something more usable
  // The CognitoUserAmplify typing does not appear to be exported
  return user as unknown as AwsUser
}

/**
 * Hook to provide the role of the current logged in AWS user
 *
 * @returns role of AWS user, or 'none' if not logged in
 */
export const useAwsUserRole = (): AwsUserRole => {
  const user = useAwsUser()

  if (!user) {
    return 'none'
  }

  return user.attributes['custom:role'] as AwsUserRole
}

/**
 * Hook to check if the current logged-in AWS user has an admin role
 *
 * @returns true if the user has an admin role; false otherwise
 */
export const useIsAdmin = (): boolean => {
  const awsUserRole = useAwsUserRole()

  return awsUserRole === 'admin'
}
