import {
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'

import formatDecimal from '../../lib/formatDecimal'

import { Highlights, TagCardProps } from './shared'
import TagLinearGauge from './TagLinearGauge'
import TagRadialGauge from './TagRadialGauge'

type Props = {
  highlights: Highlights
} & TagCardProps

/**
 * Display a tag value as a gauge
 */
const TagGauge: React.FC<Props> = (props: Props) => {
  const { perfTag, demo } = props

  const { tag, value, average } = perfTag

  return (
    <>
      <CardHeader title={tag.name} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <Typography variant="h6">
          {formatDecimal(value, 1)} {tag.units}
        </Typography>
        {demo.gauge_type === 'linear' ? (
          <TagLinearGauge {...props} />
        ) : (
          <TagRadialGauge {...props} />
        )}
      </CardContent>
      <CardActions>24 Hr. Avg: {formatDecimal(average, 1)}</CardActions>
    </>
  )
}

export default TagGauge
