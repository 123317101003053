import React from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Hidden,
  Drawer,
} from '@material-ui/core'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FullscreenIcon from '@material-ui/icons/Fullscreen'

import cx from 'classnames'
import { sub } from 'date-fns'
import fscreen from 'fscreen'

import { downloadCsv } from '../../lib/csv'
import { parseDate } from '../../lib/dates'
import formatUTC from '../../lib/formatUTC'
import { Lot, Tag } from '../../lib/types'
import { loadLocal, saveLocal } from '../../lib/utils'
import {
  withApp,
  WithAppProps,
  withSystem,
  WithSystemProps,
} from '../../providers'

import {
  ChartableTag,
  ChartMode,
  ChartView,
  Resolution,
  RefreshRate,
  TimeRange,
  tagColor,
} from '../charts/shared'
import ChartViewPicker from '../charts/ChartViewPicker'
import CommonChart from '../charts/CommonChart'
import LotPicker from '../charts/LotPicker'
import ModePicker from '../charts/ModePicker'
import RefreshPicker from '../charts/RefreshPicker'
import ResolutionPicker from '../charts/ResolutionPicker'
import TimeBrush from '../charts/TimeBrush'
import TimeRangePicker from '../charts/TimeRangePicker'
import AllTags from './AllTags'

const DEFAULT_TIME_RANGE: TimeRange = {
  relative: {
    count: 6,
    span: 'hours',
  },
}

const REFRESH_TIME_MS = {
  '1M': 1 * 60 * 1000,
  '5M': 5 * 60 * 1000,
  '15M': 15 * 60 * 1000,
  '1H': 1 * 60 * 60 * 1000,
  None: -1,
}

// How far back to look for "recent" lots
const RECENT_LOT_DAYS = 365

// How long to wait between UI events before fetching new data
const FETCH_DEBOUNCE_MS = 500

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
    paper: {
      padding: theme.spacing(2),
    },
    tagsPaper: {
      flex: '0 1 15%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    hideTagsButton: {
      margin: theme.spacing(1),
    },
    tags: {
      flex: '1 1 100%',
      overflow: 'hidden auto',
    },
    content: {
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      gap: theme.spacing(1),
      overflow: 'hidden',
    },
    header: {
      flex: '0 0 auto',
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
    },
    controlsDrawer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      height: '100%',
      minWidth: '50vw',
    },
    brush: {
      marginTop: theme.spacing(1),
    },
    chart: {
      flex: '1 1 100%',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    fetching: {
      width: theme.spacing(4),
    },
    buttonProgress: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWrapper: {
      position: 'relative' as const,
    },
  })

/**
 * Sort tags first by units then name.
 */
const sortTags = (a: Tag, b: Tag) => {
  const rv =
    (a.units || '').localeCompare(b.units || '') || a.name.localeCompare(b.name)

  return rv
}

type Row = any[]

type TrendingDataResponse = {
  rows: Row[]
  tags: Tag[]
}

type LocationState = {
  lot?: Lot
}

type Props = RouteComponentProps<
  Record<string, string | undefined>, // deafult
  StaticContext, // default
  LocationState
> &
  WithStyles<typeof styles> &
  WithAppProps &
  WithSystemProps

type State = {
  mode: ChartMode
  chartView: ChartView

  availTags: Tag[]

  // Recent Lots
  lots: Lot[] | null
  // Lot selected in the Time range UI
  selectedLot: string

  selectedTags: Tag[]
  showTags: boolean
  showControls: boolean

  fetchedData: TrendingDataResponse | null
  fetching: boolean

  start_time: Date
  end_time: Date

  brushStart: Date
  brushEnd: Date

  // form input values for custom time range
  customStart: string
  customEnd: string

  resolution: Resolution
  // is the CSV download in progress? used for busy indicator.
  downloadingCsv: boolean

  timeRange: TimeRange
  refreshRate: RefreshRate
}

/**
 * Live Data Graph
 *
 * Display live data for selected tags and time range.
 */
class LiveDataView extends React.Component<Props, State> {
  // Incrementing counter to track data requests
  fetchCount = 0

  fetchDebounce: number | undefined

  refreshInterval: number | undefined

  contentRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)

    // References to child elements
    this.contentRef = React.createRef()

    const now = new Date()

    const endTime = now
    const startTime = sub(now, { hours: 6 })

    // set initial component state
    this.state = {
      mode: 'chart',
      availTags: [],
      selectedTags: [],
      showTags: false,
      showControls: false,
      fetchedData: null,
      fetching: false,
      lots: null,
      selectedLot: 'None',

      // these will get set on componentDidMount() anyway, but we set
      // them here as well so that we can avoid using null or undefined
      // in their types. i.e. so we can use `Date` instead of e.g. `Date | null`.
      start_time: startTime,
      end_time: endTime,

      brushStart: startTime,
      brushEnd: endTime,

      customStart: '',
      customEnd: '',

      resolution: 'auto',
      downloadingCsv: false,

      chartView: loadLocal('live_data.chartView', ChartView.AllTogether),

      timeRange: {
        relative: {
          span: 'hours',
          count: 6,
        },
      },
      refreshRate: loadLocal('live_data.refreshRate', 'None'),
    }
  }

  /**
   * Initialize the component
   */
  componentDidMount() {
    // Extract all the tags
    this.findTags().then(() => {
      if (this.props.location.state?.lot) {
        // Zoom to the selected lot
        const lot = this.props.location.state?.lot
        this.showLot(lot)
      } else {
        // Fetch the default data
        this.updateTimeRange(DEFAULT_TIME_RANGE)
      }
    })

    // Start refreshing
    this.applyRefreshRate()

    // Fetch the recent Lots
    this.fetchRecentLots()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.system.system_id !== prevProps.system.system_id) {
      this.setState(
        {
          // Clear the existing graph info
          availTags: [],
          selectedTags: [],
          showTags: false,
          fetchedData: null,
          lots: null,
          selectedLot: 'None',
        },
        () => {
          // Extract all the tags
          this.findTags().then(() => {
            // Fetch data for the new subsystem
            this.fetchData()
          })
        }
      )
    }
  }

  componentWillUnmount() {
    this.clearFetchDebounce()
    this.clearRefreshInterval()
  }

  /**
   * Render the component
   */
  render() {
    const { classes, system, withCatch } = this.props
    const {
      availTags,
      selectedTags,
      showTags,
      showControls,
      fetchedData,
      lots,
      start_time,
      end_time,
      brushStart,
      brushEnd,
      downloadingCsv,
      chartView,
      fetching,
      timeRange,
    } = this.state

    // Are the start/end dates invalid? (possible when using 'Custom'.)
    const startTimestamp = start_time.getTime()
    const endTimestamp = end_time.getTime()
    const invalidDates =
      isNaN(startTimestamp) ||
      isNaN(endTimestamp) ||
      startTimestamp > endTimestamp

    const controls = (
      <>
        <TimeRangePicker
          timeRange={timeRange}
          onChange={this.updateTimeRange}
          startTime={start_time}
          endTime={end_time}
        />
        <RefreshPicker
          refreshRate={this.state.refreshRate}
          onChange={this.onChangeRefreshRate}
          refresh={this.fetchDataWithDebounce}
          timeRange={timeRange}
        />
        <ResolutionPicker
          resolution={this.state.resolution}
          onChange={this.onChangeResolution}
        />
        <LotPicker
          system={system}
          lots={this.state.lots}
          selected={this.state.selectedLot}
          onChange={this.selectLot}
        />
        <ModePicker mode={this.state.mode} onChange={this.onChangeMode} />
        <ChartViewPicker
          chartView={this.state.chartView}
          onChange={this.onChangeChartView}
          disabled={this.state.mode !== 'chart'}
        />
      </>
    )

    // Style the drawers to keep them visible
    const drawerStyle: React.CSSProperties = {
      top: this.contentRef.current?.getBoundingClientRect()?.top ?? 0,
      bottom: 0,
      height: 'auto',
    }

    return (
      <div className={classes.container} ref={this.contentRef}>
        {/* Tags drawer */}
        <Drawer
          open={showTags}
          onClose={() => this.setState({ showTags: false })}
          ModalProps={{
            container: this.contentRef.current,
          }}
          PaperProps={{ style: drawerStyle }}
        >
          <Button
            onClick={() => this.setState({ showTags: false })}
            variant="outlined"
            size="small"
            color="primary"
            className={classes.hideTagsButton}
            startIcon={<ChevronLeftIcon />}
          >
            Hide
          </Button>
          <div className={classes.tags}>
            <AllTags
              availTags={availTags}
              selectedTags={selectedTags}
              updateSelectedTags={this.selectTags}
            />
          </div>
        </Drawer>

        {/* Controls drawer */}
        <Hidden smUp>
          <Drawer
            anchor="right"
            open={showControls}
            onClose={() => this.setState({ showControls: false })}
            ModalProps={{
              keepMounted: true,
              container: this.contentRef.current,
            }}
            PaperProps={{ style: drawerStyle }}
          >
            <Paper className={classes.controlsDrawer}>
              <Button
                onClick={() => this.setState({ showControls: false })}
                variant="outlined"
                size="small"
                color="primary"
                className={classes.hideTagsButton}
                endIcon={<ChevronRightIcon />}
              >
                Hide
              </Button>
              {controls}
            </Paper>
          </Drawer>
        </Hidden>

        <div className={classes.content}>
          <Paper className={cx(classes.paper, classes.header)}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.controls}
            >
              <Button
                onClick={() => this.setState({ showTags: true })}
                variant="outlined"
                size="small"
                color="primary"
                endIcon={<ChevronRightIcon />}
              >
                Tags
              </Button>
              <Hidden xsDown>
                {controls}
                {fscreen.fullscreenEnabled && (
                  <Tooltip title="Toggle fullscreen">
                    <IconButton onClick={withCatch(this.toggleFullscreen)}>
                      <FullscreenIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Hidden>
              <div className={classes.fetching}>
                {fetching && <CircularProgress size={24} />}
              </div>
              <Hidden smUp>
                <Button
                  onClick={() => this.setState({ showControls: true })}
                  variant="outlined"
                  size="small"
                  color="primary"
                  startIcon={<ChevronLeftIcon />}
                >
                  Controls
                </Button>
              </Hidden>
            </Grid>
            <Hidden xsDown>
              <div className={classes.brush}>
                <TimeBrush
                  startTime={start_time}
                  endTime={end_time}
                  onBrushUpdate={this.updateBrush}
                />
              </div>
            </Hidden>
          </Paper>
          <Paper className={cx(classes.paper, classes.chart)}>
            {/* The Chart */}
            {this.state.mode === 'chart' && fetchedData && (
              <CommonChart
                title="Live Data"
                rows={fetchedData.rows}
                lots={lots}
                tags={fetchedData.tags as ChartableTag[]}
                startTime={brushStart}
                endTime={brushEnd}
                chartView={chartView}
                showDemo={true}
                localPrefix="live_data"
              />
            )}
            {/* The CSV download button */}
            {this.state.mode === 'csv' && (
              <Grid
                container
                justifyContent="center"
                className={classes.buttonWrapper}
              >
                <Button
                  disabled={invalidDates || downloadingCsv}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.downloadData}
                >
                  Download CSV
                </Button>
                {/* Busy indicator */}
                {downloadingCsv && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            )}
          </Paper>
        </div>
      </div>
    )
  }

  /** Download CSV file. Doesn't handle the busy indicator state. */
  downloadData_ = async () => {
    const { system } = this.props
    const { selectedTags, start_time, end_time } = this.state

    // the api doesn't recognize 'auto'; it expects undefined.
    const r = this.state.resolution
    const resolution = r === 'auto' ? undefined : r

    const { tenant_id, system_id } = system
    const tag_ids = selectedTags.map((t) => t.tag_id)
    const csvData = await this.props.api.fetchSystemTrending(
      tenant_id,
      system_id,
      {
        start_utc: formatUTC(start_time),
        end_utc: formatUTC(end_time),
        tags: tag_ids,
        resolution,
      }
    )

    if (!csvData) {
      return
    }

    const { tags, rows } = csvData

    // Prepare the column headers
    const header = tags.map((tag, index) => {
      if (index === 0) {
        return 'Timestamp'
      } else if (tag.units) {
        return `${tag.name} (${tag.units})`
      } else {
        return tag.name
      }
    })

    // Download the data as CSV
    downloadCsv(system.name, header, rows ?? [])
  }

  /** Download CSV file. Handles setting the busy indicator state */
  downloadData = () => {
    this.setState({ downloadingCsv: true })
    this.downloadData_().finally(() => this.setState({ downloadingCsv: false }))
  }

  /**
   * Update the component state based on the new properties.
   *
   * - Find the available tags
   * - Select default tags
   */
  findTags = (): Promise<boolean> => {
    const { system } = this.props

    // Find all the tags available for graphing
    const availTags = system.tags?.filter((tag) => tag.is_trending) || []
    // Sort the tags by units and name
    availTags.sort(sortTags)

    // Assign colors based on either the DB configuration or a rotating palette
    availTags.forEach((tag, index) => {
      if (!tag.color) {
        tag.color = tagColor(index)
      }
    })

    // Initialize tag selection
    let selectedTags: Tag[] = []
    // Use tag selection from local storage
    const localTags = loadLocal(`live_data.tags.${system.system_id}`, [])
    if (localTags?.length) {
      selectedTags = availTags.filter((tag) => localTags.includes(tag.tag_id))
    } else if (availTags.length) {
      // Use the first available tag
      selectedTags = [availTags[0]]
    }

    return new Promise((resolve) => {
      // Update the state
      this.setState(
        {
          availTags,
          selectedTags,
        },
        () => resolve(true)
      )
    })
  }

  onChangeResolution = (resolution: Resolution) => {
    this.setState({ resolution }, this.fetchDataWithDebounce)
  }

  // handle switching between chart and CSV mode
  onChangeMode = (mode: ChartMode) => {
    // if changing to the chart, fetch data
    const fetch = mode === 'chart' ? this.fetchDataWithDebounce : () => null
    this.setState({ mode }, fetch)
  }

  onChangeChartView = (chartView: ChartView) => {
    saveLocal('live_data.chartView', chartView)
    this.setState({ chartView })
  }

  onChangeRefreshRate = (refreshRate: RefreshRate) => {
    saveLocal('live_data.refreshRate', refreshRate)
    this.setState({ refreshRate }, this.applyRefreshRate)
  }

  clearRefreshInterval = () => window.clearTimeout(this.refreshInterval)

  applyRefreshRate = () => {
    this.clearRefreshInterval()

    const refreshTime = REFRESH_TIME_MS[this.state.refreshRate]

    if (refreshTime > 0) {
      this.refreshInterval = window.setInterval(() => {
        if (this.state.timeRange.relative) {
          this.updateTimeRange({
            relative: { ...this.state.timeRange.relative },
          })
        }
      }, refreshTime)
    }
  }

  updateBrush = (brushStart: Date, brushEnd: Date, applyRange = false) => {
    if (applyRange) {
      this.updateTimeRange({
        absolute: {
          startDate: brushStart,
          endDate: brushEnd,
        },
      })
    } else {
      this.setState({
        brushStart,
        brushEnd,
      })
    }
  }

  clearFetchDebounce = () => {
    window.clearTimeout(this.fetchDebounce)
  }

  fetchDataWithDebounce = () => {
    this.clearFetchDebounce()
    this.fetchDebounce = window.setTimeout(() => {
      this.fetchData()
    }, FETCH_DEBOUNCE_MS)
  }

  /**
   * Fetch trending data from the API (for the chart)
   */
  fetchData = async () => {
    const { system } = this.props
    const { mode, selectedTags, availTags } = this.state
    const { start_time, end_time } = this.state

    if (mode === 'csv') {
      // we're not showing the chart, so don't bother fetching data
      return
    }

    if (!selectedTags || !selectedTags.length) {
      // No tags selected
      return
    }

    // Track which fetch we're on
    const fetch_id = ++this.fetchCount

    // Need the tag IDs for the API call
    const selected_tag_ids = selectedTags.map((t) => t.tag_id)
    const tag_ids = availTags
      .filter((t) => selected_tag_ids.includes(t.tag_id))
      .map((t) => t.tag_id)

    // the api doesn't recognize 'auto'; it expects undefined.
    const r = this.state.resolution
    const resolution = r === 'auto' ? undefined : r

    // Fetch the trending data
    this.setFetching(true)
    try {
      const args = {
        start_utc: formatUTC(start_time),
        end_utc: formatUTC(end_time),
        tags: tag_ids,
        resolution,
      }

      const fetchedData = await this.props.api.fetchSystemTrending(
        system.tenant_id,
        system.system_id,
        args
      )

      if (fetch_id !== this.fetchCount) {
        // The selections have changed while we waited
        return
      }

      if (fetchedData) {
        // Convert the first column into a Date
        fetchedData.rows.forEach((row: any[]) => {
          row.forEach((value, index) => {
            if (value !== null) {
              row[index] = index === 0 ? new Date(value) : parseFloat(value)
            }
          })
        })

        // Assign the fetched tag colors based on available tag color assignments
        fetchedData.tags.forEach((tag, index) => {
          // Skip the Timestamp tag
          if (index > 0 && !tag.color) {
            tag.color =
              availTags.find((t) => t.tag_id === tag.tag_id)?.color ??
              tagColor(index)
          }
        })

        // Use this data
        this.setState({ fetchedData })
      } else {
        // Nothing was fetched
        this.setState({
          fetchedData: null,
        })
      }
    } finally {
      this.setFetching(false)
    }
  }

  /**
   * Fetch the "recent" Lots
   *
   * TODO: Check the chart time range and fetch older lots as necessary
   */
  fetchRecentLots = async () => {
    const { system } = this.props

    if (!system.show_lots) {
      // This system is not using Lots
      return
    }

    const lots = await this.props.api.listSystemLots(
      system.tenant_id,
      system.system_id,
      { days: RECENT_LOT_DAYS }
    )
    if (lots) {
      this.setState({
        lots: lots.map((lot, index) => ({
          ...lot,
          startTime: new Date(lot.start_utc),
          endTime: new Date(lot.end_utc),
          color: tagColor(index),
        })),
      })
    }
  }

  /**
   * The time range has been updated in the graph
   */
  updateTimeRange = (timeRange: TimeRange, lot: Lot | null = null) => {
    let startTime: Date = new Date()
    let endTime: Date = new Date()

    if (timeRange.absolute) {
      startTime = timeRange.absolute.startDate
      endTime = timeRange.absolute.endDate
    } else if (timeRange.relative) {
      endTime = new Date()
      startTime = sub(endTime, {
        [timeRange.relative.span]: timeRange.relative.count,
      })
    }

    // Set the time range and refresh
    this.setState(
      {
        timeRange,
        start_time: startTime,
        end_time: endTime,
        brushStart: startTime,
        brushEnd: endTime,
        selectedLot: lot ? lot.lot_id : 'None',
      },
      this.fetchDataWithDebounce
    )
  }

  /**
   * The tag selections for the chart have changed
   */
  selectTags = (tags: Tag[]) => {
    const { system } = this.props
    // save tag selection to local storage
    saveLocal(
      `live_data.tags.${system.system_id}`,
      tags.map((t) => t.tag_id)
    )
    // Set the tags and refresh
    this.setState(
      {
        selectedTags: tags,
      },
      this.fetchDataWithDebounce
    )
  }

  /**
   * Toggle the "fetching data" indicator"
   */
  setFetching = (isFetching: boolean) => {
    this.setState({ fetching: isFetching })
  }

  /** Handle the selection of a Lot */
  selectLot = (lot_id: string) => {
    const { lots } = this.state

    // Update the form first
    this.setState({
      selectedLot: lot_id,
    })

    if (lot_id === 'None') {
      // Cleared the lot selection
      return
    }

    if (!lots || lots.length === 0) {
      // No lots to check
      return
    }

    // Try to find the lot - really should be there!
    const lot = lots.find((lot) => lot.lot_id === lot_id)
    if (!lot) {
      return
    }

    this.showLot(lot)
  }

  /** Update the time range in the chart to match the lot */
  showLot = (lot: Lot) => {
    const start_time = parseDate(lot.start_utc)
    const end_time = parseDate(lot.end_utc)
    if (start_time && end_time) {
      this.updateTimeRange(
        {
          absolute: {
            startDate: start_time,
            endDate: end_time,
          },
        },
        lot
      )
    }
  }

  /**
   * Toggle showing the graph UI in fullscreen mode.
   */
  toggleFullscreen = () => {
    if (fscreen.fullscreenElement) {
      fscreen.exitFullscreen()
    } else if (this.contentRef.current) {
      fscreen.requestFullscreen(this.contentRef.current)
    }
  }
}

export default withRouter(withApp(withSystem(withStyles(styles)(LiveDataView))))
