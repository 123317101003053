import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import WarnIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      error: {
        color: theme.palette.error.main,
      },
      warn: {
        color: theme.palette.warning.main,
      },
      ok: {
        color: theme.palette.success.main,
      },
    }),
  { name: 'StatusIcon' }
)

export type StatusOption = 'ok' | 'warn' | 'error'

type Props = {
  status: StatusOption
  className?: string
}

/**
 * Colored icon to show status.
 *
 * <StatusIcon status="ok|warn|error" ... />
 */
const StatusIcon: React.FC<Props> = (props: Props) => {
  const { status, className, ...other } = props

  const classes = useStyles()

  if (status === 'error') {
    return <ErrorIcon className={`${classes.error} ${className}`} {...other} />
  }

  if (status === 'warn') {
    return <WarnIcon className={`${classes.warn} ${className}`} {...other} />
  }

  return <CheckIcon className={`${classes.ok} ${className}`} {...other} />
}

export default StatusIcon
