import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'
import { Hub, I18n } from '@aws-amplify/core'

/**
 * Add the Authentication header to all Amplify API requests
 */
const add_auth = async () => {
  const session = await Auth.currentSession()
  const token = session.getIdToken().getJwtToken()
  return {
    Authorization: `Bearer ${token}`,
  }
}

const configs = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: 'data',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: add_auth,
      },
      {
        name: 'barcode',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: add_auth,
      },
    ],
  },
}

// Text labels for the sign up form
const AMPLIFY_LABELS = {
  en: {
    'Sign in to your account': 'Welcome to SepTrac',
  },
}

export const configureAws = (): void => {
  // Configure Amplify
  Auth.configure(configs.Auth)
  API.configure(configs.API)

  // Customize the text labels
  I18n.putVocabularies(AMPLIFY_LABELS)

  // Listen for AWS authentication events (eg: sign-in, sign-out)
  Hub.listen('auth', async (data) => {
    const { payload } = data
    switch (payload.event) {
      case 'signOut':
        if (window.location.pathname !== '/') {
          // Go to the home page after sign-out to clear the URL
          window.location.href = '/'
        }
        break

      default:
        break
    }
  })
}

export default configureAws
