import { DataGetterPropType, VictoryLine, VictoryScatter } from 'victory'

import { ChartableTag, OPACITY_UNFOCUSED } from '../charts/shared'

type Props = {
  rows: any[][]
  tags: ChartableTag[]
  tagIndex: number
  getY?: (row: any) => number | null
  selectedTag?: string | null
  multiple?: boolean
  showDots?: boolean
}

/**
 * The colored line for a single tag's data.
 */
const TagLine: React.FC<Props> = (props: Props) => {
  const {
    rows,
    tags,
    tagIndex,
    getY,
    selectedTag = null,
    multiple = false,
    showDots = false,
    ...other
  } = props
  const tag = tags[tagIndex]
  const selected = selectedTag === tag.name

  const fullOpacity = multiple ? 0.8 : 1.0
  const color = tag.color

  // The VictoryCharts typing does not allow the data getter to return null, but
  // the charts do so we'll force the typing a bit here.
  const yGetter: DataGetterPropType =
    (getY as unknown as DataGetterPropType) ?? tagIndex

  return (
    <g>
      <VictoryLine
        {...other}
        data={rows}
        x={0}
        y={yGetter}
        style={{
          data: {
            stroke: color,
            strokeWidth: selected ? 2 : 1,
            opacity: !selectedTag || selected ? fullOpacity : OPACITY_UNFOCUSED,
          },
        }}
      />
      {showDots && (!selectedTag || selected) && (
        <VictoryScatter
          {...other}
          data={rows}
          x={0}
          y={yGetter}
          size={2}
          style={{
            data: {
              fill: color,
              opacity: 0.5,
            },
          }}
        />
      )}
    </g>
  )
}

export default TagLine
