import { useRef, useState } from 'react'

import {
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  Slider,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'

import { DemoOptions } from './shared'

type Props = {
  demo: DemoOptions
  setDemo: (demo: DemoOptions) => void
}

/**
 * Menu to control gauge demo options
 */
const DemoMenu: React.FC<Props> = (props: Props) => {
  const { demo, setDemo } = props

  const ref = useRef<any>(null)
  const [open, setOpen] = useState<boolean>(false)

  const updateDemo = (key: string, value: any) => {
    setDemo({
      ...demo,
      [key]: value,
    })
  }
  const setValue = (key: string) => (event: any) =>
    updateDemo(key, event.target.value)
  const toggleValue = (key: string) => (event: any) =>
    updateDemo(key, event.target.checked)

  const items = []

  items.push(
    <MenuItem key="menu_0">
      <FormControlLabel
        label="Gauge Type"
        control={
          <Select value={demo.gauge_type} onChange={setValue('gauge_type')}>
            <MenuItem value="linear">Linear</MenuItem>
            <MenuItem value="radial">Radial</MenuItem>
          </Select>
        }
      />
    </MenuItem>
  )
  items.push(
    <MenuItem key="menu_1">
      <FormControlLabel
        label="Highlights"
        control={
          <Checkbox
            checked={!!demo.highlights}
            onChange={toggleValue('highlights')}
          />
        }
      />
    </MenuItem>
  )
  items.push(
    <MenuItem key="menu_2">
      <FormControlLabel
        label="Highlight Headers"
        control={
          <Checkbox
            checked={!!demo.highlight_headers}
            onChange={toggleValue('highlight_headers')}
          />
        }
      />
    </MenuItem>
  )
  items.push(
    <MenuItem key="menu_3">
      <Slider
        onChange={(event, value) => updateDemo('bar_width', value)}
        defaultValue={demo.bar_width}
        min={5}
        max={50}
        step={5}
        marks
        valueLabelDisplay="auto"
      />
    </MenuItem>
  )

  items.push(
    <MenuItem key="menu_4">
      <FormControlLabel
        label="Needle Color"
        control={
          <Select value={demo.needle_color} onChange={setValue('needle_color')}>
            <MenuItem value="primary.main">Primary Main</MenuItem>
            <MenuItem value="primary.light">Primary Light</MenuItem>
            <MenuItem value="secondary.main">Secondary Main</MenuItem>
            <MenuItem value="secondary.light">Secondary Light</MenuItem>
          </Select>
        }
      />
    </MenuItem>
  )

  if (demo.gauge_type === 'linear') {
    items.push(
      <MenuItem key="menu_5">
        <FormControlLabel
          label="Units"
          control={
            <Checkbox
              checked={!!demo.linear_units}
              onChange={toggleValue('linear_units')}
            />
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_6">
        <FormControlLabel
          label="Needle"
          control={
            <Select
              value={demo.linear_needle}
              onChange={setValue('linear_needle')}
            >
              <MenuItem value="off">None</MenuItem>
              <MenuItem value="left">Top</MenuItem>
              <MenuItem value="right">Bottom</MenuItem>
              <MenuItem value="both">Both</MenuItem>
            </Select>
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_7">
        <FormControlLabel
          label="Ticks"
          control={
            <Select
              value={demo.linear_ticks}
              onChange={setValue('linear_ticks')}
            >
              <MenuItem value="left">Top</MenuItem>
              <MenuItem value="right">Bottom</MenuItem>
              <MenuItem value="both">Both</MenuItem>
            </Select>
          }
        />
      </MenuItem>
    )
  } else {
    items.push(
      <MenuItem key="menu_8">
        <FormControlLabel
          label="Half Circle"
          control={
            <Checkbox
              checked={!!demo.radial_half}
              onChange={toggleValue('radial_half')}
            />
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_9">
        <FormControlLabel
          label="Value Box"
          control={
            <Checkbox
              checked={!!demo.radial_valuebox}
              onChange={toggleValue('radial_valuebox')}
            />
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_10">
        <FormControlLabel
          label="Units"
          control={
            <Checkbox
              checked={!!demo.radial_units}
              onChange={toggleValue('radial_units')}
            />
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_11">
        <FormControlLabel
          label="Bar"
          control={
            <Checkbox
              checked={!!demo.radial_bar}
              onChange={toggleValue('radial_bar')}
            />
          }
        />
      </MenuItem>
    )
    items.push(
      <MenuItem key="menu_12">
        <FormControlLabel
          label="Needle"
          control={
            <Checkbox
              checked={!!demo.radial_needle}
              onChange={toggleValue('radial_needle')}
            />
          }
        />
      </MenuItem>
    )
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        {items}
      </Menu>
    </>
  )
}

export default DemoMenu
