import React, { useState } from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  TextFieldProps,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'

import {
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  isFunction,
} from 'formik'

import { Option } from '../../lib/types'
import { useApp } from '../../providers'
import { AppTextField } from '../common'

type HasName = {
  name: string
}

export const EDIT_PROPS: Partial<TextFieldProps> = {
  variant: 'outlined',
  fullWidth: true,
  InputLabelProps: { shrink: true },
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      formContent: {
        '& .MuiFormControl-root': {
          padding: theme.spacing(0, 1),
          margin: theme.spacing(1, 0),
        },
      },
      actions: {
        // Move the buttons to the right (end)
        justifyContent: 'flex-end',
      },
    }),
  { name: 'AdminForm' }
)

type Props<T extends HasName> = {
  /**
   * Callback triggered when the form values should be saved
   *
   * @param values values from the form
   * @param form Formik helper
   * @return saved value, or null if the save failed, as Promise
   */
  onSave: (values: T, form: FormikHelpers<T>) => Promise<any>

  /** Label for the name input (default: Name) */
  name_label?: string

  /** Form elements as React children or child render callback */
  children?: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode
} & Omit<FormikConfig<T>, 'onSubmit' | 'children'>

/**
 * Standard form for editing the main entity on an admin page
 */
export default function AdminForm<T extends HasName>(
  props: Props<T>
): React.ReactElement {
  const { name_label = 'Name', onSave, children, ...rest } = props

  const classes = useStyles()
  const { asBusy } = useApp()

  // State for the name editing
  const [editName, setEditName] = useState<boolean>(false)

  return (
    <Formik
      enableReinitialize
      onSubmit={
        // User submitted the form - save the data
        async (values, f) =>
          asBusy(async () => {
            await onSave(values, f)
            f.setSubmitting(false)
            setEditName(false)
          })
      }
      onReset={() => {
        // Stop editing the name
        setEditName(false)
      }}
      {...rest}
    >
      {(f) => (
        <form noValidate onSubmit={f.handleSubmit} onReset={f.handleReset}>
          <Card>
            <CardHeader
              title={
                editName ? (
                  <AppTextField
                    name="name"
                    variant="outlined"
                    label={name_label}
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                ) : (
                  <Typography variant="h4">
                    {f.values.name}
                    <IconButton onClick={() => setEditName(true)}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                )
              }
            />
            {children && (
              <CardContent className={classes.formContent}>
                {isFunction(children)
                  ? (children as (bag: FormikProps<T>) => React.ReactNode)(f)
                  : children}
              </CardContent>
            )}
            {(children || editName) && (
              <CardActions className={classes.actions}>
                <Button type="reset">Reset</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!f.dirty || !f.isValid || f.isSubmitting}
                >
                  Save
                </Button>
              </CardActions>
            )}
          </Card>
        </form>
      )}
    </Formik>
  )
}

/**
 * Render the items as MenuItems for a Select component
 *
 * @param items selection items
 * @param value_key key to the value field
 * @param label_key key to the label field
 * @returns array of MenuItems
 */
export function listItems<T>(
  items: T[] | undefined | null,
  value_key: keyof T,
  label_key: keyof T
): React.ReactElement[] {
  return (
    items?.map((item: T) => (
      <MenuItem key={item[value_key] as any} value={item[value_key] as any}>
        {item[label_key]}
      </MenuItem>
    )) ?? []
  )
}

/**
 * Render the Options as MenuItems for a Select component
 *
 * @param options array of Option items
 * @returns array of MenuItems
 */
export const optionItems = (options: Option[]): React.ReactElement[] =>
  listItems(options, 'value', 'label')
