import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  IconButton,
  Tooltip,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/DeleteOutline'

import { FormikConfig, FormikProps } from 'formik'
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
} from 'mui-datatables'
import EditDialog from './EditDialog'

import { MIN_CELL, renderLite } from '../../lib/tables'
import { useApp } from '../../providers'
import LoadPage from './LoadPage'

const DEFAULT_TABLE_OPTIONS: MUIDataTableOptions = {
  // Don't allow selecting rows
  selectableRows: 'none',
  // Don't allow selection columns
  viewColumns: false,
  // Allow object paths using '.', like 'part.name'
  enableNestedDataAccess: '.',
}

// Helper type
type HasName = {
  name?: string
  username?: string
}

const useStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      actions: {
        whiteSpace: 'nowrap',
      },
    }),
  { name: 'AdminTable' }
)

type Props<T extends HasName> = {
  /** Label for the entity type (e.g. Customer) */
  label: string
  /** Key for the the ID field (e.g. tenant_id) */
  id_key: keyof T

  data: T[] | null

  /**
   * Callback triggered when preparing a new entity for the edit form
   * @returns initial form values for the new entity
   */
  makeNew?: () => Partial<T>
  /**
   * Callback triggered when the entity should be saved
   *
   * @param row updated entity for the row
   * @returns saved entity, or null if it could not be saved
   */
  onSave: (row: Partial<T>) => Promise<T | null>
  /** Callback triggered when the table should be refreshed */
  refresh: () => any

  /** Key for the name field (default: 'name') */
  name_key?: keyof T | 'name'
  /**
   * Callback triggered when an entity should be deleted.
   * If not provided, the Delete button will be hidden.
   *
   * @param row the entity for the row to be deleted
   * @returns true if deleted, false or null otherwise
   */
  onDelete?: (row: T) => Promise<boolean | null>

  /**
   * Callback triggered when constructing a path to the admin page for the entity.
   *
   * If provided, the Edit button will send the user to the admin page to edit the entity.
   * If not provided, the Edit button will open a form in a dialog.
   *
   * @param row the entity for the row
   * @returns path to admin page
   */
  makeEditPath?: (row: T) => string

  /** Form elements as React children or child render callback */
  children?: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode

  /** Additional properties passed to Formik (e.g. validationSchema )*/
  FormikProps?: Partial<FormikConfig<T>>
} & Omit<MUIDataTableProps, 'data'>

/**
 * Standard table for editing entities in the Admin UI.
 *
 * Additional properties are passed to MUIDataTable.
 *
 * @example
 * <AdminTable<Tenant> title="Tenants" label="Tenant"
 *   id_key="tenant_id"
 *   columns={columns}
 *   data={data}
 *   FormikProps={{
 *     validationSchema: TENANT_SCHEMA
 *   }}
 *   makeNew={() => ({
 *     status: 'Active',
 *     totalVisits: 0,
 *     remainingVisits: 0,
 *   })}
 *   makeEditPath={
 *     (row) => `/admin/tenant/${row.tenant_id}`
 *   }
 *   onSave={
 *     (row) => (row.tenant_id)
 *       ? this.props.api.updateTenant(row.tenant_id, row)
 *       : this.props.api.createTenant(row)
 *   }
 *   refresh={this.fetchTenants}
 * >
 *   <AppTextField
 *     name="name"
 *     label="Name"
 *     type="text"
 *     required
 *     fullWidth
 *   />
 * </AdminTable>
 */
export default function AdminTable<T extends HasName>(
  props: Props<T>
): React.ReactElement {
  const {
    // AdminTable-specific options
    label,
    makeNew,
    makeEditPath,
    onDelete,
    onSave,
    refresh,
    children,
    id_key,
    name_key = 'name',
    // MUIDataTable required options
    title,
    columns,
    data,
    options = {},
    // Formik options
    FormikProps = {},
    // Additional MUIDataTable options
    ...tableProps
  } = props

  const fullOptions: MUIDataTableOptions = {
    // Start with the default options
    ...DEFAULT_TABLE_OPTIONS,
    // Hide the pagination unless it is needed
    pagination: (data?.length ?? 0) > 10,
    // Merge in the caller options
    ...(options ?? {}),
  }

  const classes = useStyles()
  const history = useHistory()
  const { asBusy } = useApp()

  // Selected rows for the Edit and Delete dialogs
  const [editRow, setEditRow] = useState<T | null>(null)
  const [deleteRow, setDeleteRow] = useState<T | null>(null)

  if (data === null) {
    return <LoadPage />
  }

  const fullColumns = [
    // Add in the Actions columns
    {
      name: 'actions',
      label: 'Actions',
      options: {
        // Disable all the features
        filter: false,
        download: false,
        searchable: false,
        sort: false,
        // Keep the column small
        setCellHeaderProps: MIN_CELL,
        customBodyRenderLite: renderLite(data as T[], (row: T) => (
          <div className={classes.actions}>
            <Tooltip title={`Edit ${label}`}>
              <IconButton
                onClick={() =>
                  makeEditPath
                    ? history.push(makeEditPath(row))
                    : setEditRow(row)
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {onDelete && (
              <Tooltip title={`Delete ${label}`}>
                <IconButton onClick={() => setDeleteRow(row)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )),
      },
    } as MUIDataTableColumn,
    // Merge in the caller columns
    ...columns,
  ]

  return (
    <>
      <MUIDataTable
        {...tableProps}
        options={fullOptions}
        columns={fullColumns}
        data={data}
        title={
          <Typography variant="h6">
            {makeNew && (
              <Tooltip title={`Add ${label}`}>
                <IconButton onClick={() => setEditRow(makeNew() as T)}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {title}
          </Typography>
        }
      />
      {editRow && (
        <EditDialog
          open={true}
          title={
            editRow[id_key]
              ? `Edit "${editRow[name_key]}"`
              : `Create New ${label}`
          }
          initialValues={editRow}
          onSave={(row) =>
            asBusy(async () => {
              const resp = await onSave(row)
              if (resp) {
                if (makeEditPath) {
                  // Navigate to the new page after creation
                  history.push(makeEditPath(resp))
                } else {
                  // Refresh the table
                  refresh()
                }
              }
              return !!resp
            })
          }
          onClose={() => setEditRow(null)}
          {...FormikProps}
        >
          {children}
        </EditDialog>
      )}
      {onDelete && deleteRow && (
        <Dialog open={true}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete &quot;{deleteRow[name_key]}&quot;
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteRow(null)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                asBusy(async () => {
                  if (await onDelete(deleteRow)) {
                    // Refresh the table
                    refresh()
                  }
                  setDeleteRow(null)
                })
              }
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
