import { Theme } from '@material-ui/core'
import { AlarmLevel, TagPerformance } from '../../lib/types'

export const GAUGE_WIDTH = 200

export type Highlights = {
  ranges: {
    from: number
    to: number
    color: string
  }[]
  className: string
}

export type TagCardProps = {
  perfTag: TagPerformance
  alarmLevel?: AlarmLevel | null
  demo: any
}

export const DEMO_DEFAULTS = {
  gauge_type: 'radial',
  highlights: false,
  highlight_headers: false,
  bar_width: 30,
  needle_color: 'secondary.main',
  needle_width: 3,
  linear_needle: 'left',
  linear_ticks: 'right',
  linear_units: false,
  radial_valuebox: false,
  radial_half: false,
  radial_units: true,
  radial_bar: false,
  radial_needle: true,
}

export type DemoOptions = typeof DEMO_DEFAULTS

/**
 * Find the highlight ranges and gauge class
 *
 * @param perfTag current tag
 * @returns highlight ranges and gauge class
 */
export const findHighlights = (
  perfTag: TagPerformance,
  theme: Theme,
  demo: DemoOptions
): Highlights => {
  const { tag, value } = perfTag

  const highlights: Highlights = {
    ranges: [],
    className: '',
  }

  // Force undefined to null
  const min_value = tag.min_value ?? null
  const max_value = tag.max_value ?? null

  if (min_value === null || max_value === null) {
    return highlights
  }

  // Force undefined to null
  const low_error = tag.low_error ?? null
  const high_error = tag.high_error ?? null
  const low_warning = tag.low_warning ?? null
  const high_warning = tag.high_warning ?? null

  // Add the low ranges
  if (low_error !== null) {
    highlights.ranges.push({
      from: min_value,
      to: low_error,
      color: theme.palette.error.main,
    })
  }
  if (low_warning !== null) {
    highlights.ranges.push({
      from: low_error ?? min_value,
      to: low_warning,
      color: theme.palette.warning.main,
    })
  }

  // Add the high ranges
  if (high_warning !== null) {
    highlights.ranges.push({
      from: high_warning,
      to: high_error ?? max_value,
      color: theme.palette.warning.main,
    })
  }
  if (high_error !== null) {
    highlights.ranges.push({
      from: high_error,
      to: max_value,
      color: theme.palette.error.main,
    })
  }

  // Determine the class for the entire gauge
  if (demo.highlight_headers) {
    if (low_error !== null && value <= low_error) {
      highlights.className = 'error'
    } else if (low_warning !== null && value <= low_warning) {
      highlights.className = 'warning'
    } else if (high_error !== null && value >= high_error) {
      highlights.className = 'error'
    } else if (high_warning !== null && value >= high_warning) {
      highlights.className = 'warning'
    }
  }

  return highlights
}

/**
 * Find a decent tick-step for the span to get 5-6 tick marks on the gauge
 *
 * @param {number} span range from min to max
 * @returns {number}
 */
export const findTickStep = (span: number): number => {
  // Find a starting scale about 2 orders of magnitude below the span
  // For example, a span of 345 would have a scale of 1
  const scale = Math.pow(10, Math.floor(Math.log10(span) - 1))

  // Divide the span into roughly 5 sections
  if (span <= 10 * scale) {
    return 2 * scale
  } else if (span <= 20 * scale) {
    return 4 * scale
  } else if (span <= 50 * scale) {
    return 10 * scale
  } else {
    return 20 * scale
  }
}

export const findTicks = (perfTag: TagPerformance): number[] => {
  const { tag } = perfTag

  // Force range to actual numbers
  const min_value = tag.min_value || 0
  const max_value = tag.max_value || 0

  // Try to guess a nice tick scale
  const span = max_value - min_value
  const step = findTickStep(span)

  // Add in all the ticks from min to max
  const ticks: number[] = []
  for (let tick = min_value || 0; tick <= max_value; tick += step) {
    ticks.push(tick)
  }

  // Make sure the max_value is included in the ticks
  if (ticks[ticks.length - 1] < max_value) {
    ticks.push(max_value)
  }

  return ticks
}

export const findNeedleColor = (theme: Theme, demo: DemoOptions): string => {
  switch (demo.needle_color) {
    case 'primary.main':
      return theme.palette.primary.main
    case 'primary.light':
      return theme.palette.primary.light
    case 'secondary.main':
      return theme.palette.secondary.main
    case 'secondary.light':
      return theme.palette.secondary.light
    default:
      return theme.palette.secondary.main
  }
}
