import React from 'react'

import { Card, CardContent, CardHeader, Container } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import cx from 'classnames'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      divider: {
        margin: theme.spacing(1, 0),
      },
    }),
  { name: 'ContentContainer' }
)

type Props = {
  /** Page title */
  title?: React.ReactNode
  /** Page contents */
  children: React.ReactNode
  /** Additional class name for the outer container (optional) */
  className?: string
}

/**
 * Common container for content pages with a title and divider
 */
const ContentContainer: React.FC<Props> = (props: Props) => {
  const { title, children, className, ...other } = props

  const classes = useStyles()

  return (
    <Container
      maxWidth={false}
      {...other}
      className={cx(classes.container, className)}
    >
      {title && (
        <Card>
          <CardHeader title={title} />
          <CardContent>{children}</CardContent>
        </Card>
      )}
      {!title && children}
    </Container>
  )
}

export default ContentContainer
