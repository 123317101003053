import { Typography } from '@material-ui/core'
import { VictoryChart } from 'victory'

import { useDimensions } from '../../lib/hooks'
import { ChartableTag, findMinMax, CHART_PADDING } from './shared'
import TagLine from './TagLine'
import Tooltip from './Tooltip'
import XAxis from './XAxis'
import YAxis from './YAxis'

const CHART_HEIGHT = 120

type Props = {
  startTime: Date
  endTime: Date
  rows: any[][]
  tags: ChartableTag[]
  tagIndex: number
  syncTooltips: boolean
  showDots: boolean
  forceMinMax: boolean
}

/**
 * Chart for a single tag
 */
const PerTagChart: React.FC<Props> = (props: Props) => {
  const {
    startTime,
    endTime,
    rows,
    tags,
    tagIndex,
    syncTooltips,
    showDots,
    forceMinMax,
  } = props

  const tag = tags[tagIndex]

  // Measure the width of the wrapper to size the chart
  const [refDimensions, dimensions] = useDimensions()

  // Find the range graphed data
  const minMax = findMinMax(rows, tag, tagIndex, forceMinMax)

  return (
    <div ref={refDimensions}>
      {dimensions && (
        <>
          <Typography>
            {tag.name} ({tag.units})
          </Typography>
          <VictoryChart
            width={dimensions.width}
            height={CHART_HEIGHT}
            domain={{
              x: [startTime, endTime],
              y: [minMax.min, minMax.max],
            }}
            singleQuadrantDomainPadding={{ y: false }}
            padding={CHART_PADDING}
            domainPadding={{ y: 1 }}
            scale={{ x: 'time', y: 'linear' }}
            prependDefaultAxes={true}
            defaultAxes={{
              dependent: <XAxis />,
              independent: <YAxis />,
            }}
          >
            <TagLine
              rows={rows}
              tags={tags}
              tagIndex={tagIndex}
              showDots={showDots}
              multiple={false}
            />
            <Tooltip
              rows={rows}
              tags={tags}
              tagIndexes={[tagIndex]}
              sync={syncTooltips}
            />
          </VictoryChart>
        </>
      )}
    </div>
  )
}

export default PerTagChart
