/**
 * Redux Store, Actions, and Reducer to manage global state.
 */

// The Redux actions are just too weird to type
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { System, Tenant } from './types'
import { saveLocal } from './utils'

export const KEY_LAST_TENANT_ID = 'last.tenant_id'
export const KEY_LAST_SYSTEM_ID = 'last.system_id'

// STORE //////////////////////////////////////////////////////////////

export type StoreState = {
  /** UI Busy semaphore */
  uiBusy: number | 'ok'
  /** Errors that need to be reported to the user */
  errors: any[]

  /** All the tenants the user can access, cached for the nav bar */
  tenants: Tenant[] | null
  /** The selected tenant, cached for the nav bar */
  tenant: Tenant | null
  /** The selected system, cached for the nav bar */
  system: System | null

  /** Shared tooltip row for graphs */
  tooltipRow: any
  tooltipLocked: boolean
}

const initState: StoreState = {
  uiBusy: 0,
  errors: [],

  tenants: null,
  tenant: null,
  system: null,

  tooltipRow: null,
  tooltipLocked: false,
}

// ACTIONS ////////////////////////////////////////////////////////////

export function setUiBusy(uiState: number | 'ok') {
  return {
    type: 'SET_UI_BUSY' as const,
    data: uiState,
  }
}

export function setTenants(tenants: Tenant[] | null) {
  return {
    type: 'SET_TENANTS' as const,
    data: tenants,
  }
}

export function setTenant(tenant: Tenant | null) {
  // Persist the selection to local storage
  if (tenant) {
    saveLocal(KEY_LAST_TENANT_ID, tenant.tenant_id)
  }

  return {
    type: 'SET_TENANT' as const,
    data: tenant,
  }
}

export function setSystem(system: System | null) {
  // Persist the selection to local storage
  if (system) {
    saveLocal(KEY_LAST_SYSTEM_ID, system.system_id)
  }

  return {
    type: 'SET_SYSTEM' as const,
    data: system,
  }
}

export function selectSystem(tenant: Tenant | null, system: System | null) {
  // Persist the selection to local storage
  if (tenant) {
    saveLocal(KEY_LAST_TENANT_ID, tenant.tenant_id)
  }
  if (system) {
    saveLocal(KEY_LAST_SYSTEM_ID, system.system_id)
  }

  return {
    type: 'SELECT_SYSTEM' as const,
    data: { tenant, system },
  }
}

// Record a new error
export const addError = (error: any) => {
  // Start with the basic item
  const item: any = {
    text: null,
    title: null,
    error,
  }

  if (!error) {
    item.text = 'Unknown error'
  } else if (typeof error === 'string') {
    // Just use the text
    item.text = error
  } else if (error.response) {
    // API error
    const status = error.response.status

    item.title = `API ERROR [${status}]`

    // Fallback to a generic (and useless) message
    item.text = 'Server error'
    if (error.response.data?.detail) {
      // The API has provided an error message
      const detail = error.response.data?.detail
      if (typeof detail === 'string') {
        // Simple message
        item.text = detail
      } else if (status === 422 && Array.isArray(detail)) {
        // Validation errors are sent as arrays
        item.text =
          'Validation Error:\n' +
          detail
            .map((item) => `\t${item.msg}: ${item.loc.join('.')}`)
            .join('\n')
      } else {
        // Not sure what this is, but let's make it at least sort of readable and not '[Object object]'
        item.text = JSON.stringify(detail)
      }
    }
  } else if (typeof error.message === 'string') {
    // Get the Error message
    item.text = error.message
  } else {
    // Hmm, something else
    item.text = error.toString()
  }

  return {
    type: 'ERRORS_ADD' as const,
    error: item,
  }
}

// Dismiss the current error
export const dismissError = () => ({
  type: 'ERRORS_REMOVE' as const,
})

// Clear all errors
export const clearErrors = () => ({
  type: 'ERRORS_CLEAR' as const,
})

export const setTooltipRow = (tooltipRow: any) => {
  return {
    type: 'SET_TOOLTIP_ROW' as const,
    data: tooltipRow,
  }
}

export const setTooltipLocked = (tooltipLocked: boolean) => {
  return {
    type: 'SET_TOOLTIP_LOCKED' as const,
    data: tooltipLocked,
  }
}

export type Action =
  | ReturnType<typeof setUiBusy>
  | ReturnType<typeof setTenants>
  | ReturnType<typeof setTenant>
  | ReturnType<typeof setSystem>
  | ReturnType<typeof selectSystem>
  | ReturnType<typeof addError>
  | ReturnType<typeof dismissError>
  | ReturnType<typeof clearErrors>
  | ReturnType<typeof setTooltipRow>
  | ReturnType<typeof setTooltipLocked>

// REDUCER ////////////////////////////////////////////////////////////

export const reducer = (state = initState, action: Action): StoreState => {
  switch (action.type) {
    case 'SET_UI_BUSY':
      return {
        ...state,
        uiBusy: action.data,
      }

    case 'SET_TENANTS':
      return {
        ...state,
        tenants: action.data,
        tenant: null,
        system: null,
      }

    case 'SET_TENANT':
      return {
        ...state,
        tenant: action.data,
        system: null,
      }

    case 'SET_SYSTEM':
      return {
        ...state,
        system: action.data,
      }

    case 'SELECT_SYSTEM':
      return {
        ...state,
        tenant: action.data.tenant,
        system: action.data.system,
      }

    case 'ERRORS_ADD':
      // Append the new error
      return {
        ...state,
        errors: [...state.errors, action.error],
      }
    case 'ERRORS_REMOVE':
      // Remove the first error
      return {
        ...state,
        errors: state.errors.slice(1),
      }
    case 'ERRORS_CLEAR':
      // Purge the list
      return {
        ...state,
        errors: [],
      }

    case 'SET_TOOLTIP_ROW':
      return {
        ...state,
        tooltipRow: action.data,
      }

    case 'SET_TOOLTIP_LOCKED':
      return {
        ...state,
        tooltipLocked: action.data,
      }

    default:
      return state
  }
}

// Create the Redux store to manage global state
export const store = configureStore({
  reducer,
})

// Convenient typing shortcuts
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector

// UTILITIES //////////////////////////////////////////////////////////

/**
 * Utility to wrap event handlers with a try/catch block and display any errors.
 *
 * For example:
 *
 * <Button onClick={withCatch((evt) => { stuff.that.could.fail(); })}>...</Button>
 */
export const withCatch =
  (callback: (...args: any[]) => any) =>
  (dispatch: AppDispatch) =>
  async (...args: any[]): Promise<void> => {
    try {
      await callback(...args)
    } catch (err) {
      dispatch(addError(err))
    }
  }

/**
 * Utility to pause for a brief time
 *
 * @param waitTime time to wait, in milliseconds
 */
const wait = async (waitTime: number) => {
  await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), waitTime)
  })
}

/**
 * Increment the UI busy counter to indicate the start of a task
 *
 * @param store Redux store
 */
export const incUiBusy = async (store: AppStore) => {
  const { uiBusy } = store.getState()

  if (uiBusy === 'ok') {
    store.dispatch(setUiBusy(1))
  } else {
    store.dispatch(setUiBusy(uiBusy + 1))
  }
}

/**
 * Decrement the UI busy counter to indicate the end of a task
 *
 * @param store Redux store
 */
export const decUiBusy = async (store: AppStore) => {
  let { uiBusy } = store.getState()

  if (uiBusy === 'ok') {
    return
  } else if (uiBusy > 1) {
    store.dispatch(setUiBusy(uiBusy - 1))
  } else if (uiBusy === 1) {
    store.dispatch(setUiBusy('ok'))
    await wait(750)
    uiBusy = store.getState().uiBusy
    if (uiBusy === 'ok') {
      store.dispatch(setUiBusy(0))
    }
  }
}
