import React from 'react'

import {
  Checkbox,
  FormControlProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  CheckboxProps,
} from '@material-ui/core'
import { Field, FastField, FieldProps, getIn } from 'formik'

type Props = {
  /** If true, use the FastField (no cross field valiation) */
  fast?: boolean
  helperText?: string
} & CheckboxProps &
  FormControlProps &
  Omit<FormControlLabelProps, 'control'>

/**
 * Standard Checkbox that can be used in Formik forms
 */
const AppCheckbox: React.FC<Props> = (props) => {
  const { fast = false, ...rest } = props
  return fast ? (
    <FastField component={InnerField} {...rest} />
  ) : (
    <Field component={InnerField} {...rest} />
  )
}

/**
 * Utility component for use with Formik <Field/> and <FastField/>
 *
 * For example:
 *
 *   <FastField name="title" component={InnerField} />
 *
 * See: https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/
 */
const InnerField: React.FC<FieldProps & Omit<Props, 'fast'>> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)
  const checked = getIn(props.form.values, props.field.name)

  const { error, label, helperText, field, form: _form, ...rest } = props

  const message =
    helperText ?? (isTouched && errorMessage ? errorMessage : false)

  return (
    <FormControl
      error={error ?? Boolean(isTouched && errorMessage)}
      {...rest} // includes any Material-UI specific props
      {...field} // includes all props contributed by the Formik Field/FastField
    >
      <FormControlLabel
        label={label}
        control={<Checkbox name={props.field.name} checked={checked} />}
      />
      {message !== false && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  )
}

export default AppCheckbox
