import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables'

import { formatDate } from '../../lib/dates'
import { byString } from '../../lib/sortItems'
import { MIN_CELL, NO_WRAP, renderLite } from '../../lib/tables'
import { AlarmLog } from '../../lib/types'
import { useApp, useSystem } from '../../providers'
import { ContentContainer } from '../common'
import LoadPage from '../common/LoadPage'

import AlarmSubscription from './AlarmSubscription'

// How many days of data to fetch
const DEFAULT_DAYS = 365
// Max alarms to return
const MAX_ITEMS = 500

/**
 * History of alarm messages
 */
const AlarmHistory: React.FC = () => {
  const { api } = useApp()
  const { system } = useSystem()

  const [log, setLog] = useState<AlarmLog | null>(null)

  // Fetch the alarm log
  useEffect(() => {
    // Fetch the alarm log
    api
      .getSystemAlarmLog(system.tenant_id, system.system_id, {
        days: DEFAULT_DAYS,
        max: MAX_ITEMS,
      })
      .then((log) => {
        if (log) {
          // Put the most recent alarms first
          log.rows.sort(byString('timestamp')).reverse()
          setLog(log)
        }
      })
  }, [api, system])

  if (!log) {
    return <LoadPage />
  }

  const options: MUIDataTableOptions = {
    selectableRows: 'none',
    viewColumns: false,
  }
  const data = log.rows
  const columns: MUIDataTableColumn[] = [
    {
      name: 'timestamp',
      label: 'Timestamp',
      options: {
        filter: false,
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
        customBodyRenderLite: renderLite(data, (entry) =>
          formatDate(entry.timestamp)
        ),
      },
    },
    {
      name: 'tag_name',
      label: 'Tag',
      options: {
        setCellHeaderProps: MIN_CELL,
        setCellProps: NO_WRAP,
      },
    },
    {
      name: 'text',
      label: 'Message',
    },
  ]

  return (
    <ContentContainer>
      <AlarmSubscription />
      <MUIDataTable
        title={<Typography variant="h5">Alarm History</Typography>}
        options={options}
        columns={columns}
        data={data}
      />
    </ContentContainer>
  )
}

export default AlarmHistory
