import {
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'

import { TagCardProps } from './shared'

/**
 * Display a tag value as a message
 */
const TagMessage: React.FC<TagCardProps> = (props: TagCardProps) => {
  const { perfTag } = props
  const { tag, value } = perfTag

  return (
    <>
      <CardHeader title={tag.name} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <Typography variant="h6">{value}</Typography>
      </CardContent>
      <CardActions>&nbsp;</CardActions>
    </>
  )
}

export default TagMessage
