import React from 'react'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'

import { Container, Hidden } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import StandardNav from './StandardNav'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'absolute',
        height: '100%',
        width: '100%',
      },
      header: {
        flex: '1 1 auto',
        zIndex: 100,
        position: 'relative',
      },
      content: {
        flex: '1 1 100%',
        overflow: 'auto',
        padding: theme.spacing(2),
        zIndex: 90,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1, 0),
        },
      },
      children: {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(0, 1),
        },
      },
      full: {
        flex: '1 1 100%',
        overflow: 'hidden',
        height: '100%',
        zIndex: 90,
        position: 'relative',
      },
    }),
  { name: 'StandardWrapper' }
)

type Props = {
  children?: React.ReactNode
}

/**
 * Checks if the current page needs the full content window.
 *
 * Most pages can use the standard centered content, but some need more space.
 *
 * Note: this cannot use the actual routed paths since this component
 * @param location Current page
 * @returns true if the page needs the full content window; false otherwise
 */
const needsFullContent = (location: Location): boolean => {
  // These page needs the full content:
  // - Live Data charts (/livedata)
  if (location.pathname.endsWith('/livedata')) {
    return true
  }
  if (location.pathname.endsWith('/influent/chart')) {
    return true
  }

  // Everything else can use the standard template
  return false
}

const StandardWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props

  const classes = useStyles()

  // Does this page need the full content window?
  const location = useLocation()
  const needsFull = needsFullContent(location)

  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <div className={classes.header}>
          <StandardNav />
        </div>
      </Hidden>
      {needsFull && <div className={classes.full}>{children}</div>}
      {!needsFull && (
        <div className={classes.content}>
          <Container className={classes.children}>
            <>{children}</>
          </Container>
        </div>
      )}
    </div>
  )
}

export default StandardWrapper
