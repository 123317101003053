/**
 * Date utilities
 */

import { DateTime } from 'luxon'
import { format } from 'date-fns'

import { hasValue } from './utils'

type DateLike = null | undefined | string | number | Date

/**
 * Parse a date from text
 *
 * @param value Date/time string (but can also be number or Date object)
 * @returns a Date object, or null if the value could not be parsed
 */
export const parseDate = (value: DateLike): Date | null => {
  if (!hasValue(value)) {
    // Nothing to parse
    return null
  }

  if (value instanceof Date) {
    // Pass the Date through
    return value
  }

  let timestamp: DateTime | null = null

  if (typeof value === 'number') {
    timestamp =
      value > 4_294_967_295 // 2^32 - 1
        ? DateTime.fromMillis(value)
        : DateTime.fromSeconds(value)
  } else if (typeof value === 'string') {
    timestamp = DateTime.fromISO(value)
  }

  if (!timestamp?.isValid) {
    console.error(`Invalid ISO8601 date value [${value}]`)
    return null
  }

  return timestamp.toJSDate()
}

/**
 * Check if the current time is in the given range.
 *
 * @param startDate Date or text for start of range
 * @param endDate Date or text for end of range
 * @param isOpen Is the range open (i.e. have a null start or end)
 * @returns true if a range is specified and the current time is within it
 */
export const inDateRange = (
  startDate: DateLike,
  endDate: DateLike,
  isOpen = false
): boolean => {
  startDate = parseDate(startDate)
  endDate = parseDate(endDate)

  if (!startDate && !endDate) {
    // No range specified
    return false
  }

  if (!isOpen && (!startDate || !endDate)) {
    // Both start and end need to be specified
    return false
  }

  const now = Date.now()

  if (startDate && startDate.getTime() > now) {
    // Has not started yet
    return false
  }

  if (endDate && endDate.getTime() < now) {
    // Already ended
    return false
  }

  // All good
  return true
}

/**
 * Format a date either using an explicit pattern, or using the default locale
 * formatting with or withour the time.
 *
 * The template string uses standard Unicode date tokens
 *   https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 *
 * @param value Date/time string (but can also be number or Date object)
 * @param template format pattern string, or a flag to include the time for standard locale formatting
 * @returns formatted date
 */
export const formatDate = (
  value: DateLike,
  template: string | boolean = true
): string => {
  // Try to parse the value into a date
  const date = parseDate(value)

  if (!date) {
    // Oh well...
    return ''
  }

  if (template === true) {
    // Use the standard Date/Time locale format
    return date.toLocaleString()
  }

  if (template === false) {
    // Use the standard Date-only locale format
    return date.toLocaleDateString()
  }

  // Format using the explicit pattern
  return format(date, template)
}

/**
 * Check if the date is at the start of the day (00:00) in the local
 * timezone, ignoring seconds.
 *
 * @param date
 * @returns true, if the date is at the start of the day
 */
export const isStartOfDay = (date: Date): boolean =>
  date.getHours() === 0 && date.getMinutes() === 0

/**
 * Check if the date is at the end of the day (23:59) in the local timezone,
 * ignoring seconds.
 *
 * @param date
 * @returns true, if the date is at the end of the day
 */
export const isEndOfDay = (date: Date): boolean =>
  date.getHours() === 23 && date.getMinutes() === 59
